import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ValidatorRegister {
  public customerTypeAndCompany(): ValidatorFn {
    return (formGroup: AbstractControl) => {
      const customerTypeControl = formGroup.get('customerType');
      const companyControl = formGroup.get('company');
      const customerTypeValue = customerTypeControl?.value;

      if (customerTypeValue == 1 && !companyControl) {
        return { typeRequireCompany: true }; // This is our error!
      }

      return null;
    };
  }
  public match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl?.errors && !checkControl.errors['matching']) {
        return null;
      }
      if (control?.value !== checkControl?.value) {
        controls.get(checkControlName)?.setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }
}
