import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from '../base/data.service';
import { Config } from '../base/endpoints.config';

interface IpFirmsDirectoryBP{
  Id?: number;
  JurisdictionId?: number;
  Category?: number;
}

@Injectable({
  providedIn: 'root'
})
export class IpFirmsDirectoryService extends DataService{
  constructor(public http: HttpClient, private configuration: Config) {
    super(http, configuration.URL);
  }
  getLawFirms(bodyParameters: IpFirmsDirectoryBP) {
    this.url = this.configuration.URL + this.configuration.GET_LAW_FIRMS_BY_JURISDICTION;
    return this.getPostApiResultListData(bodyParameters);
  }
  getLawFirmByIdAndCategory(bodyParameters: IpFirmsDirectoryBP) {
    this.url = this.configuration.URL + this.configuration.GET_LAW_FIRMS_BY_ID_AND_CATEGORY;
    return this.getPostApiResultData(bodyParameters);
  }
}
