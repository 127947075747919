import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerSubscriptionDTO, PaypalCaptureRequestDTO } from '../../models/customerSubscriptionDTO';
import { TariffPromotion } from '../../models/tariff-promotion';
import { DataService } from '../base/data.service';
import { Config } from '../base/endpoints.config';
@Injectable()
export class PriceService extends DataService {
  constructor(public http: HttpClient, private configuration: Config) {
    super(http, configuration.URL);
  }

  getSubscription() {
    this.url = this.configuration.URL + this.configuration.PRICE_SUBSCRIPTIONS;
    return this.getPostApiResultListData();
  }
  getMatters() {
    this.url = this.configuration.URL + this.configuration.PRICE_MATTERS;
    return this.getPostApiResultListData();
  }
  getTariffs() {
    this.url = this.configuration.URL + this.configuration.PRICE_TARIFFGROUPS;
    return this.getPostApiResultListData();
  }
  subscriptionPlan(bodyParaeter: CustomerSubscriptionDTO) {
    this.url = this.configuration.URL + this.configuration.SUBSCRIPTION_PLAN;
    return this.getPostApiResultData(bodyParaeter);
  }

  paypalCaptureOrder(bodyParameter: PaypalCaptureRequestDTO){
    this.url = this.configuration.URL + this.configuration.PAYPAL_CAPTURE_ORDER;
    return this.getPostApiResultData(bodyParameter);
  }

  checkDiscountCode(bodyParameter: TariffPromotion){
    this.url = this.configuration.URL + this.configuration.CHECK_DISCOUNT_CODE;
    return this.getPostApiResultData(bodyParameter);
  }
}
