import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TypeOfApplicantEnum } from 'src/app/core/enums/type-of-applicant-enum';
import { ApiResultData } from 'src/app/core/models/base/ApiResult';
import { Session } from 'src/app/core/models/base/session';
import { registerUser } from 'src/app/core/models/registerUserDTO';
import { RegisterService } from 'src/app/core/services/apis/register.service';
import { LoginService } from 'src/app/core/services/auth/login.service';
import { StorageService } from 'src/app/core/services/base/storage.service';
import { ValidatorRegister } from 'src/app/core/validators/register';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  public type: number = 0;
  formRegister!: FormGroup;
  user: registerUser;
  messageError: string=null;
  successMessage: string = null;
  constructor(
    private formBuilder: FormBuilder,
    private validatorRegister: ValidatorRegister,
    private srvRegister: RegisterService,
    private storageService: StorageService,
    private router: Router,
    private srvLogin: LoginService,
    private scroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    const regexPassword = /^((?=\S*?[A-Z])(?=\S*?[a-z]).{6,})\S$/;
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.formRegister = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        customerType: [0, Validators.required],
        company: ['', null],
        email: ['', [Validators.required, Validators.pattern(regexEmail)]],
        password: [
          '',
          [Validators.required, Validators.pattern(regexPassword)],
        ],
        confirmPassword: [
          '',
          [Validators.required, Validators.pattern(regexPassword)],
        ],
      },
      {
        validators: [
          this.validatorRegister.customerTypeAndCompany(),
          this.validatorRegister.match('password', 'confirmPassword'),
        ],
        updateOn: 'change',
      }
    );
  }

  get customerType() {
    return this.formRegister.get('customerType');
  }
  set customerType(values) {
    this.type = values?.value;
  }
  changeCustomerType(event: any) {
    this.type = event.target.value;
    if (this.type == TypeOfApplicantEnum.Company) {
      this.formRegister.get('company')?.addValidators(Validators.required);
    } else {
      this.formRegister.get('company')?.clearValidators();
      this.formRegister.get('company')?.updateValueAndValidity();
    }
  }
  onSubmit() {
    debugger;
    this.successMessage = null;
    this.messageError = null;
    if (!this.formRegister.valid) {
      alert('Please check required form fields!');
      return false;
    } else {
      this.user = {
        firstName: this.formRegister.get('firstName').value,
        lastName: this.formRegister.get('lastName').value,
        email: this.formRegister.get('email').value,
        password: this.formRegister.get('password').value,
        companyName: this.formRegister.get('company').value,
        customerType: this.formRegister.get('customerType').value,
      };
      
      this.srvRegister
        .registerUser(this.user)
        .then((result: ApiResultData) => {
          if (result.IsSucess) {
            this.successMessage = "An email has been sent for activation.";
            this.scroller.scrollToPosition([0,0]);
            // let _session = new Session();
            // _session.token = result.Data.Token;
            // _session.user = {
            //   id: result.Data.Id,
            //   firstName: result.Data.FirstName,
            //   lastName: result.Data.LastName,
            //   email: result.Data.Email,
            //   companyName: result.Data.Company,
            //   isCustomerAdmin: result.Data.IsCustomerAdmin
            // };
            // this.storageService.setCurrentSession(_session);
            // this.srvLogin.loginSuccess();
            // this.srvLogin.loginStatusChange();
            // this.router.navigate(['/main']);
          } else {
            this.messageError = result.MessageError;
          }
        })
        .catch((error) => {
          alert(JSON.stringify(error.error));
        });
      return true;
    }
  }
}
