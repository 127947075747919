<!-- Login -->
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div
        class="col-md-10 col-lg-7 wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div class="text-start">
          <h2 class="mb-3">Account Confirmation</h2>
          <app-alert *ngIf="showAlert" [title]="alerTitle" [body]="alertBody" [type]="alertType"></app-alert>
        </div>
      </div>
    </div>
  </div>
</div>
