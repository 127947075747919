<div class="modal-bodypt-0">
  <div class="border-box-padding text-start">
    <div class="sec-title ft-25 text-start mb-2">
      Change Password
    </div>
    <p class="mb-4">Change your password by setup your new password</p>
    <form [formGroup]="formChangePassword" (ngSubmit)="saveChangePassword()" class="pt-4">
      <div class="mb-4 form-group position-relative">
        <label>New Password</label>
        <input tabindex="6" type="password" name="" placeholder="6+ Strong Charecter" id="" class="form-control"
          formControlName="password" />
        <img src="./assets/img/icon/key.svg" class="img-fluid" alt="icon" />
      </div>
      <div class="mb-4 form-group position-relative">
        <label>Confirm Password</label>
        <input tabindex="7" type="password" name="" placeholder="6+ Strong Charecter" id="" class="form-control"
          formControlName="confirmPassword" />
        <img src="./assets/img/icon/key.svg" class="img-fluid" alt="icon" />
      </div>
      <div class="mb-3">
        <button tabindex="8" class="btn btn-theme w-100 br-8" type="submit" [disabled]="
            !formChangePassword.valid
          ">
          Update
        </button>
        <p *ngIf="
            formChangePassword.controls['password'].hasError('required') &&
            formChangePassword.controls['password'].touched
          " class="alert alert-danger error-margin">
          please enter password
        </p>
        <p *ngIf="
            formChangePassword.controls['password'].hasError('pattern') &&
            formChangePassword.controls['password'].touched
          " class="alert alert-danger error-margin">
          Check that a password has a minimum of 6 characters, at least 1
          uppercase letter and 1 lowercase letter
        </p>
        <p *ngIf="
            formChangePassword.controls['confirmPassword'].hasError(
              'pattern'
            ) && formChangePassword.controls['confirmPassword'].touched
          " class="alert alert-danger error-margin">
          Check that a confirm password has a minimum of 6 characters, at
          least 1 uppercase letter and 1 lowercase letter
        </p>
        <p *ngIf="
            formChangePassword.controls['confirmPassword'].hasError(
              'required'
            ) && formChangePassword.controls['confirmPassword'].touched
          " class="alert alert-danger error-margin">
          please enter confirm password
        </p>
        <p *ngIf="
            formChangePassword.errors?.matching &&
            !formChangePassword.controls['password'].hasError('pattern') &&
            !formChangePassword.controls['confirmPassword'].hasError('pattern')
          " class="alert alert-danger error-margin">
          Confirm Password does not match
        </p>
      </div>
    </form>
  </div>
</div>