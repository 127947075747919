import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiResultData } from 'src/app/core/models/base/ApiResult';
import { ExtraParameterUser } from 'src/app/core/models/extra-parameter-user';
import { AccountService } from 'src/app/core/services/apis/account.service';
import { StepFormService } from 'src/app/core/services/apis/stepForm.service';
import { StorageService } from 'src/app/core/services/base/storage.service';
import { HelperService } from 'src/app/core/services/shared/helper.service';
import { LoadingService } from 'src/app/core/services/shared/loading.service';
@Component({
  selector: 'app-generate-order-pdf',
  templateUrl: './generate-order-pdf.component.html',
  styleUrls: ['./generate-order-pdf.component.css'],
})
export class GenerateOrderPdfComponent implements OnInit {
  @ViewChild('htmlData') DATA!: ElementRef;
  @ViewChild('btnContainter') btnContainer!: ElementRef<HTMLElement>;
  @ViewChild('header') header!: ElementRef<HTMLElement>;
  @Input('orderId') orderId: string = null;
  @Output('finishDownload') finishDownload: EventEmitter<any> = new EventEmitter();
  public pctTitle: string;
  public tierTitle: string;
  public listEstimate: any;
  public listOrders: ApiResultData;
  public tierOption: number;
  public lawFirmList: any[] = null;
  listOtherParams: ExtraParameterUser[] = [];
  private order: any = null;
  matterName: string;
  constructor(
    public router: Router,
    public helperService: HelperService,
    private svcAccount: AccountService,
    private svcStorage: StorageService,
    private activatedRoute: ActivatedRoute,
    private svcLoading: LoadingService,
    private svcStepForm: StepFormService,
    private svcModal: BsModalService
  ) {}

  ngOnInit(): void {
    const session = this.svcStorage.getCurrentSession();
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    if(!id){
      id = this.orderId;
    }
    this.svcLoading.show();
    this.svcAccount
      .getOrderByCustomerAndId({ Id: id, token: session.token })
      .then(async (result) => {
        let tierOptionList = await this.svcStepForm.getTiersOptions();
        const order = result.DataResult;
        this.pctTitle = order.PatentPCT ?? '';
        this.listEstimate = order.OrderParameters;
        this.createOtherParams(order);
        this.listOrders = result;
        this.tierOption = order.TierOption;
        this.tierTitle =
          'TIER ' +
          tierOptionList.ListData.find((x) => x.Id == this.tierOption).Name;
        this.matterName = order.Matter.Name;
        this.order = order;
        setTimeout(() => {
          this.downloadPDF();
        }, 1000);
      });
  }
  private createOtherParams(order: any) {
    this.listOtherParams = [
      {
        SiteLabel: 'Client',
        Show: order.ClientName.trim().length > 0,
        Value: order.ClientName,
      },
      {
        SiteLabel: 'Reference',
        Show: order.Reference.trim().length > 0,
        Value: order.Reference,
      },
      {
        SiteLabel: 'Pct patent',
        Show: order.PatentPCT && order.PatentPCT.trim().length > 0,
        Value: order.PatentPCT,
      },
      {
        SiteLabel: 'Currency',
        Show: true,
        Value: order.Currency.Name,
      },
      {
        SiteLabel: 'Language',
        Show: true,
        Value: order.Language != null ? order.Language.Name : "",
      },
    ];
    this.listOtherParams = this.listOtherParams.filter((x) => x.Show);
  }
  downloadPDF() {
    this.svcLoading.show();
    this.btnContainer.nativeElement.classList.add('d-none');
    html2canvas(this.DATA.nativeElement, { scale: .7 }).then((canvas) => {
      this.btnContainer.nativeElement.classList.remove('d-none');
      let htmlWidth = canvas.width;
      let htmlHeight = canvas.height;
      let topLeftMargin = 15;
      let pdfWidth = htmlWidth + topLeftMargin * 2;
      let pdfHeight = this.DATA.nativeElement.offsetHeight;

      let imageWidth = htmlWidth;
      let iamgeHeight = htmlHeight;

      canvas.getContext('2d');

      console.log(canvas.height + '  ' + canvas.width);

      var imgData = canvas.toDataURL('image/jpeg', 1.0);
      var pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);
      pdf.addImage(
        imgData,
        'PNG',
        topLeftMargin,
        topLeftMargin,
        imageWidth,
        iamgeHeight
      );
      pdf.text('', topLeftMargin+10, topLeftMargin+10);
      pdf.save(`Quote_${this.matterName}_${this.order.Id}`, {returnPromise: true}).then(x => {
        this.svcLoading.hide();
        this.finishDownload?.emit(null);
      })
    });
  }
}
