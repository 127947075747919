<div class="sec-pad ">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-10 col-lg-7 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
        <div class="login-box text-start border-box-padding">
          <div class="text-center">
            <div>
              <div class="sec-title text-center">
                Your Payments
              </div>
              <div class="sec-line m-auto"></div>
            </div>
            <p *ngIf="!isEmpty" class="mb-4">Your Subscription Details</p>
            <p *ngIf="isEmpty" class="mb-4">You haven't made payments yet!</p>
          </div>
          <div class="mt-4"></div>
          <div class="table-responsive">
            <ng-container *ngIf="lastPayment!=null else elseTemplate">
              <table class="table simple-table text-start">
                <tbody>
                  <tr>
                    <th>Payment Method</th>
                    <td>{{getPaymentMethod()}}</td>
                  </tr>
                  <tr>
                    <th>Last Payments</th>
                    <td>{{lastPayment.Amount | currency: '':''}} {{lastPayment.CurrencyCode}}</td>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <td>{{lastPayment.Date | date: 'dd MMM yyyy'}}</td>
                  </tr>
                  <tr>
                    <th colspan="2" class="border-bottom-0">
                      <p>Your Chosen Payment Method</p>
                      <div class="">
                        <label class="container1 p-label d-inline-block">Credit or Debit card
                          <input type="checkbox" disabled [checked]="isCreditOrDebit">
                          <span class="checkmark disabled"></span>
                        </label>
                        <label class="container1 p-label d-inline-block">Paypal
                          <input type="checkbox" disabled [checked]="isPaypal">
                          <span class="checkmark"></span>
                        </label>
                        <label class="container1 p-label d-inline-block">Check/Wire
                          <input type="checkbox" disabled>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </ng-container>
            <ng-template #elseTemplate>
              <div *ngIf="!isEmpty">
                <table class="table simple-table text-start">
                  <tbody>
                    <tr *ngFor="let item of generateFake(3)">
                      <ngx-skeleton-loader [theme]="{ width: '100%', height: '60px' }"></ngx-skeleton-loader>
                    </tr>
                    <tr>
                      <th colspan="2" class="border-bottom-0">
                        <p>Your Chosen Payment Method</p>
                        <div class="">
                          <ngx-skeleton-loader *ngFor="let item of generateFake(3)" [theme]="{ width: '30%', marginRight: '8px' }"></ngx-skeleton-loader>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div *ngIf="!isEmpty" class="col-12 mt-5 wow fadeInUp" data-wow-delay=".4s"
        style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
        <p class="medium col-12">Last Payments</p>
        <div class="table-responsive">
          <ng-container *ngIf="payments!=null else elseTable">
            <table class="table simple-table text-start">
              <tbody>
                <tr *ngFor="let payment of payments">
                  <td><span class="me-2"><img src="./assets/img/icon/usd.svg" class="img-fluid"
                        alt="icon"></span>{{payment.Amount | currency: '':''}}</td>
                  <td><span class="me-2"><img src="./assets/img/icon/calendar.svg" class="img-fluid"
                        alt="icon"></span>{{payment.Date | date: 'dd MMM yyyy'}}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-template #elseTable>
            <table class="table simple-table text-start">
              <tbody>
                <tr *ngFor="let item of generateFake(3)" class="row">
                  <ngx-skeleton-loader *ngFor="let item of generateFake(2)" class="col col-6"></ngx-skeleton-loader>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>