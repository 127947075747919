<!-- Login -->
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div
        class="col-md-10 col-lg-7 wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div class="login-box text-start">
          <div class="sec-title ft-25 text-start mb-2">
            Welcome to Pascal IP
          </div>
          <p class="mb-4">Login in your account with Email id and Password</p>
          <form class="pt-4" [formGroup]="formLogin" (ngSubmit)="onSubmit()">
            <div class="mb-4 form-group position-relative">
              <label>Email</label>
              <input
                formControlName="email"
                type="email"
                name="email"
                placeholder="example@mail.com"
                id="email"
                class="form-control"
              />
              <img
                src="./assets/img/icon/at.svg"
                class="img-fluid"
                alt="icon"
              />
            </div>
            <div class="mb-4 form-group position-relative">
              <label>Password</label>
              <input
                formControlName="password"
                type="password"
                name="password"
                placeholder="6+ Strong Charecter"
                id="password"
                class="form-control"
              />
              <img
                src="./assets/img/icon/lock.svg"
                class="img-fluid"
                alt="icon"
              />
            </div>

            <div class="mb-3">
              <button
                class="btn btn-theme w-100 br-8"
                type="submit"
                [disabled]="!formLogin.valid"
              >
                Sign In
              </button>
              <p
                *ngIf="
                  formLogin.controls['email'].hasError('required') &&
                  formLogin.controls['email'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter email
              </p>
              <p
                *ngIf="
                  formLogin.controls['email'].hasError('pattern') &&
                  formLogin.controls['email'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter valid email
              </p>
              <p
                *ngIf="
                  formLogin.controls['password'].hasError('required') &&
                  formLogin.controls['password'].touched
                "
                class="alert alert-danger error-margin{"
              >
                please enter password
              </p>
              <p
                *ngIf="
                  formLogin.controls['password'].hasError('pattern') &&
                  formLogin.controls['password'].touched
                "
                class="alert alert-danger error-margin"
              >
                Check that a password has a minimum of 6 characters, at least 1
                uppercase letter and 1 lowercase letter
              </p>

              <p *ngIf="errorLogin" class="alert alert-danger error-margin">
                {{ errorLogin }}
              </p>
            </div>
            <p class="text-center mb-0">
              Or <a [routerLink]="['/register']" class="text-theme"> Sign Up</a>
            </p>
            <p class="text-center mb-0">
               <a [routerLink]="['/forgot-password']" class="text-theme"> Forgot password?</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
