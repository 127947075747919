import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StorageService } from 'src/app/core/services/base/storage.service';
import { HelperService } from 'src/app/core/services/shared/helper.service';
import { LoadingService } from 'src/app/core/services/shared/loading.service';
import { UserInformationBP } from '../../core/models/loginSecurityDTO';
import { AccountService } from '../../core/services/apis/account.service';
import { LoginService } from '../../core/services/auth/login.service';
import { ChangePasswordComponent } from './modal/change-password/change-password.component';

@Component({
  selector: 'app-login-security',
  templateUrl: './login-security.component.html',
  styleUrls: ['./login-security.component.css']
})
export class LoginSecurityComponent implements OnInit {
  public formLoginSecurity: FormGroup;
  public messageError: string;
  public messageSuccess: string;
  public user: UserInformationBP;
  bsModalRef: BsModalRef;
  constructor(
    private formBuilder: FormBuilder,
    private srvStorage: StorageService,
    private srvLogin: LoginService,
    private srvAccount: AccountService,
    private modalService: BsModalService,
    private svcLoading: LoadingService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const session = this.srvStorage.getCurrentSession();
    this.formLoginSecurity = this.formBuilder.group(
      {
        firstName: [session.user.firstName, Validators.required],
        lastName: [session.user.lastName, Validators.required],
        email: [session.user.email, [Validators.required, Validators.pattern(regexEmail)]],
      } ,{
        updateOn: 'change',
      }
    );
  }
  openModalWithComponent() {
    const initialState = {
      item: {Name: "xd"},
    };
    this.bsModalRef = this.modalService.show(ChangePasswordComponent, {
      initialState: initialState,
      class: 'modal-dialog-centered modal-lg'
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.event.subscribe((res)=>{
      this.messageSuccess = res;
    });
  }
  onSubmit():boolean{
    const session = this.srvStorage.getCurrentSession();
    this.messageError = null;
    if (!this.formLoginSecurity.valid) {
      alert('Please check required form fields!');
      return false;
    } 
    this.user = {
      FirstName: this.formLoginSecurity.get('firstName').value,
      LastName: this.formLoginSecurity.get('lastName').value,
      Email: this.formLoginSecurity.get('email').value,
      Token: session.token
    };
    this.svcLoading.show();
    this.srvAccount.updateUserInformation(this.user)
      .then(result => {
        this.svcLoading.hide();
        if(!result.IsSucess){
          this.messageError = result.MessageError;
          this.messageSuccess = null;
          return;
        }
        session.user.firstName = result.Data.FirstName;
        session.user.lastName = result.Data.LastName;
        session.user.email = result.Data.Email;
        this.srvStorage.setCurrentSession(session);
        this.srvLogin.loginSuccess();
        this.messageSuccess = "Updated successfully!";
      })
      .catch((error) => {
        this.svcLoading.hide();
        alert(JSON.stringify(error.error));
      });
    return true;
  }
}
