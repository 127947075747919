import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { LoginDTO } from '../../models/loginDTO';
import { DataService } from '../base/data.service';
import { Config } from '../base/endpoints.config';
import { StorageService } from '../base/storage.service';
import { IToken } from '../../models/base/IToken';
import { ApiResult } from '../../models/base/ApiResult';
@Injectable()
export class LoginService extends DataService {
  private loggedIn: Subject<boolean> = new ReplaySubject<boolean>(1);
  constructor(
    public http: HttpClient,
    private configuration: Config,
    private svcStorage: StorageService
  ) {
    super(http, configuration.URL);
  }

  loginUser(bodyParameters: LoginDTO) {
    this.url = this.configuration.URL + this.configuration.LOGIN;
    return this.getPostApiResultData(bodyParameters);
  }
  loginSuccess() {
    this.loggedIn.next(true);
  }

  loginStatusChange(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }
  logout(): void {
    this.loggedIn.next(false);
    this.svcStorage.logout();
  }
  loginStatus(): { isAuthenticated: boolean; username: string } {
    let loggedIn = this.svcStorage.isAuthenticated();
    if (loggedIn) {
      let userName =
        this.svcStorage.getCurrentUser().firstName +
        ' ' +
        this.svcStorage.getCurrentUser().lastName;
      return { isAuthenticated: true, username: userName };
    } else {
      return null;
    }
  }
  isLogged(): boolean {
    return this.svcStorage.isAuthenticated();
  }
  async isValidToken(): Promise<ApiResult>{
    debugger;
    const token = this.svcStorage.getCurrentToken();
    if(token){
      this.url = this.configuration.URL + this.configuration.IS_VALID_TOKEN;
      return this.getPostApiResult({Token: token});
    }
    return {IsSucess: true};
  }
}
