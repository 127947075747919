import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SponsorLawFirmComponent } from '../sponsor-law-firm/sponsor-law-firm.component';
import { StorageService } from '../../../../core/services/base/storage.service';
import { LoadingService } from '../../../../core/services/shared/loading.service';
import { StepFormService } from '../../../../core/services/apis/stepForm.service';

@Component({
  selector: 'app-law-firm-list',
  templateUrl: './law-firm-list.component.html',
  styleUrls: ['./law-firm-list.component.css']
})
export class LawFirmListComponent implements OnInit {
  list: any[] = null;
  orderId: any = null;
  isLoading: boolean = false;
  isSuccess?: boolean = null;
  messageError: any = null;
  constructor(
    public bsModalRef: BsModalRef,
    public bsModalService: BsModalService,
    private svcStorage: StorageService,
    private srvLoading: LoadingService,
    private srvStepForm: StepFormService

  ) { }

  ngOnInit(): void {
    if(this.list!=null){
      this.list.forEach(item => {
        item.isLoading = null;
        item.isSuccess = null;
      });
    }
  }

  createLink(link: string){
    if(link && !link.startsWith("http://") && !link.startsWith("https://")){
      return "http://"+link;
    }
    return link;
  }
  onClickSponsor(item: any){
    this.bsModalRef.hide();
    this.bsModalService.show(SponsorLawFirmComponent, {
      initialState: {
        lawFirmId: item.Id,
        orderId: this.orderId
      },
      class: "modal-lg"
    })
  }
  async sendEmail(item: any) {
    item.isLoading = true;
    const session = this.svcStorage.getCurrentSession();
    this.isLoading = true;
    item.isLoading = true;
    this.srvLoading.show();
    this.srvStepForm
      .sendMailToLawFirm({
        Token: session.token,
        Id: this.orderId,
        Email: item.Email,
        ContactName: item.ContactName,
      })
      .then((result) => {
        item.isLoading = false;
        this.isLoading = false;
        this.srvLoading.hide();
        if (result.IsSucess) {
          this.isSuccess = true;
          item.isSuccess= true;
        } else {
          this.isSuccess = false;
          item.isSuccess = false;
          this.messageError = result.MessageError;
        }
      });
  }
  close() {
    this.bsModalRef.hide();
  }
}
