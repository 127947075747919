import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { StorageService } from 'src/app/core/services/base/storage.service';
import { LoadingService } from 'src/app/core/services/shared/loading.service';
import { ValidatorRegister } from 'src/app/core/validators/register';
import { ChangePasswordBP } from '../../../../core/models/loginSecurityDTO';
import { AccountService } from '../../../../core/services/apis/account.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public item: any;
  public formChangePassword: FormGroup;
  public changePasswordBP: ChangePasswordBP;
  public messageError: any;
  public event: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    public options: ModalOptions,
    private storageService: StorageService,
    private validatorRegister: ValidatorRegister,
    private svcAccount: AccountService,
    private svcLoading: LoadingService,
    private svcModal: BsModalService
  ){

  }
  ngOnInit(): void {
    const regexPassword = /^((?=\S*?[A-Z])(?=\S*?[a-z]).{6,})\S$/;
    this.formChangePassword = this.formBuilder.group(
      {
        password: [
          '',
          [Validators.required, Validators.pattern(regexPassword)],
        ],
        confirmPassword: [
          '',
          [Validators.required, Validators.pattern(regexPassword)],
        ],
      },
      {
        validators: [
          this.validatorRegister.match('password', 'confirmPassword'),
        ],
        updateOn: 'change',
      }
    );
  }
  saveChangePassword():boolean{
    const session = this.storageService.getCurrentSession();
    this.messageError = null;
    if (!this.formChangePassword.valid) {
      alert('Please check required form fields!');
      return false;
    } 
    if(session){
      const changePassword: ChangePasswordBP = {
        Password: this.formChangePassword.get('password').value,
        Token: session.token,
        UserId: this.item?.UserId
      };
      this.svcLoading.show();
      this.svcAccount.changePassword(changePassword) 
      .then(result => {
        this.svcLoading.hide();
        if(!result.IsSucess){
          this.messageError = result.MessageError;
          return;
        }
        this.event.emit("Password changed!");
        this.bsModalRef.hide();
      })
      .catch((error) => {
        this.svcLoading.hide();
        alert(JSON.stringify(error.error));
      });
    }
    return true;
  }
}
