<div class="sec-pad ">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-md-12 text-center wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                <div>
                    <div class="sec-title text-center">
                       Customer Services
                    </div>
                    <div class="sec-line m-auto"></div>
                </div>
                <p class="mb-4">Customer Services 24/7</p>
            </div>
            <div class="mt-4"></div>
          <div class="col-12 col-lg-12 mb-4 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
            <div class="s-box s-bg-none box-shadow-none text-start">
              <div class="sec-title font-25 mb-3 text-start">
                PASCAL IP ONLINE HELP
              </div>
              <p class="op-7 mb-4">These videos show you how the quoting system works for each matter:</p>
              <div class="video-listing">
                <ng-container *ngIf="matters != null; else elseTemplate">
                  <p *ngFor="let matter of matters" class="medium"><img src="./assets/img/icon/video-orange.svg" class="img-fluid me-3" alt="img"><a class="cursor-pointer" (click)="onClickMatter(matter)">{{matter.Name}}</a></p>
                </ng-container>
                <ng-template #elseTemplate>
                  <p class="medium" *ngFor="let i of generateFake(6)">
                    <ngx-skeleton-loader [theme]="{ width: '200px'  }"></ngx-skeleton-loader>
                  </p>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-4 wow fadeInUp" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
            <div class="s-box s-bg-4 box-shadow-none text-center">
              <div class="sec-title font-25 mb-3">
                Quoting System
              </div>
              <p class="op-7 mb-4">If you need to contact us regarding the quoting system</p>
              <div class="d-inline-block text-start">
                <p class="medium mb-2"><span class="icon-img-width me-2"><img src="./assets/img/icon/phone-orange.svg" class="img-fluid" alt="img"></span><a href="tel:+1-305-358-8556">+1-305-358-8556</a></p>
                <p class="medium mb-2"><span class="icon-img-width me-2"><img src="./assets/img/icon/email-orange.svg" class="img-fluid" alt="img"></span><a href="mailto:support@pascalip.com">support@pascalip.com</a></p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-4 wow fadeInUp" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
            <div class="s-box s-bg-4 box-shadow-none text-center">
              <div class="sec-title font-25 mb-3">
                Payments &amp; Subscriptions
              </div>
              <p class="op-7 mb-4">If you need to contact us regarding payments and subscriptions</p>
              <div class="d-inline-block text-start">
                <p class="medium mb-2"><span class="icon-img-width me-2"><img src="./assets/img/icon/phone-orange.svg" class="img-fluid" alt="img"></span><a href="tel:+1-305-358-8556">+1-305-358-8556</a></p>
                <p class="medium mb-2"><span class="icon-img-width me-2"><img src="./assets/img/icon/email-orange.svg" class="img-fluid" alt="img"></span><a href="mailto:accounting@pascalip.com">accounting@pascalip.com</a></p>
              </div>
            </div>
          </div>
          <div class="col-12 text-center mt-5 wow fadeInUp" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;" *ngIf="false">
            <div class="sec-title font-25">
              If you prefer to chat with one of our agents
            </div>
            <button class="btn btn-theme wow fadeInUp mt-4" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
              <span class="me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-square-fill" viewBox="0 0 16 16">
                  <path d="M2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path>
                </svg>
              </span>
              Chat
            </button>
          </div>
        </div>
    </div>
</div>