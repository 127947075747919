<!-- hero -->
<div class="hero inner">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-10 text-center">
        <h1
          class="wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
          "
        >
          Country Guide
        </h1>
        <p
          class="wow fadeInUp mb-0"
          data-wow-delay=".4s"
          style="
            visibility: visible;
            animation-delay: 0.4s;
            animation-name: fadeInUp;
          "
        >
          Your frequently asked questions, simply answered
        </p>
      </div>
    </div>
  </div>
</div>
<!-- What is Pascal IP -->
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-center wow fadeInLeft"
        style="visibility: visible; animation-name: fadeInLeft"
      >
        <img
          src="./assets/img/country-guide/country-guide.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
      <div
        class="col-md-6 text-start text-md-start wow fadeInRight"
        style="visibility: visible; animation-name: fadeInRight"
      >
        <div>
          <div class="sec-title text-start text-md-start">Country Guide</div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>
        <p>
          Country Guide is the leading publication on worldwide industrial
          property (IP) practice, application handling, and law. Inside you will
          find a detailed explanation on IP practices in every country of the
          world. This will help you understand not only the registration
          process, but also how these rights can be maintained and enforced.
        </p>
        <p>The scope of this service covers:</p>
        <div class="d-flex mb-3">
          <img src="./assets/img/arrow.svg" class="img-fluid me-3" alt="icon" />
          <div>
            Matters included: Patents, trademarks, utility models, industrial
            models, and designs.
          </div>
        </div>
        <div class="d-flex mb-3">
          <img src="./assets/img/arrow.svg" class="img-fluid me-3" alt="icon" />
          <div>
            Scope: More than 200 countries, territories, and jurisdictions
            covered.
          </div>
        </div>
        <div class="d-flex mb-3">
          <img src="./assets/img/arrow.svg" class="img-fluid me-3" alt="icon" />
          <div>Updates: Monthly updates, over 2,000 updates a year.</div>
        </div>
        <div class="d-flex mb-3">
          <img src="./assets/img/arrow.svg" class="img-fluid me-3" alt="icon" />
          <div>Over 100 contributors worldwide.</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="sec-pad pt-0">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-12 text-center wow fadeInUp"
        style="visibility: hidden; animation-name: none"
      >
        <div class="country-guide-box">
          <div class="sec-title text-start text-md-start mb-2">
            Select matter of your choice
          </div>
          <p class="mb-3 mb-md-5">
            Check your country guide using this options
          </p>
          <form>
            <label class="container2" *ngFor="let matter of listMatter"
              >{{ matter.Name }}
              <input
                tabindex="0"
                type="radio"
                [value]="matter.Id"
                name="radio"
                (change)="onChangeSelectMatter($any($event.target).value)"
              />
              <span class="checkmark"></span>
            </label>
            <div tabindex="1" class="form-floating mb-5 mt-4" *ngIf="matterId != 0">
              <select
                class="form-select"
                id="selectCountry"
                aria-label="Floating label select example"
                (change)="onChangeSelectCountry($any($event.target).value)"
                [disabled]="listCountrys.length == 0"
              >
                <option selected="" value="0">Select Country</option>
                <option
                  *ngFor="let country of listCountrys"
                  [value]="country.Id"
                >
                  {{ country.NameEn }}
                </option>
              </select>
              <label for="selectCountry">Select the country</label>
            </div>
            <div
              *ngIf="jurisdictionId != 0"
              tabindex="2"
              class="form-floating mb-5"
            >
              <select
                class="form-select"
                id="selectSection"
                aria-label="Floating label select example"
                (change)="onChangeSelectSection($any($event.target).value)"
              >
                <option selected="" value="0">Select Section</option>
                <option
                  *ngFor="let section of listSection"
                  [value]="section.Id"
                >
                  {{ section.Description }}
                </option>
              </select>
              <label for="selectSection">Select the Section</label>
            </div>
            <div *ngIf="sectionId != 0" tabindex="3" class="form-floating mb-5">
              <select
                class="form-select"
                id="selectSubsection"
                aria-label="Floating label select example"
                (change)="onChangeSelectSubSection($any($event.target).value)"
              >
                <option selected="" value="0">Select Sub Section</option>
                <option
                  *ngFor="let subSection of listSubSection"
                  [value]="subSection.Id"
                >
                  {{ subSection.Description }}
                </option>
              </select>
              <label for="selectSubsection">Select your sub section</label>
            </div>
          </form>
          <div>
            <div
              *ngIf="countryGuideBody"
              class="s-box text-start pt-4 pb-4"
            >
              <p
                class="fw-500 mb-3"
                [innerHTML]="countryGuideBody.CouGuideSubSecionDesc"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
