<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-12 text-center wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div>
          <div class="sec-title text-center">IP KINDS COVERED</div>
          <div class="sec-line m-auto"></div>
        </div>
        <p class="mb-4">
          Pascal IP is a unique online tool that generates accurate and reliable
          quotes for filing, prosecuting, and maintaining Patents, Trademarks,
          Designs, and Utility Models around the world
        </p>
      </div>

      <div
        class="col-12 wow fadeInUp mb-4"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div class="position-relative ip-kc-box">Patents</div>
        <div class="position-relative ip-kc-box1">
          Pascal IP provides quotes for filing Paris Convention, PCT
          International, and National Stage patent applications, as well as the
          validation and maintenance of European patents.
          <div class="row mt-4">
            <div class="col-md-6 mb-4">
              <div class="sec-title ft-25 text-start mb-2">
                Direct Filing/Paris Convention
              </div>
              <p>
                Not all countries belong to the PCT treaty, and not every
                invention deserves a PCT application. Twenty-eight countries are
                not part of the PCT, and you will need to file your patent
                application in those countries within one year of your initial
                filing. We only need the basics — the number of pages, words,
                claims, and drawings of your application — and you will receive
                your quote for the entire process in seconds. Our quotes include
                filing fees, translation costs, legalization of documents,
                annuities, replying-to-office actions, issuance, and maintenance
                fees.
              </p>
            </div>
            <div class="col-md-6 mb-4">
              <div class="sec-title ft-25 text-start mb-2">
                PCT National Phase
              </div>
              <p>
                Simply insert your PCT application serial number and the
                countries in which you intend to file. Pascal IP will gather the
                relevant information from WIPO web servers and make accurate
                calculations. You don’t need to provide us with the particulars
                of your application. Quotes include filing fees, translation
                costs, legalization of documents, annuities, replying-to-office
                actions, issuance, and maintenance fees.
              </p>
            </div>
            <div class="col-md-6 mb-4">
              <div class="sec-title ft-25 text-start mb-2">
                Validation of EU patents
              </div>
              <p>
                A European patent is usually granted within 3-4 years of filing.
                The grant fee must be paid and the final version of the claims
                must be translated into all three official languages (EN, DE,
                FR) and submitted during this period of time.
              </p>
              <p>
                Once the mention of the grant is published in the Bulletin, the
                European patent must be validated in each of the designated
                states within a specific period of time to retain its protective
                effect and be enforceable against infringers.
              </p>
              <p>
                With Pascal IP, you can sImply select those countries where you
                would like to validate your EPO-granted European patent. In some
                contracting states, the patent owner will have to file a
                translation of the specification in an official language of the
                national patent office. Depending on the relevant national law,
                the applicant may also have to pay fees to the national patent
                office. Pascal IP can help at this stage by estimating the costs
                involved in this validation process.
              </p>
            </div>
            <div class="col-md-6 mb-4">
              <div class="sec-title ft-25 text-start mb-2">
                Maintenance of European Patents
              </div>
              <p>
                Once a European patent is granted, the holder must pay an annual
                maintenance fee to each country in which the patent was
                validated. Pascal IP provides the maintenance cost to maintain
                your rights, including official fees and local IP attorney fees.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 wow fadeInUp mb-4"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div class="position-relative ip-kc-box">Trademarks</div>
        <div class="position-relative ip-kc-box1">
          Pascal IP provides quotes for filing, prosecuting, and renewing
          trademark applications around the world, including national and
          regional applications, Paris Convention, and Madrid Agreement
          applications in more than 180 countries. Our quotes include not only
          the filing costs, but also opposition procedures, office actions,
          renewals, and more.
        </div>
      </div>

      <div
        class="col-12 wow fadeInUp mb-4"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div class="position-relative ip-kc-box">Designs</div>
        <div class="position-relative ip-kc-box1">
          After you have filed an initial design patent application, you have
          six non-extendable months to file it in any of the 173 countries of
          the Paris Convention. Pascal IP helps you file your case by providing
          the total cost of obtaining a design in any country, including filing
          fees, translations, adaptation of drawings, replying-to-office
          actions, issuance, and annuities (if applicable).
        </div>
      </div>

      <div
        class="col-12 wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div class="position-relative ip-kc-box">Utility Models</div>
        <div class="position-relative ip-kc-box1">
          In some countries, a “utility model” system protects so-called “minor
          inventions” similar to patent protection. Utility models grant an
          exclusive right, which allows the right holder to prohibit commercial
          use of the protected invention without his/her authorization for a
          specified period of time. Simply provide the basics — number of pages,
          words, claims, and drawings of your application — and Pascal IP will
          prepare your quote. Our costs include filing fees, translation costs,
          legalization of documents, annuities, replying-to-office actions,
          issuance, and maintenance fees.
        </div>
      </div>
    </div>
  </div>
</div>
