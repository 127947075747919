<div class="modal-body bg-dark">
  <div class="w-100" style="height: 1rem;" (click)="close()">
    <i class="fa fa-times d-block close-modal" style="text-align: right;margin-right: 5px;"></i>
  </div>
  <div class="main-video-container">
    <div class="embed-container">
      <iframe [class.thumbnail]="'thumbnail'" [src]="safeSrc" frameborder="0" webkitallowfullscreen mozallowfullscreen
      allowfullscreen></iframe>
    </div>
  </div>
</div>