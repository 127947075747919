import { IToken } from './base/IToken';
import { TypeOfApplicantEnum } from '../enums/type-of-applicant-enum';
export class OrderRequestDTO implements IToken {
  Token: string;
  MatterId: number; //paso 1b
  PatentPCT: string; //paso 1c
  OrderJurisdictions: Jurisdiction[]; //List<JurisdictionDTO>  //Listado de jurisdicciones (se setea en el paso 2)
  //---Id
  CurrencyId: number; //paso 3.1
  LanguageId: number; //paso 3.2
  //List<OrderParameterDTO> (Se setea en el paso 1.C o 4)
  //---ParameterUserId:
  //---Value
  OrderParameters: OrderParameter[]; //OrderParameterFake[]
  //List<TariffGroupDTO>(Se setea en el paso 5.A)
  /////////////////////////////////////////////////TariffGroups: TariffGroupIds[];
  //---Id
  //TierOption (short) (Se setea en el paso 5.A)
  TierOption: number;
  ClientName: string;
  Reference: string;
  Id?: string;
  ChangeJurisdictionFavorite?: boolean;
  TariffGroups: Array<TariffGroupIds>;
  TypeOfApplicant?: any;
  //////////////////////////////////////////////////////////////////
  // estan en postman pero faltan en la documentacion "CustomerId"  y  "SubscriptionId"
  //estan en la documentacion "PatentePCT" y List<TariffGroupDTO> pero no estan en postman
  /////////////////////////////////////////////////////////////////
}


export class OrderFilterBP implements IToken {
  Token: string;
  Id: string;
  Email?: string;
  ContactName?: string;
}
export class OrderRequestBP implements IToken {
  Token: string;
  MatterId: number; //paso 1b
  PatentPCT: string; //paso 1c
  OrderJurisdictions: Jurisdiction[]; //List<JurisdictionDTO>  //Listado de jurisdicciones (se setea en el paso 2)
  //---Id
  CurrencyId: number; //paso 3.1
  LanguageId: number; //paso 3.2
  //List<OrderParameterDTO> (Se setea en el paso 1.C o 4)
  //---ParameterUserId:
  //---Value
  OrderParameters: any[]; //OrderParameter[]
  //List<TariffGroupDTO>(Se setea en el paso 5.A)
  ////////////////////////////////////////////////TariffGroups: TariffGroupIds[];
  //---Id
  //TierOption (short) (Se setea en el paso 5.A)
  TierOption: number;
  Id?: string;
  //////////////////////////////////////////////////////////////////
  // estan en postman pero faltan en la documentacion "CustomerId"  y  "SubscriptionId"
  //estan en la documentacion "PatentePCT" y List<TariffGroupDTO> pero no estan en postman
  /////////////////////////////////////////////////////////////////
}
export interface patentPctBP {
  PatentPct: string;
  // MatterId: number;
}

export interface matterBP {
  MatterGroup: number;
}

export interface MatterGroupDTO {
  Id: number;
  Name: string;
}

export interface MatterDTO {
  Id: number;
  Name: string;
  InputPatentNumber: boolean;
}
export interface JurisdictionBP extends IToken {
  MatterId: number;
  Token: string;
}
export interface JurisdictionDTO {
  Id: number;
  NameEn: string;
  Continent: string;
  IsFavority: boolean;
}
export interface CurrencyDTO {
  Id: number;
  Name: string;
}
export interface LanguageDTO {
  Id: number;
  Name: string;
}

export interface ParamUserDTO {
  Id: number;
  Code: string;
  Name: string;
  SiteLabel: string;
  SitePlaceHolder: string;
  SiteIsFormatDate: boolean;
  SiteIsFormatNumber: boolean;
  SiteUseInFormule: boolean;
  SiteUseRowComplete: boolean;
  SiteOrder: number;
  SiteIsCalculated?: boolean;
  SiteIsRequired: boolean;
  Value?: string;
}
export interface ParamUserBP {
  Id: number;
}

export interface ParamMatterUSerBP extends IToken {
  Id: number;
  Token: string;
}

export interface MatterTariffDTO {
  TariffGroupId: number;
  TariffGroup: TariffGroup;
  Disabled: boolean;
  Id: number;
}
export interface TariffGroup {
  Code: string;
  Name: string;
  Active: boolean;
  Id: number;
}
export interface TierOptionDTO {
  Id: number;
  Name: string;
  Selected: boolean;
}

export interface Jurisdiction {
  JurisdictionId: number;
  IsFavorite?: boolean;
}
export interface OrderParameter {
  ParameterUserId: number;
  ParameterUser: ParamUserDTO;
  Value: string;
}
export class OrderParameterReduce {
  ParameterUserId: number = undefined;
  Value: string = undefined;
}
export interface OrderParameterBP {
  ParameterUserId: number;
  Value: any;
}
export interface OrderDTO {
  MatterId: number;
  CurrencyId: number;
  LanguageId: number;
  CustomerId: number;
  SubscriptionId: number;
  TierOption: number;
  OrderJurisdictions: Jurisdiction[];
  OrderParameters: OrderParameter[];
}
export interface TariffGroupIds {
  Id: number;
}

// UserId: 13;
//   PatentPCT: 'PCT/PL2014/000130';
//   MatterId: 6;
//   CurrencyId: 1;
//   CustomerId: 7;
//   SubscriptionId: 1;
//   TierOption: 1;
//   OrderJurisdictions: [
//     {
//       JurisdictionId: 1461;
//     },
//     {
//       JurisdictionId: 1462;
//     },
//     {
//       JurisdictionId: 1463;
//     }
//   ];
//   OrderParameters: [
//     {
//       ParameterUserId: 10;
//       Value: 14;
//     }
//   ];
export interface GenerateOrderResponse {
  Data: Data;
  IsSucess: boolean;
  DataResult: any;
  Exception: any;
  MessageError: string;
  MessageErrors: string;
  ErrorCode: string;
  PermissionAdditionalData: any;
}

export interface Data {
  MatterName: string;
  CurrencyName: string;
  LanguageName: string;
  TotalPrice: number;
  ResumePrice: ResumePrice;
  DetailPrices: DetailPrice[];
  Id: number;
}

export interface DetailPrice {
  TariffName: string;
  TotalPrice: number;
  ResumePrice: ResumePrice;
  Id: number;
}

export interface ResumePrice {
  Headers: string[];
  Jurisdictions: string[];
  Rows: { [key: string]: number[] };
  Id: number;
}

export const continents = [
  {
    continent: 'Europe',
    value: 1,
  },

  {
    continent: 'Asia',
    value: 2,
  },
  {
    continent: 'America',
    value: 3,
  },
  {
    continent: 'Africa',
    value: 4,
  },
  {
    continent: 'Australia',
    value: 5,
  },
];
