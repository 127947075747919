<!-- hero -->
<div class="hero inner">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-10 text-center">
        <h1
          class="wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
          "
        >
          Our Services
        </h1>
        <p
          class="wow fadeInUp mb-0"
          data-wow-delay=".4s"
          style="
            visibility: visible;
            animation-delay: 0.4s;
            animation-name: fadeInUp;
          "
        >
          We provide an online quoting system to estimate the costs to file,
          prosecute, grant, and maintain Patents, Trademarks, Utility Models and
          Designs in more than 180 countries of the world.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- What is Pascal IP -->
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 text-center fadeInLeft">
        <img
          src="./assets/img/services/services-01.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
      <div class="col-md-6 text-start text-md-start fadeInRight">
        <div>
          <div class="sec-title text-start text-md-start">
            PCT National Phase Applications
          </div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>
        <p>
          If your PCT National Phase application has been published, simply
          insert the PCT Serial Number and Pascal IP will provide you with an
          accurate and complete estimate for filing, prosecuting, granting, and
          maintaining PCT National Phase applications in the countries of your
          choice.
        </p>
        <p class="mb-4">
          You don’t need to input the particulars of your case. Pascal IP
          communicates with the WIPO servers to Collect all the necessary
          information for your estimate.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-start text-md-end order-2 order-md-1 fadeInLeft"
      >
        <div>
          <div class="sec-title text-start text-md-end">
            Direct Filing/Paris Convention Applications
          </div>
          <div class="sec-line ms-0 ms-md-auto"></div>
        </div>
        <p>
          Not all countries belong to the PCT treaty, and not every invention
          deserves a PCT application. 28 countries are not part of the PCT. You
          will need to file your patent application in those countries one year
          after your initial filing.
        </p>
        <p class="mb-4">
          Pascal IP provides online estimates in more than 180 countries. You
          will know in advance exactly how much money your foreign filings will
          cost. Our estimates include all fees involved in the whole life of
          your patent — translations, official fees, professional fees,
          legalizations, etc.
        </p>
      </div>
      <div class="col-md-6 text-center order-1 order-md-2 fadeInRight">
        <img
          src="./assets/img/services/service-02.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
    </div>
  </div>
</div>
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 text-center fadeInLeft">
        <img
          src="./assets/img/services/services-03.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
      <div class="col-md-6 text-start text-md-start fadeInRight">
        <div>
          <div class="sec-title text-start text-md-start">
            European Patent Validations
          </div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>
        <p>
          Once a European patent is granted, the grant fee is paid, and mention
          of the grant is published in the Bulletin, the European patent has to
          be validated in each of the designated states within a specific time
          frame in order to be enforceable against infringers.
        </p>
        <p class="mb-4">
          Simply select those countries where you would like to validate your
          European patent, and the system will provide you with an accurate cost
          in the 38 countries of the European Patent Agreement (EPA).
        </p>
      </div>
    </div>
  </div>
</div>
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-start text-md-end order-2 order-md-1 fadeInLeft"
      >
        <div>
          <div class="sec-title text-start text-md-end">Utility Models</div>
          <div class="sec-line ms-0 ms-md-auto"></div>
        </div>

        <p class="mb-4">
          In some countries, you can protect so-called “small inventions” or
          “utility models.” Pascal IP provides not only the costs of filing,
          obtaining, and maintaining utility models in those countries, but also
          a detailed explanation of how the system works.
        </p>
      </div>
      <div class="col-md-6 text-center order-1 order-md-2 fadeInRight">
        <img
          src="./assets/img/services/service-utility-model.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
    </div>
  </div>
</div>
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 text-center fadeInLeft">
        <img
          src="./assets/img/services/service-design-01.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
      <div class="col-md-6 text-start text-md-start fadeInRight">
        <div>
          <div class="sec-title text-start text-md-start">Designs</div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>

        <p class="mb-4">
          After you have filed an initial design patent application, you have
          six non-extendable months to file it in any of the 173 countries of
          the Paris Convention. Pascal IP can help you estimate your filing,
          prosecuting, granting, and maintaining costs in ANY of them, including
          costs associated with translations, adaptation of drawings (if any),
          legalizations, and extra costs.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-start text-md-end order-2 order-md-1 fadeInLeft"
      >
        <div>
          <div class="sec-title text-start text-md-end">Trademarks</div>
          <div class="sec-line ms-0 ms-md-auto"></div>
        </div>
        <p>
          Considering trademark laws and regulations vary from country to
          country, it is always a challenge to extend trademark rights abroad.
          It helps to have, in advance, a full and detailed budget that covers
          all stages of foreign trademark registration — preferably before you
          even begin the registration process.
        </p>
        <p class="mb-4">
          Pascal IP provides the costs of filing, prosecuting, registering, and
          maintaining trademarks in more than 180 countries, offering you the
          opportunity to pick the foreign IP law firm of your preference.
        </p>
      </div>
      <div class="col-md-6 text-center order-1 order-md-2 fadeInRight">
        <img
          src="./assets/img/services/services-last.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
    </div>
  </div>
</div>
