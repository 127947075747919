<div class="sec-pad ">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10 col-lg-7 wow fadeInUp">
          <alert *ngIf="messageSuccess" type="success">{{messageSuccess}}</alert>
        </div>
      </div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-10 col-lg-7 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                  <div class="login-box text-start border-box-padding">
                      <div class="text-center">
                        <div>
                            <div class="sec-title text-center">
                               Your Subscription
                            </div>
                            <div class="sec-line m-auto"></div>
                        </div>
                        <p class="mb-4">Your Subscription Details</p>
                      </div>
                      <div class="mt-4"></div>
                      <div class="table-responsive">
                        <ng-container *ngIf="subscription != null; else elseTemplate">
                          <table class="table simple-table text-start">
                            <tbody>
                              <tr>
                                <th>Subscription Type</th>
                                <td>{{subscription.SubscriptionType}}</td>
                              </tr>
                              <tr *ngIf="subscription.NumberOfQuotesRemaiting != null">
                                <th>Number of Quotes remaining</th>
                                <td>{{subscription.NumberOfQuotesRemaiting}}</td>
                              </tr>
                              <tr *ngIf="subscription.NumberOfUserRemaint != null">
                                <th>Number of user remaint</th>
                                <td>{{subscription.NumberOfUserRemaint}}</td>
                              </tr>
                              <tr>
                                <th>Valid Since</th>
                                <td>{{subscription.ValidSince | date: 'dd MMMM yyyy'}}</td>
                              </tr>
                              <tr>
                                <th>Valid Thru</th>
                                <td>{{subscription.ValidThru | date: 'dd MMMM yyyy'}}</td>
                              </tr>
                              <tr>
                                <th>Cost</th>
                                <td>{{subscription.Cost | currency:'':''}} USD</td>
                              </tr>
                            </tbody>
                        </table>
                        </ng-container>
                        <ng-template #elseTemplate>
                          <table class="table simple-table text-start">
                            <tbody>
                              <tr *ngFor="let i of generateFake(5)">
                                <td>
                                  <ngx-skeleton-loader [theme]="{ width: '100%' }"></ngx-skeleton-loader>
                                </td>
                              </tr>
                            </tbody>
                        </table>
                        </ng-template>
                        
                      </div>
                      <div class="text-center">
                        <button (click)="redirectToPrice()" class="btn btn-theme br-8 wow fadeInUp mt-4" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;"><span class="fa fa-plus me-2"></span>New Subscription</button>
                      </div>
                  </div>
              </div>
        </div>
    </div>
</div>