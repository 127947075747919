import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ip-kinds-covered',
  templateUrl: './ip-kinds-covered.component.html',
  styleUrls: ['./ip-kinds-covered.component.css']
})
export class IpKindsCoveredComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
