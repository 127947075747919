import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidatorRegister } from 'src/app/core/validators/register';
import { AccountService } from '../../../../core/services/apis/account.service';
import { CreateUser } from '../../../../core/models/yourProfile';
import { StorageService } from '../../../../core/services/base/storage.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-profile-information-user-form',
  templateUrl: './profile-information-user-form.component.html',
  styleUrls: ['./profile-information-user-form.component.css']
})
export class ProfileInformationUserFormComponent implements OnInit {
  public item: CreateUser;
  public messageError: any;
  public event: EventEmitter<any> = new EventEmitter();
  formUserForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private validatorRegister: ValidatorRegister,
    private svcAccount: AccountService,
    private svcStorage: StorageService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.formUserForm = this.formBuilder.group({
      userId: [this.item?.Id],
      firstName: [this.item?.FirstName, Validators.required],
      lastName: [this.item?.LastName, Validators.required],
      email: [this.item?.Email, [Validators.required, Validators.pattern(regexEmail)]],
      password: this.getPasswordValidators(),
      confirmPassword: this.getPasswordValidators(),
      active: [this.item?.Active ?? true],
    },
    {
      validators: this.getCustomValidators(),
      updateOn: 'change',
    });
  }

  private getPasswordValidators(){
    const regexPassword = /^((?=\S*?[A-Z])(?=\S*?[a-z]).{6,})\S$/;
    if(this.item != null){
      return [''];
    }
    return ['', [Validators.required,  Validators.pattern(regexPassword)]]
  }
  private getCustomValidators(){
    if(this.item != null){
      return [];
    }
    return [
      this.validatorRegister.match('password', 'confirmPassword'),
    ];
  }

  saveUser(){
    const session = this.svcStorage.getCurrentSession();
    if(session){
      let user: CreateUser = {
        Id: this.formUserForm.get('userId').value,
        FirstName: this.formUserForm.get('firstName').value,
        LastName: this.formUserForm.get('lastName').value,
        Password: this.formUserForm.get('password').value,
        Active: this.formUserForm.get('active').value,
        Email: this.formUserForm.get('email').value,
        Token: session.token
      };
      this.svcAccount.saveUser(user)
        .then(result => {
          if(result.IsSucess){
            let data = {data: {save: true}, res: 200, msg: ""};
            data.msg = user.Id ? "User updated!" : "User created!";
            this.event.emit(data);
          }else{
            let data = {data: {save: false}, res: 500, msg: result.MessageError};
            this.event.emit(data);
          }
          this.bsModalRef.hide();
        })
        .catch((error) => {
          alert(JSON.stringify(error.error));
        });
    }
  }

}
