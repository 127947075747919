import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuardGuard } from './core/guards/auth-guard.guard';
import { AuthIsAdminGuard } from './core/guards/auth-is-admin.guard';
import { AuthLoginWithSubscriptionGuard } from './core/guards/auth-login-with-subscription.guard';
import { AboutComponent } from './pages/about/about.component';
import { AccountComponent } from './pages/account/account.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CountryGuideComponent } from './pages/country-guide/country-guide.component';
import { CustomerServicesComponent } from './pages/customer-services/customer-services.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FeaturesComponent } from './pages/features/features.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { IpKindsCoveredComponent } from './pages/ip-kinds-covered/ip-kinds-covered.component';
import { IsPascalIpForMeComponent } from './pages/is-pascal-ip-for-me/is-pascal-ip-for-me.component';
import { LoginSecurityComponent } from './pages/login-security/login-security.component';
import { MainComponent } from './pages/main/main.component';
import { NopagefoundComponent } from './pages/nopagefound/nopagefound.component';
import { PriceComponent } from './pages/price/price.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { RegisterComponent } from './pages/register/register.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { StepFormComponent } from './pages/step-form/step-form.component';
import { TermOfUseComponent } from './pages/term-of-use/term-of-use.component';
import { WhyPascalIpComponent } from './pages/why-pascal-ip/why-pascal-ip.component';
import { YourPaymentsComponent } from './pages/your-payments/your-payments.component';
import { YourProfileInformationComponent } from './pages/your-profile-information/your-profile-information.component';
import { YourQuotesComponent } from './pages/your-quotes/your-quotes.component';
import { YourSubscriptionComponent } from './pages/your-subscription/your-subscription.component';
import { QuoteDetailComponent } from './pages/quote-detail/quote-detail.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ActiveUserComponent } from './pages/active-user/active-user.component';
import { GenerateOrderPdfComponent } from './pages/generate-order-pdf/generate-order-pdf.component';
import { AuthSubscriptionWithShowCountryGuideGuard } from './core/guards/auth-show-country-guide.guard';
import { AuthIsValidToken } from './core/guards/auth-is-valid-token.guard';
import { IpFirmsDirectoryComponent } from './pages/ip-firms-directory/ip-firms-directory.component';
const routes: Routes = [
  { path: 'main', component: MainComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'ip-firms-directory', component: IpFirmsDirectoryComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'ip-kinds-covered', component: IpKindsCoveredComponent },
  { path: 'is-pascal-ip-for-me', component: IsPascalIpForMeComponent },
  {
    path: 'price',
    component: PriceComponent
  },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'terms-of-use', component: TermOfUseComponent },
  { path: 'why-pascal-ip', component: WhyPascalIpComponent },
  { path: 'login', component: LoginComponent },
  { path: 'services', component: ServicesPageComponent },
  { path: 'country-guide', component: CountryGuideComponent,
  canActivate: [AuthIsValidToken, AuthSubscriptionWithShowCountryGuideGuard] },
  {
    path: 'step-form',
    component: StepFormComponent,
    canActivate: [AuthIsValidToken, AuthLoginWithSubscriptionGuard],
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthIsValidToken, AuthGuardGuard],
  },
  {
    path: 'account/your-quotes',
    component: YourQuotesComponent,
    canActivate: [AuthIsValidToken, AuthGuardGuard],
  },
  {
    path: 'account/your-quotes/:id',
    component: QuoteDetailComponent,
    canActivate: [AuthIsValidToken, AuthGuardGuard],
  },
  {
    path: 'account/your-quotes/:id/pdf',
    component: GenerateOrderPdfComponent,
    canActivate: [AuthIsValidToken, AuthGuardGuard],
  },
  {
    path: 'account/login-security',
    component: LoginSecurityComponent,
    canActivate: [AuthIsValidToken, AuthGuardGuard],
  },
  {
    path: 'account/your-profile-information',
    component: YourProfileInformationComponent,
    canActivate: [AuthIsValidToken, AuthGuardGuard, AuthIsAdminGuard],
  },
  {
    path: 'account/your-subscription',
    component: YourSubscriptionComponent,
    canActivate: [AuthIsValidToken, AuthGuardGuard],
  },
  {
    path: 'account/customer-services',
    component: CustomerServicesComponent,
    canActivate: [AuthIsValidToken, AuthGuardGuard],
  },
  {
    path: 'account/your-payments',
    component: YourPaymentsComponent,
    canActivate: [AuthIsValidToken, AuthGuardGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'account/confirm/:token',
    component: ActiveUserComponent,
  },
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: '**', component: NopagefoundComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
