<!-- Form Step -->
<div class="sec-pad form-step-box">
  <div class="container">
    <alert type="success" [dismissible]="true" *ngIf="isEmailSend">Email sent successfully!</alert>
    <alert type="danger" [dismissible]="true" *ngIf="emailError">{{emailError}}</alert>
    <div class="row">
      <div class="col-md-12">
        <div class="px-0 mb-3">
          <div class="">
            <div>
              <div class="sec-title text-center">{{ matterName }}</div>
              <div class="sec-line ms-auto me-auto mb-4"></div>
            </div>
            <div class="d-flex justify-content-between">
              <button
                class="form-btn"
                (click)="router.navigate(['account/your-quotes'])"
              >
                Back
              </button>
              <div>
                <button
                class="form-btn"
                (click)="sendMail()"
              >
                Send Mail
              </button>
                <button
                class="form-btn"
                (click)="downloadPDF()"
              >
                Download PDF
              </button>
              </div>
            </div>
            <div class="result-box mt-4">
              <div class="rb-header fw-500">
                Cost Estimate for {{ this.tierTitle }}
              </div>
              <div class="result-body">
                <div class="result-body">
                  <div
                    *ngFor="let itemEstimate of listOtherParams"
                    class="result-li"
                  >
                    <div class="row">
                      <div class="col-lg-5">
                        {{ itemEstimate.SiteLabel }}
                      </div>
                      <div class="col-lg-2 text-start text-lg-center">
                        <i class="fa fa-long-arrow-right"></i>
                      </div>
                      <div class="col-lg-5 text-theme">
                        {{ itemEstimate.Value }}
                      </div>
                    </div>
                  </div>
                  <div
                    *ngFor="let itemEstimate of listEstimate"
                    class="result-li"
                  >
                    <div class="row">
                      <div class="col-lg-5">
                        {{ itemEstimate.ParameterUser.SiteLabel }}
                      </div>
                      <div class="col-lg-2 text-start text-lg-center">
                        <i class="fa fa-long-arrow-right"></i>
                      </div>
                      <div class="col-lg-5 text-theme" *ngIf="itemEstimate.ParameterUser.SiteIsFormatDate && !itemEstimate.ParameterUser.SiteIsCalculated">
                        {{ itemEstimate.Value | date: 'MM/dd/yyyy'}}
                      </div>
                      <div class="col-lg-5 text-theme" *ngIf="!itemEstimate.ParameterUser.SiteIsFormatDate || itemEstimate.ParameterUser.SiteIsCalculated">
                        {{ itemEstimate.Value }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <button class="form-btn float-none d-none">Revise Perameters</button>
                  <button
                    *ngIf="tierOption != 1"
                    class="form-btn float-none"
                    (click)="getLawFirmInformation()"
                  >
                  Law Firms Information
                  </button>
                </div>
                <div class="result-table-box">
                  <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-compact-view-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-compact-view"
                        type="button"
                        role="tab"
                        aria-controls="pills-compact-view"
                        aria-selected="true"
                      >
                        Compact View
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-detailed-view-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-detailed-view"
                        type="button"
                        role="tab"
                        aria-controls="pills-detailed-view"
                        aria-selected="false"
                      >
                        Detailed View
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-compact-view"
                      role="tabpanel"
                      aria-labelledby="pills-compact-view-tab"
                    >
                      <p class="text-theme">
                        <!-- PCT National Phase -->
                        {{ listOrders?.Data.MatterName }} Application Costs
                      </p>
                      <div class="table-responsive">
                        <ng-container
                          *ngIf="listOrders != null; else elseTemplate"
                        >
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Country</th>
                                <th
                                  *ngFor="
                                    let item of listOrders?.Data.ResumePrice
                                      .Headers
                                  "
                                  scope="col"
                                >
                                  {{ item }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let jurisdictionItem of listOrders?.Data
                                    .ResumePrice.Jurisdictions;
                                  let i = index
                                "
                              >
                                <td data-label="Country">
                                  {{ jurisdictionItem }}
                                </td>
                                <td
                                  *ngFor="
                                    let rowItem of listOrders?.Data.ResumePrice
                                      .Rows[i]
                                  "
                                  data-label="Examination"
                                >
                                  <span>
                                    $ {{ rowItem | number: "1.2-2" }}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="text-end fw-500 p-3">
                            Total Cost({{ listOrders?.Data.CurrencyName }})
                            <span class="ms-3"
                              >${{
                                listOrders?.Data.TotalPrice | number: "1.2-2"
                              }}</span
                            >
                          </div>
                        </ng-container>
                        <ng-template #elseTemplate>
                          <table class="table simple-table text-start">
                            <tbody>
                              <tr
                                *ngFor="
                                  let i of this.helperService.generateFake(2)
                                "
                              >
                                <td>
                                  <ngx-skeleton-loader
                                    [theme]="{ width: '100%' }"
                                  ></ngx-skeleton-loader>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </ng-template>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-detailed-view"
                      role="tabpanel"
                      aria-labelledby="pills-detailed-view-tab"
                    >
                      <div
                        *ngFor="
                          let detail of listOrders?.Data.DetailPrices;
                          let i = index
                        "
                      >
                        <p class="text-theme">{{ detail.TariffName }}</p>
                        <div class="table-responsive">
                          <div *ngIf="detail.ResumePrice.Headers.length >= 10">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Country</th>
                                  <th
                                    *ngFor="
                                      let resumeHeaders of detail.ResumePrice
                                        .Headers;
                                      let i = index
                                    "
                                    scope="col"
                                    [ngClass]="{ 'd-none': i >= 10 }"
                                  >
                                    {{ resumeHeaders }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let jurisdictionItem of detail.ResumePrice
                                      .Jurisdictions;
                                    let i = index
                                  "
                                >
                                  <td data-label="Country">
                                    {{ jurisdictionItem }}
                                  </td>
                                  <td
                                    *ngFor="
                                      let rowItem of detail.ResumePrice.Rows[i];
                                      let i = index
                                    "
                                    data-label="Professional Fees"
                                    [ngClass]="{ 'd-none': i >= 10 }"
                                  >
                                    $ {{ rowItem | number: "1.2-2" }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table class="table mt-3">
                              <thead>
                                <tr>
                                  <th scope="col">Country</th>
                                  <th
                                    *ngFor="
                                      let resumeHeaders of detail.ResumePrice
                                        .Headers;
                                      let i = index
                                    "
                                    scope="col"
                                    [ngClass]="{ 'd-none': i < 10 }"
                                  >
                                    {{ resumeHeaders }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let jurisdictionItem of detail.ResumePrice
                                      .Jurisdictions;
                                    let i = index
                                  "
                                >
                                  <td data-label="Country">
                                    {{ jurisdictionItem }}
                                  </td>
                                  <td
                                    *ngFor="
                                      let rowItem of detail.ResumePrice.Rows[i];
                                      let i = index
                                    "
                                    [ngClass]="{ 'd-none': i < 10 }"
                                    data-label="Professional Fees"
                                  >
                                    $ {{ rowItem | number: "1.2-2" }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div *ngIf="detail.ResumePrice.Headers.length < 10">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Country</th>
                                  <th
                                    *ngFor="
                                      let resumeHeaders of detail.ResumePrice
                                        .Headers
                                    "
                                    scope="col"
                                  >
                                    {{ resumeHeaders }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let jurisdictionItem of detail.ResumePrice
                                      .Jurisdictions;
                                    let i = index
                                  "
                                >
                                  <td data-label="Country">
                                    {{ jurisdictionItem }}
                                  </td>
                                  <td
                                    *ngFor="
                                      let rowItem of detail.ResumePrice.Rows[i]
                                    "
                                    data-label="Professional Fees"
                                  >
                                    $ {{ rowItem | number: "1.2-2" }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="text-end fw-500 p-3">
                          Total Cost({{ listOrders?.Data.CurrencyName }})
                          <span class="ms-3"
                            >$ {{ detail.TotalPrice | number: "1.2-2" }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="position: absolute;top: -2000px;left: -2000px" *ngIf="orderId">
  <app-generate-order-pdf [orderId]="orderId" (finishDownload)="orderId=null"></app-generate-order-pdf>
</div>