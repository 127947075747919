<!-- hero -->
<div class="hero">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-7 text-center">
        <h1
          class="wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
            margin-top:30px;
          "
        >
          YOUR GLOBAL IP COSTS, <br />
          UNDER CONTROL
        </h1>
        <p
          class="wow fadeInUp"
          data-wow-delay=".4s"
          style="
            visibility: visible;
            animation-delay: 0.4s;
            animation-name: fadeInUp;
          "
        >
          Pascal IP is a powerful tool to calculate the costs of registering and
          maintaining your IP assets around the world
        </p>
        <div class="mt-5">
          <a
            class="btn btn-theme mx-2 wow fadeInUp"
            data-wow-delay=".6s"
            routerLink="/step-form"
            style="
              visibility: visible;
              animation-delay: 0.6s;
              animation-name: fadeInUp;
            "
            >Get an Instant Quote Now!</a
          >
          <div class="w-100 d-block d-md-none mb-4"></div>
          <a
            class="mx-2 text-white watch-overview wow fadeInUp"
            data-wow-delay=".8s"
            (click)="openModal()"
            style="
              visibility: visible;
              animation-delay: 0.8s;
              animation-name: fadeInUp;
              
              cursor: pointer;
            "
            ><img
              src="./assets/img/icon/play-button-o.svg"
              class="img-fluid me-2"
              alt=""
            />
            Watch Brief Overview</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- What is Pascal IP -->
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row">
      <div
        class="col-md-6 text-center wow fadeInLeft"
        style="visibility: visible; animation-name: fadeInLeft"
      >
        <img
          src="./assets/img/easy-to-use.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
      <div
        class="col-md-6 text-start text-md-start wow fadeInRight"
        style="visibility: visible; animation-name: fadeInRight"
      >
        <div>
          <div class="sec-title text-start text-md-start">Easy to Use</div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>
        <p class="mb-4">
          Our easy-to-use, affordable, and reliable quoting system calculates
          the cost of patents, trademarks, designs, and utility models in more
          than 180 countries, covering:
        </p>
        <div class="text-start mb-4">
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>
              Filing, prosecution, issuance, and maintenance costs for PCT
              International and National Phase applications
            </div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>
              Direct Filing of patents and trademarks under the Paris Convention
            </div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>European patents</div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>Madrid Agreement applications</div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>… and much more.</div>
          </div>
        </div>
        <a class="btn btn-theme" [routerLink]="['/price']"
          >Discover More</a
        >
      </div>
    </div>
  </div>
</div>
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row">
      <div
        class="col-md-6 text-start text-md-end order-2 order-md-1 wow fadeInLeft"
        style="visibility: visible; animation-name: fadeInLeft"
      >
        <div>
          <div class="sec-title text-start text-md-end">What is Pascal IP?</div>
          <div class="sec-line ms-0 ms-md-auto"></div>
        </div>
        <p class="mb-4">
          Pascal IP is a powerful tool to calculate the costs of registering and
          maintaining your IP assets around the world, from filing to issuance.
          You will enjoy our exclusive “My Favorite Tier” system that allows you
          to pick the type of foreign associate you want to use. Foreign
          associates are classified into three tiers:
        </p>
        <div class="text-start mb-4">
          <div class="d-flex mb-3 justify-content-md-end justify-content-start">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>Large IP firms (Tier 1)</div>
          </div>
          <div class="d-flex mb-3 justify-content-md-end justify-content-start">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>Medium-small firms (Tier 2)</div>
          </div>
          <div class="d-flex mb-3 justify-content-md-end justify-content-start">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>IP boutiques (Tier 3)</div>
          </div>
        </div>
        <a class="btn btn-theme" [routerLink]="['/price']"
          >Discover More</a
        >
      </div>
      <div
        class="col-md-6 text-center order-1 order-md-2 wow fadeInRight"
        style="visibility: visible; animation-name: fadeInRight"
      >
        <img src="./assets/img/what-is.png" class="img-fluid" alt="Pascal" />
      </div>
    </div>
  </div>
</div>
<!-- services -->
<div class="services sec-pad pb-0">
  <div class="container">
    <div class="row">
      <div
        class="col-md-4 mb-4 mb-md-0 wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div class="s-box s-bg-1">
          <div class="sec-title mb-4">Basic</div>
          <p class="mb-0 text-center">
            When you select this option, you will receive an average tariff from
            several different firms per country. No specific firm can be picked.
          </p>
        </div>
      </div>

      <div
        class="col-md-4 mb-4 mb-md-0 wow fadeInUp"
        data-wow-delay=".2s"
        style="
          visibility: visible;
          animation-delay: 0.2s;
          animation-name: fadeInUp;
        "
      >
        <div class="s-box s-bg-2">
          <div class="sec-title mb-0">Pro</div>
          <p class="mb-0 text-center">
            This option will provide the average tariff of the firms classified
            in the Tier of your preference. No specific firm can be picked.
          </p>
        </div>
      </div>

      <div
        class="col-md-4 mb-4 mb-md-0 wow fadeInUp"
        data-wow-delay=".4s"
        style="
          visibility: visible;
          animation-delay: 0.4s;
          animation-name: fadeInUp;
        "
      >
        <div class="s-box s-bg-3">
          <div class="sec-title mb-0">Premier</div>
          <p class="mb-0 text-center">
            This option will provide the tariff of the firm of your choice in
            the Tier you selected. The system will also provide the contact
            details of the firm, including firm name, email address, physical
            address, phone number, and contact name. You can also click on the
            “Contact Me Now” button and the firm you chose will contact you to
            start the filing process you need.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 my-4 text-center wow fadeInUp"
        data-wow-delay=".6s"
        style="
          visibility: visible;
          animation-delay: 0.6s;
          animation-name: fadeInUp;
        "
      >
        <a class="btn btn-theme" [routerLink]="['/price']"
          >Discover More</a
        >
      </div>
    </div>
  </div>
</div>
