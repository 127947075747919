<div class="sec-pad">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 text-center wow fadeInUp" style="visibility: visible; animation-name: fadeInUp">
        <div>
          <div class="sec-title text-center">Your Profile Information</div>
          <div class="sec-line m-auto"></div>
        </div>
        <p class="mb-4">Profile Details</p>
      </div>
      <div class="mt-4"></div>
      <div class="col-md-12">
        <div class="account-info wow fadeInUp" style="visibility: visible; animation-name: fadeInUp">
          <ng-container *ngIf="customer != null; else elseCustomerTemplate">
            <div class="row">
              <div class="col col-6">
                <p>
                  <span class="first-text">Company Name</span>
                  <span class="medium">{{ customer?.CompanyName }}</span>
                </p>
                <p>
                  <span class="first-text">First Name</span>
                  <span class="medium">{{ customer?.FirstName }}</span>
                </p>
                <p>
                  <span class="first-text">Last Name</span>
                  <span class="medium">{{ customer?.LastName }}</span>
                </p>
              </div>
              <div class="col col-6">
                <p>
                  <span class="first-text">Phone</span>
                  <span class="medium">{{ customer?.Phone }}</span>
                </p>
                <p>
                  <span class="first-text">Email</span>
                  <span class="medium">{{ customer?.Email }}</span>
                </p>
              </div>
            </div>
          </ng-container>
          <ng-template #elseCustomerTemplate>
            <div class="row">
              <div class="col col-6">
                <p>
                  <span class="first-text">Company Name</span>
                  <span class="medium">
                    <ngx-skeleton-loader [theme]="{ width: '100px', marginBottom: '0px' }"></ngx-skeleton-loader>
                  </span>
                </p>
                <p>
                  <span class="first-text">First Name</span>
                  <span class="medium">
                    <ngx-skeleton-loader [theme]="{ width: '100px', marginBottom: '0px'  }"></ngx-skeleton-loader>
                  </span>
                </p>
                <p>
                  <span class="first-text">Last Name</span>
                  <span class="medium">
                    <ngx-skeleton-loader [theme]="{ width: '100px', marginBottom: '0px'  }"></ngx-skeleton-loader>
                  </span>
                </p>
              </div>
              <div class="col col-6">
                <p>
                  <span class="first-text">Phone</span>
                  <span class="medium">
                    <ngx-skeleton-loader [theme]="{ width: '100px', marginBottom: '0px'  }"></ngx-skeleton-loader>
                  </span>
                </p>
                <p>
                  <span class="first-text">Email</span>
                  <ngx-skeleton-loader [theme]="{ width: '100px', marginBottom: '0px'  }"></ngx-skeleton-loader>
                </p>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp">
          <div class="mt-3" *ngIf="messageSuccess != null">
            <alert type="success"> {{ messageSuccess }}</alert>
          </div>
          <div class="mt-3" *ngIf="messageError != null">
            <alert type="danger"> {{ messageError }}</alert>
          </div>
          <div *ngIf="isPerUser()">
            <p class="medium">Number of Users <span>{{users != null ? users.length : ''}}</span></p>
            <ng-container *ngIf="users != null; else elseTemplate">
              <div class="user-list" *ngFor="let item of users;let i = index;">
                <div class="d-flex justify-content-between">
                  <p class="orange-text">User {{getTextNumber(i)}}</p>
                  <p class="text-theme">
                    <a class="cursor-pointer mr-2" (click)="openModalForm(item)">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path
                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z">
                          </path>
                          <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z">
                          </path>
                        </svg>
                      </span>
                      Edit
                    </a>
                    <a class="cursor-pointer" (click)="openChangePasswordForm(item)">
                      <span style="margin-left: 8px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-key" viewBox="0 0 18 18">
                          <path
                            d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                          <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                      </span>
                      Change Password
                    </a>
                  </p>
                </div>
                <ul class="user-details-list ps-0 mb-0">
                  <li>
                    <p class="op-7">
                      <span><img src="./assets/img/icon/user.svg" class="img-fluid me-3"
                          alt="icon" /></span>{{item.FirstName + " " + item.LastName}}
                    </p>
                  </li>
                  <li>
                    <p class="op-7">
                      <span><img src="./assets/img/icon/email.svg" class="img-fluid me-3"
                          alt="icon" /></span>{{item.Email}}
                    </p>
                  <li>
                    <p class="op-7">
                      {{item.Active ? "Active" : "Non Active"}}
                    </p>
                  </li>
                </ul>
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <div class="user-list" *ngFor="let item of generateFake(5);let i = index;">
                <div class="d-flex justify-content-between">
                  <p class="orange-text">User {{getTextNumber(i)}}</p>
                  <p class="text-theme">
                    <ngx-skeleton-loader [theme]="{ width: '100px', marginRight: '25px' }"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{ width: '100px' }"></ngx-skeleton-loader>
                  </p>
                </div>
                <ul class="user-details-list ps-0 mb-0">
                  <li>
                    <p class="op-7">
                      <span>
                        <ngx-skeleton-loader [theme]="{ width: '200px' }"></ngx-skeleton-loader>
                      </span>
                    
                    </p>
                  </li>
                  <li>
                    <p class="op-7">
                      <span>
                        <ngx-skeleton-loader [theme]="{ width: '200px' }"></ngx-skeleton-loader>
                      </span>
                    </p>
                  <li>
                    <p class="op-7">
                      <ngx-skeleton-loader [theme]="{ width: '200px' }"></ngx-skeleton-loader>
                    </p>
                  </li>
                </ul>
              </div>
            </ng-template>
            <div class="col-12 text-center mt-5">
              <button class="btn btn-theme br-8 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp" [disabled]="!enableAddNewUser"
                (click)="openModalForm()">
                <span class="fa fa-plus me-2"></span>Add New User
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>