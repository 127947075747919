<!-- hero -->
<div class="hero inner">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-7 text-center">
        <h1
          class="wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
          "
        >
          FAQs
        </h1>
        <p
          class="wow fadeInUp"
          data-wow-delay=".4s"
          style="
            visibility: visible;
            animation-delay: 0.4s;
            animation-name: fadeInUp;
          "
        >
          Your frequently asked questions, simply answered
        </p>
      </div>
    </div>
  </div>
</div>

<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-12 text-center wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div>
          <div class="sec-title text-center">FAQs</div>
          <div class="sec-line m-auto"></div>
        </div>
      </div>

      <div
        class="col-12 wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div class="accordion" id="accordionExample">
          <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                How long has your company been in the market?
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                PascaL IP is a service supported by
                <a href="http://www.inventiaglobal.com" class="text-primary"
                  >Inventia Global Patent Solutions</a
                >, a company with more than 20 years’ history offering online
                solutions to the IP community.
              </div>
            </div>
          </div>

          <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                How do you calculate the costs of an IP action?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo"
            >
              <div class="accordion-body">
                Our system includes the tariff of several IP firms in each
                country. Each firm is categorized as Tier 1 (big firm), Tier 2
                (medium-sized), or Tier 3 (IP boutique). Each tier is populated
                with at several firms. All Pascal IP quotes include the
                professional fees of each associate, plus the official fees and
                translation costs (if any). You can select the tier you want to
                work with based on your preferences and budget. Pascal IP can
                provide a quote based on the average tariff of the firms in the
                three tiers (“standard quote”), an average cost of one specific
                tier, or the cost of one specific firm in a specific tier
                (“customized quote”).
              </div>
            </div>
          </div>

          <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="panelsStayOpen-heading3">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse3"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse3"
              >
                Who are the associates whose tariffs are used to calculate the
                costs?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse3"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-heading3"
            >
              <div class="accordion-body">
                When you pick a specific firm from a tier, you have access to
                the firm name, physical address, contact name, email address,
                and website of that firm so you may contact them directly.
              </div>
            </div>
          </div>

          <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="panelsStayOpen-heading4">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse4"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse4"
              >
                Can you upload the tariff of a specific IP firm of my
                preference?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse4"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-heading4"
            >
              <div class="accordion-body">
                Yes, you just need to give us the firm name and contact number
                and we will include the tariff of that firm in our quoting
                system.
              </div>
            </div>
          </div>

          <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="panelsStayOpen-heading5">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse5"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse5"
              >
                Can I add in-house fees to the quote?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse5"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-heading5"
            >
              <div class="accordion-body">
                Yes, Pascal IP allows you to add in-house charges and
                miscellaneous costs for a specific country or the same amount
                for all the countries involved.
              </div>
            </div>
          </div>

          <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="panelsStayOpen-heading6">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse6"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse6"
              >
                Can I send a quote made with PascaL IP to my clients?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse6"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-heading6"
            >
              <div class="accordion-body">
                Yes, once the quote is ready, you can save it, print it, and/or
                email it to your clients. You have two options of how to display
                a quote — a detailed version, including every line item of the
                quote; or a summarized version with just the total cost per
                country.
              </div>
            </div>
          </div>

          <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="panelsStayOpen-heading7">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse7"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse7"
              >
                Can I try the system before purchasing it?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse7"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-heading7"
            >
              <div class="accordion-body">
                We offer a free one-week full-access trial that you can use to
                evaluate Pascal IP before making a purchase decision. You can
                cancel your subscription at any time — none of our tariff
                options include a long-term commitment.
              </div>
            </div>
          </div>

          <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="panelsStayOpen-heading8">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse8"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse8"
              >
                If we have several offices in different locations. Do we have to
                pay a fee per location?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse8"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-heading8"
            >
              <div class="accordion-body">
                No, all your branches can use Pascal IP at the same time, at no
                extra cost.
              </div>
            </div>
          </div>

          <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="panelsStayOpen-heading9">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse9"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse9"
              >
                Do I need to install any software on my computer before using
                Pascal IP?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse9"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-heading9"
            >
              <div class="accordion-body">
                Our service is Cloud-based and accessible from any web browser.
                There is no need to install any software on your computer. You
                can access your Pascal IP account from any computer with an
                Internet connection.
              </div>
            </div>
          </div>

          <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="panelsStayOpen-heading10">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse10"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse10"
              >
                How many countries, jurisdictions, and regional agreements are
                covered?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse10"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-heading10"
            >
              <div class="accordion-body">
                All major countries, jurisdictions, and regional agreements are
                included in Pascal IP. Currently, 182 countries are available
                for patents, 84 for utility models, 201 for trademarks, and 155
                for designs.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-heading11">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse11"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse11"
              >
                What currencies are used to make the calculations?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse11"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-heading11"
            >
              <div class="accordion-body">
                Pascal IP is connected to an on-fly exchange rates system that
                allows us to provide quotes in any currency, worldwide. Once
                Pascal IP makes the initial calculation, you can change the
                quote to the currency of your preference.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
