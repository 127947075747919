<div class="row" *ngIf="isSuccess == null && item">
    <div class="col-8">
        <div class="container p-4 pb-0">
            <h5 class="text-uppercase sec-title text-start">This Quote has been sponsored by</h5>
        </div>
        <app-law-firm-detail *ngIf="item" [item]="item"></app-law-firm-detail>
        <div class="container p-4 pt-0">
            <div style="font-size: 16px;">
                <span><b>Requeriments for filing:</b></span><br />
                <p>{{ item.Detail.RequerimentsForFiling }}</p>
            </div>
            <ul>
                <li *ngFor="let doc of item.DocsForFiling">
                    <a (click)="download(doc)" class="link-primary c-p">{{doc.Name}}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-4">
        <div class="d-flex h-100 align-items-end">
            <button [disabled]="isLoading" class="form-btn btn-contanct" (click)="sendEmail()">
                <span *ngIf="!isLoading">Click here to contact this firm</span>
                <div *ngIf="isLoading" class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
            </button>
        </div>  
    </div>
</div>
<div class="container p-4" *ngIf="isSuccess==true">
    <alert type="success" [dismissible]="true" (onClose)="close()">Email sent successfully!</alert>
</div>
<div class="container p-4" *ngIf="isSuccess==false">
    <alert type="danger" [dismissible]="true" (onClose)="close()">{{messageError}}</alert>
</div>