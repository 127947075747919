import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PaymentResponseDTO } from 'src/app/core/models/yourPayments';
import { AccountService } from '../../core/services/apis/account.service';
import { StorageService } from '../../core/services/base/storage.service';
import { LoadingService } from '../../core/services/shared/loading.service';
const PaymentMehtodEnum = 
{
    CreditCard: 1,
    Paypal: 2,
    CheckOrWire: 3
}
@Component({
  selector: 'app-your-payments',
  templateUrl: './your-payments.component.html',
  styleUrls: ['./your-payments.component.css']
})
export class YourPaymentsComponent implements OnInit {
  payments: Array<PaymentResponseDTO>;
  lastPayment: PaymentResponseDTO;
  isEmpty: boolean;

get isPaypal(){
  return this.lastPayment.PaymentMethod.Id == PaymentMehtodEnum.Paypal;
}
get isCreditOrDebit(){
  return this.lastPayment.PaymentMethod.Id == PaymentMehtodEnum.CreditCard;
}

  constructor(
    private svcAccount: AccountService,
    private svcStorage: StorageService,
    private svcLoading: LoadingService
  ) { 
    this.isEmpty = false;
  }

  ngOnInit(): void {
    const session = this.svcStorage.getCurrentSession();
    this.svcLoading.show();
    if(session){
      this.svcAccount.getPayments({Token: session.token})
      .then(result => {
        if(result.IsSucess){
          this.payments = result.ListData;
          this.isEmpty = result.ListData.length == 0;
          if(this.payments.length > 0){
            this.lastPayment = this.payments[0];
          }
        }
      this.svcLoading.hide();
      }).catch((error) => {
        this.svcLoading.hide();
        alert(JSON.stringify(error.error));
      });
    }
  }
  getPaymentMethod():string{
    let paymentMethod = this.lastPayment.PaymentMethod;
    let text = this.getPaymentMethodName(paymentMethod.Id);
    if(paymentMethod.ExtraData){
      text+= ` (${paymentMethod.ExtraData})`;
    }
    return text;
  }
  getPaymentMethodName(Id: number) {
    switch(Id){
      case PaymentMehtodEnum.CheckOrWire:
        return "Check/Wire";
      case PaymentMehtodEnum.CreditCard:
        return "Credit or Debit card";
      case PaymentMehtodEnum.Paypal:
        return "Paypal";
    }
  }
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
}
