<!-- hero -->
<div class="hero inner">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-7 text-center">
        <h1 class="fadeInUp" data-wow-delay=".2s">Is Pascal Ip for me?</h1>
        <p class="fadeInUp" data-wow-delay=".4s">
          PASCAL IP is a powerful tool for any IP firm, regardless of its size
          and budget. Our subscription and pricing policies make it possible to
          adjust our level of service to fit your needs.
        </p>
      </div>
    </div>
  </div>
</div>

<!-- What is Pascal IP -->
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-center wow fadeInLeft"
        style="visibility: visible; animation-name: fadeInLeft"
      >
        <img
          src="./assets/img/is-pascal-ip-for-me/pascal-for-me-1.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
      <div
        class="col-md-6 text-start text-md-start wow fadeInRight"
        style="visibility: visible; animation-name: fadeInRight"
      >
        <div>
          <div class="sec-title text-start text-md-start">
            If you are a large law firm …
          </div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>
        <div class="d-flex mb-3">
          <img src="./assets/img/arrow.svg" class="img-fluid me-3" alt="icon" />
          <div>
            You can quote competitively, despite the fact that foreign filing
            costs change frequently. No need to overprice your foreign filings
            to cover unexpected contingencies.
          </div>
        </div>
        <div class="d-flex mb-3">
          <img src="./assets/img/arrow.svg" class="img-fluid me-3" alt="icon" />
          <div>
            You will be able to predict to your client’s long-term cost for
            patent and trademark cases before filing.
          </div>
        </div>
        <div class="d-flex mb-3">
          <img src="./assets/img/arrow.svg" class="img-fluid me-3" alt="icon" />
          <div>No extra costs for multiple branches.</div>
        </div>
        <div class="d-flex mb-3">
          <img src="./assets/img/arrow.svg" class="img-fluid me-3" alt="icon" />
          <div>Pick the foreign associate of your choice in each country.</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-start text-md-end order-2 order-md-1 wow fadeInLeft"
        style="visibility: visible; animation-name: fadeInLeft"
      >
        <div>
          <div class="sec-title text-start text-md-end">
            If you are a medium-sized or smaller law firm …
          </div>
          <div class="sec-line ms-0 ms-md-auto"></div>
        </div>
        <div class="text-start">
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>
              You can get an accurate quote in seconds, without contacting
              colleagues in each country for each case.
            </div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>
              Customize the system to receive and send quotes to your clients
              directly from our website, branded with your logo and contact
              information.
            </div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>
              Pick the type of foreign associate you want to work with (large,
              medium size, boutique).
            </div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>
              Powerful tool for predicting the long-term cost of a patent or
              trademark around the world.
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-6 text-center order-1 order-md-2 wow fadeInRight"
        style="visibility: visible; animation-name: fadeInRight"
      >
        <img
          src="./assets/img/is-pascal-ip-for-me/pascal-for-me-2.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
    </div>
  </div>
</div>
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-center wow fadeInLeft"
        style="visibility: visible; animation-name: fadeInLeft"
      >
        <img
          src="./assets/img/is-pascal-ip-for-me/pascal-for-me-3.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
      <div
        class="col-md-6 text-start text-md-start wow fadeInRight"
        style="visibility: visible; animation-name: fadeInRight"
      >
        <div>
          <div class="sec-title text-start text-md-start">
            If you are a corporate IP office …
          </div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>
        <div class="d-flex mb-3">
          <img src="./assets/img/arrow.svg" class="img-fluid me-3" alt="icon" />
          <div>
            You can customize the system to the countries and the associates of
            your choice.
          </div>
        </div>
        <div class="d-flex mb-3">
          <img src="./assets/img/arrow.svg" class="img-fluid me-3" alt="icon" />
          <div>No extra costs for multiple branches.</div>
        </div>
        <div class="d-flex mb-3">
          <img src="./assets/img/arrow.svg" class="img-fluid me-3" alt="icon" />
          <div>
            Powerful tool for predicting the long-term cost of a patent or
            trademark around the world.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
