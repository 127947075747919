import { Component, OnInit, TemplateRef } from '@angular/core';
import { LoadingService } from '../../core/services/shared/loading.service';
import { AccountService } from '../../core/services/apis/account.service';
import { StorageService } from '../../core/services/base/storage.service';
import { OrderDto } from '../../core/models/yourQuotes';
import { ActivatedRoute, Router } from '@angular/router';
import { StepFormService } from 'src/app/core/services/apis/stepForm.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { SendEmailComponent } from './modal/send-email/send-email.component';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';


@Component({
  selector: 'app-your-quotes',
  templateUrl: './your-quotes.component.html',
  styleUrls: ['./your-quotes.component.css'],
})
export class YourQuotesComponent implements OnInit {
  public listQuotes: Array<OrderDto>;
  modalRef?: BsModalRef;
  sendEmailError: any = false;
  private maxJurisdictionToShow = 3;
  orderId: string = null;

  bsConfig?: Partial<BsDatepickerConfig>;
  dateFrom = new Date();
  dateTo = new Date();

  constructor(
    private srvService: AccountService,
    private storageService: StorageService,
    private svcLoading: LoadingService,
    private srvStepForm: StepFormService,
    private router: Router,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.bsConfig = Object.assign({}, { containerClass: "theme-default"});
    this.dateFrom.setDate(this.dateTo.getDate() -60 );

    this.search();
  }
  private search() {
    const session = this.storageService.getCurrentSession();
    if (session) {
      this.svcLoading.show();
      this.srvService
        .getOrderByCustomer({
          token: session.token,
          DateFrom: this.dateFrom,
          DateTo: this.dateTo
        })
        .then((result) => {
          if (result.IsSucess) {
            this.listQuotes = result.ListData;
            console.log(this.listQuotes);
          }
          this.svcLoading.hide();
        });
    }
  }

  getJurisdictions(item: OrderDto) {
    let texts = [];
    item.OrderJurisdictions.forEach((orderJurisdiction) => {
      if (texts.length < this.maxJurisdictionToShow) {
        texts.push(orderJurisdiction.Jurisdiction.NameEn);
      }
    });
    let result = texts.join(', ');
    if (texts.length == this.maxJurisdictionToShow) {
      result += '...';
    }
    return result;
  }
  openQuoteModal(item: OrderDto) {
    this.router.navigate(['account/your-quotes', item.Id]);
  }
  downloadPdf(item: OrderDto) {
    this.orderId = item.Id;
  }
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
  returnToMyProfile() {
    this.router.navigate(['account']);
  }
  sendEmail(item: OrderDto, template: TemplateRef<any>){
    let modal= this.modalService.show(SendEmailComponent, {
      initialState: {
        orderId: item.Id
      }
    });
    modal.onHide.subscribe(value => {
      if(value == true){
        setTimeout(()=>{
          this.modalService.show(template);
        }, 1000);
      }
    });
  }

  onClickSearchButton(){
    this.search();
  }
}
