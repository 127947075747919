import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/auth/login.service';
import { StorageService } from '../services/base/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthIsValidToken implements CanActivate {
  constructor(private svclogin: LoginService, private router: Router, private svcLocalStorage: StorageService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const response = new Promise<boolean>(async (resolve, reject) => {
        let result = await this.svclogin.isValidToken();
        resolve(result.IsSucess == true);
        if(result.IsSucess == false){
          this.svcLocalStorage.logout();
          return false;
        }
    });
    return response;
  }
  
}
