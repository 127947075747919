<!-- hero -->
<div class="hero inner">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-7 text-center">
        <h1
          class="wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
          "
        >
          Pricing
        </h1>
      </div>
    </div>
  </div>
</div>
<!-- What is Pascal IP -->
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row">
      <div class="col col-12">
        <alert *ngIf="messageSuccess" type="success">{{messageSuccess}}</alert>
      </div>
    </div>
    <div class="row d-flex align-items-center">
      <div class="col-md-6 text-center fadeInLeft">
        <img
          src="./assets/img/price/price-01.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
      <div class="col-md-6 text-start text-md-start fadeInRight">
        <div>
          <div class="sec-title text-start text-md-start">
            Our Pricing Policy
          </div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>
        <p class="mb-4">
          We understand that every IP firm has different needs . This is when
          the flexibility of Pascal IP comes in handy. We have many alternatives
          for you to choose from … but if none of them fit your needs, don’t
          hesitate to reach out to us. We will gladly customize our tariff to
          your firm.
        </p>
        <p class="mb-4">
          The Pascal IP pricing policy is straightforward — you can purchase a
          set number of estimates to use whenever and wherever you want; or you
          can purchase unlimited monthly access to Pascal IP. Our “all
          application types” service includes filing, prosecuting, issuing,
          maintaining, and assigning costs for patents, trademarks, designs, and
          utility models in 180+ countries.
        </p>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="listSubscription != null; else elseTemplate">
  <div class="sec-pad pb-0">
    <div class="sec-pad pb-0 sec-top-7" id="choose-your-plan">
      <div class="container">
        <div class="row d-flex align-items-center sub-container">
          <div class="fadeInUp">
            <div>
              <div class="sec-title text-center">Choose Your Plan</div>
              <div class="sec-line m-auto"></div>
            </div>
          </div>
          <div class="col-md-12 fadeInUp table-responsive">
            <table class="table" id="choose-your-plan-table">
              <thead class="">
                <tr>
                  <th></th>
                  <th *ngFor="let item of listSubscription">
                    {{ item.Name | uppercase }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="d-flex align-items-center min-140 height-td">
                    Price
                  </td>
                  <td *ngFor="let item of listSubscription">
                    <span class="fw-500 ft-25">{{
                      item.Price == 0 ? "FREE" : "$" + item.Price
                    }}</span>
                    <br />
                    {{ getTextPay(item) }}<br />

                    <a
                      class="btn btn-orange mt-2"
                      (click)="openModalWithComponent(item)"
                      >{{ getTextButton(item) }}</a
                    >
                  </td>
                </tr>
                <tr class="" *ngFor="let iMatter of listMatters">
                  <td class="text-start">
                    {{ iMatter.Name }}
                  </td>
                  <td class="text-center" *ngFor="let item of listSubscription">
                    <span
                      [ngClass]="
                        getCheck(item, iMatter)
                          ? 'ft-25 text-success'
                          : 'ft-25 text-danger'
                      "
                      ><i
                        [ngClass]="
                          getCheck(item, iMatter)
                            ? 'fa fa-check'
                            : 'fa fa-close'
                        "
                      ></i>
                    </span>
                  </td>
                </tr>
                <tr class="" *ngFor="let iTariff of listTariffs">
                  <td class="text-start">
                    {{ iTariff.Name }}
                  </td>
                  <td class="text-center" *ngFor="let item of listSubscription">
                    <span
                      [ngClass]="
                      getCheckForTariffGroup(item, iTariff)
                          ? 'ft-25 text-success'
                          : 'ft-25 text-danger'
                      "
                      ><i
                        [ngClass]="
                        getCheckForTariffGroup(item, iTariff)
                            ? 'fa fa-check'
                            : 'fa fa-close'
                        "
                      ></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <!-- Loading Items -->
  <div class="sec-pad pb-0">
    <div class="sec-pad pb-0 sec-top-7" id="choose-your-plan">
      <div class="container">
        <div class="row d-flex align-items-center sub-container">
          <div class="fadeInUp">
            <div>
              <div class="sec-title text-center">Choose Your Plan</div>
              <div class="sec-line m-auto"></div>
            </div>
          </div>
          <div class="col-md-12 fadeInUp table-responsive">
            <table class="table">
              <thead class="">
                <tr>
                  <th></th>
                  <th *ngFor="let item of generateFake(5)">
                    <ngx-skeleton-loader
                      [theme]="{ width: '100px' }"
                    ></ngx-skeleton-loader>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="d-flex align-items-center min-140 height-td">
                    <div class="item-img">
                      <ngx-skeleton-loader
                        [theme]="{ width: '100px' }"
                      ></ngx-skeleton-loader>
                    </div>
                  </td>
                  <td *ngFor="let item of generateFake(5)">
                    <span class="fw-500 ft-25"
                      ><ngx-skeleton-loader
                        [theme]="{ width: '100px' }"
                      ></ngx-skeleton-loader>
                    </span>
                    <br />
                    <ngx-skeleton-loader
                      [theme]="{ width: '100px' }"
                    ></ngx-skeleton-loader>
                    <br />
                    <ngx-skeleton-loader
                      [theme]="{
                        'border-radius': '35px',
                        height: '50px',
                        border: '1px solid white'
                      }"
                    ></ngx-skeleton-loader>
                  </td>
                </tr>
                <tr class="" *ngFor="let item of generateFake(7)">
                  <td class="text-start">
                    <ngx-skeleton-loader
                      [theme]="{ width: '100px' }"
                    ></ngx-skeleton-loader>
                  </td>
                  <td class="text-center" *ngFor="let item of generateFake(5)">
                    <span
                      [ngClass]="
                        true ? 'ft-25 text-success' : 'ft-25 text-danger'
                      "
                      ><ngx-skeleton-loader
                        [theme]="{ width: '100px' }"
                      ></ngx-skeleton-loader>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-12 fadeInUp">
        <div class="bg-primary-box">
          <div>
            <div class="sec-title text-center">
              The key features of our tariff
            </div>
            <div class="sec-line m-auto"></div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>
              No extra fees for multiple-users accounts. Several people in your
              organization may use the system at the same time.
            </div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>
              No extra fees for accessing the system from various locations. If
              you have more than one location, all your employees from different
              offices may use the system under the same account.
            </div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>No extra fees for assignment estimates.</div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>No expiration dates for unused estimates.</div>
          </div>
          <div class="d-flex mb-3">
            <img
              src="./assets/img/arrow.svg"
              class="img-fluid me-3"
              alt="icon"
            />
            <div>
              No long-term commitment required. You can cancel your subscription
              anytime.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
