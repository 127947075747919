<div class="sec-pad">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-md-12 text-center wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                <div>
                    <div class="sec-title text-center">
                       Your Account Information
                    </div>
                    <div class="sec-line m-auto"></div>
                </div>
                <p class="mb-4">Account Details</p>
            </div>
            <div class="mt-4"></div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 wow fadeInUp p-0" style="visibility: visible; animation-name: fadeInUp;">
            <a routerLink="/account/your-quotes">
              <div class="contact-box ">
                <img src="./assets/img/icon/left-quote.png" class="img-fluid mb-3" alt="img">
                <p class="text-black font-23 mb-1">Your Quotes</p>
                <p class="op-7 mb-0">View and manage your archived quotes</p>
              </div>
            </a>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 wow fadeInUp p-0" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
            <a routerLink="/account/login-security">
              <div class="contact-box ">
                <img src="./assets/img/icon/padlock.png" class="img-fluid mb-26" alt="img">
                <p class="text-black font-23 mb-1">Login &amp; Security</p>
                <p class="op-7 mb-0">Edit login info, user name and password</p>
              </div>
            </a>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 wow fadeInUp p-0" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
            <a routerLink="/account/your-profile-information" [ngClass]="{'disabled': !isCustomerAdmin}">
              <div class="contact-box ">
                <img src="./assets/img/icon/profile-user.png" class="img-fluid mb-3" alt="img">
                <p class="text-black font-23 mb-1">Your Profile</p>
                <p class="op-7 mb-0">Manage, add, or remove user profiles</p>
              </div>
            </a>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 wow fadeInUp p-0" style="visibility: visible; animation-name: fadeInUp;">
            <a routerLink="/account/your-subscription">
              <div class="contact-box ">
                <img src="./assets/img/icon/subscribe.png" class="img-fluid mb-3" alt="img">
                <p class="text-black font-23 mb-1">Your Subscription</p>
                <p class="op-7 mb-0">Manage your subscription features</p>
              </div>
            </a>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 wow fadeInUp p-0" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
            <a routerLink="/account/customer-services">
              <div class="contact-box ">
                <img src="./assets//img/icon/customer-service.png" class="img-fluid mb-26" alt="img">
                <p class="text-black font-23 mb-1">Customer Service</p>
                <p class="op-7 mb-0">What would you like help with today?</p>
              </div>
            </a>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 wow fadeInUp p-0" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
            <a routerLink="/account/your-payments">
              <div class="contact-box ">
                <img src="./assets/img/icon/wallet.png" class="img-fluid mb-3" alt="img">
                <p class="text-black font-23 mb-1">Your Payments</p>
                <p class="op-7 mb-0">Manage payment methods and settings, view all transactions</p>
              </div>
            </a>
          </div>
        </div>
    </div>
</div>