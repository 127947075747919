import { Component, OnInit } from '@angular/core';
import { CustomerDTO } from 'src/app/core/models/yourProfile';
import { AccountService } from '../../core/services/apis/account.service';
import { StorageService } from '../../core/services/base/storage.service';
import { LoadingService } from '../../core/services/shared/loading.service';
import { User } from '../../core/models/userDTO';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProfileInformationUserFormComponent } from './modal/profile-information-user-form/profile-information-user-form.component';
import { CreateUser } from '../../core/models/yourProfile';
import { Session } from 'src/app/core/models/base/session';
import { ChangePasswordComponent } from '../login-security/modal/change-password/change-password.component';

@Component({
  selector: 'app-your-profile-information',
  templateUrl: './your-profile-information.component.html',
  styleUrls: ['./your-profile-information.component.css']
})
export class YourProfileInformationComponent implements OnInit {
  public customer: CustomerDTO = null;
  public users: CreateUser[] = null;
  public messageSuccess: string;
  public messageError: string;
  bsModalRef: BsModalRef;
  public session: Session;
  private subscription: any;

  get enableAddNewUser(): boolean{
    if(this.session?.user?.subscription == null){
      return false;
    }
    let isPerUser = this.isPerUser();
    if(this.users && this.session && this.session.user.customerSubscriptions){
      if(this.users.length > 0){
        let numberOfUserRemaint = this.subscription.NumberOfUserRemaint;
        if(!numberOfUserRemaint){
            isPerUser = false;
        }else if(numberOfUserRemaint == 0){
          isPerUser = false;
        }
      }
    }
    return isPerUser;
  }


  constructor(
    private svcAccount: AccountService,
    private svcStorage: StorageService,
    private svcLoading: LoadingService,
    private svcModal: BsModalService
  ) { }

  isPerUser() {
    return this.session?.user?.subscription?.SubType == 2;
  }

  ngOnInit(): void {
    const session = this.svcStorage.getCurrentSession();
    this.session = session;
    this.svcLoading.show();
    if(session){
      this.svcAccount.getCustomerByUser({Token: session.token})
        .then(result => {
          if(result.IsSucess){
            this.customer = result.Data;

            this.loadUserList(session);
          }
      }).catch((error) => {
        this.svcLoading.hide();
        alert(JSON.stringify(error.error));
      });

      this.svcAccount.getCurrentSubscription({Token: session.token})
      .then(result => {
        if(result.IsSucess){
          this.subscription = result.Data;
        }
    }).catch((error) => {
      alert(JSON.stringify(error.error));
    });
    }
  }
  private loadUserList(session: Session, showLoader: boolean = false) {
    if(showLoader){
      this.svcLoading.show();
    }
    this.svcAccount.getUsersByCustomer({ Token: session.token })
      .then(result2 => {
        this.svcLoading.hide();
        if (result2.IsSucess) {
          this.users = result2.ListData;
          console.log(this.users);
        }
      }).catch((error) => {
        this.svcLoading.hide();
        alert(JSON.stringify(error.error));
      });
  }

  openModalForm(item: CreateUser=null){
    const initialState = {
      item: item
    };
    this.bsModalRef = this.svcModal.show(ProfileInformationUserFormComponent, {
      initialState: initialState,
      class: 'modal-dialog-centered modal-lg'
    });
    const session = this.svcStorage.getCurrentSession();
    this.bsModalRef.content.event.subscribe((res:any)=>{
      if(res.data.save){
        this.messageSuccess = res.msg;
        this.loadUserList(session, true);
      }else{
        this.messageError = res.msg;
      }
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  openChangePasswordForm(item: CreateUser){
    const initialState = {
      item: {
        UserId: item.Id
      }
    };
    this.bsModalRef = this.svcModal.show(ChangePasswordComponent, {
      initialState: initialState,
      class: 'modal-dialog-centered modal-lg'
    });
    const session = this.svcStorage.getCurrentSession();
    this.bsModalRef.onHide.subscribe(()=>{
      this.loadUserList(session, true);
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  getTextNumber(number: number){
    const numbers = [
      'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
      'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen', 'twenty'
    ];
    return numbers[number];
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
}
