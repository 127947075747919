import { HttpClient } from '@angular/common/http';
import {
  ApiResult,
  ApiResultData,
  ApiResultListData,
} from '../../models/base/ApiResult';
export class DataService {
  protected url: string;
  protected headers = {
    'Accept-Language': 'en'
  }
  constructor(public http: HttpClient, url: string) {
    this.url = url;
  }

  getPostApiResult(data?: any) {
    return new Promise<ApiResult>((resolve, reject) => {
      this.http.post<ApiResult>(this.url, data, {headers: this.headers}).subscribe(
        (response: any) => resolve(response),
        (error) => reject(error)
      );
    });
  }
  getPostApiResultData(data?: any) {
    return new Promise<ApiResultData>((resolve, reject) => {
      this.http.post<ApiResultData>(this.url, data, {headers: this.headers}).subscribe(
        (response: any) => resolve(response),
        (error) => reject(error)
      );
    });
  }
  getPostApiResultListData(data?: any) {
    return new Promise<ApiResultListData>((resolve, reject) => {
      const options = {
        // headers: new HttpHeaders({
        //   'Access-Control-Allow-Headers': 'Content-Type',
        //   'Access-Control-Allow-Origin': 'http://srv8.pisobredos.com:1580',
        //   'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
        //   'Content-Type': 'application/x-www-form-urlencoded',
        // }),
        // responseType: 'json',
        params: data,
      };

      this.http.post<ApiResultListData>(this.url, data, {headers: this.headers}).subscribe(
        (response: any) => resolve(response),
        (error) => reject(error)
      );
    });
  }
}
