import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../core/services/apis/account.service';
import { StorageService } from '../../core/services/base/storage.service';
import { LoadingService } from '../../core/services/shared/loading.service';
import { MySubscription } from '../../core/models/youSubscription';
import { Router, ActivatedRoute } from '@angular/router';
import { PaypalCaptureRequestDTO } from 'src/app/core/models/customerSubscriptionDTO';
import { PriceService } from '../../core/services/apis/price.service';
import { Session } from 'src/app/core/models/base/session';

@Component({
  selector: 'app-your-subscription',
  templateUrl: './your-subscription.component.html',
  styleUrls: ['./your-subscription.component.css']
})
export class YourSubscriptionComponent implements OnInit {
  public subscription: MySubscription = null;
  public currentDate: Date = new Date();
  public messageSuccess: string;
  constructor(
    private svcAccount: AccountService,
    private svcStorage: StorageService,
    private svcLoading: LoadingService,
    private svcPrice: PriceService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    debugger;
    this.route.queryParams.subscribe(params => {
      const session = this.svcStorage.getCurrentSession();
      if(session && session.token && params.token){
        let data: PaypalCaptureRequestDTO = {
          OrderId: params.token,
          Token: session.token
        }
        this.svcLoading.show();
        this.svcPrice.paypalCaptureOrder(data)
        .then(result => {
          if(result.IsSucess){
            if(result.DataResult){
              session.user.subscription = result.DataResult;
              this.svcStorage.setCurrentSession(session);
              this.getSubscription(session, true);
            }
          }else{
            this.router.navigate(['account/your-subscription']);
          }
        }).catch(err => {
          alert(JSON.stringify(err));
        })
      }else if(session && params.success){
        this.messageSuccess = "Successful subscription!";
        const session = this.svcStorage.getCurrentSession();
        if(session){
          this.svcLoading.show();
          this.getSubscription(session);
        }
      }else{
        const session = this.svcStorage.getCurrentSession();
        if(session){
          this.svcLoading.show();
          this.getSubscription(session);
        }
      }
    });
  }
  private getSubscription(session: Session, showMessage: boolean=false) {
    this.svcAccount.getCurrentSubscription({ Token: session.token })
      .then(result => {
        this.svcLoading.hide();
        if (result.IsSucess) {
          this.subscription = result.Data;
          session.user.subscription = result.DataResult;
          this.svcStorage.setCurrentSession(session);
          if(showMessage){
            this.messageSuccess = "Successful subscription!"
          }
        }else{
          if(result.MessageError == "Client don't have associated subscription."){
            this.redirectToPrice();
            return;
          }
          alert(result.MessageError);
        }
      })
      .catch((error) => {
        this.svcLoading.hide();
        alert(JSON.stringify(error.error));
      });
  }

  redirectToPrice(){
    this.router.navigate(['price']);
  }
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
}
