import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from '../base/data.service';
import { Config } from '../base/endpoints.config';
import { OrderBP } from '../../models/yourQuotes';
import { ChangePasswordBP, UserInformationBP as UserInformationBP } from '../../models/loginSecurityDTO';
import { IToken } from '../../models/base/IToken';
import { registerUser } from '../../models/registerUserDTO';
import { CreateUser } from '../../models/yourProfile';
import { User } from '../../models/userDTO';
import { LoginDTO } from '../../models/loginDTO';
@Injectable()
export class AccountService extends DataService {
  constructor(public http: HttpClient, private configuration: Config) {
    super(http, configuration.URL);
  }

  getOrderByCustomer(bodyParameter: OrderBP) {
    this.url = this.configuration.URL + this.configuration.ORDERS_BY_CUSTOMER;
    return this.getPostApiResultListData(bodyParameter);
  }
  getOrderByCustomerAndId(bodyParameter: OrderBP){
    this.url = this.configuration.URL + this.configuration.ORDERS_BY_CUSTOMER_COMPLETE;
    return this.getPostApiResultData(bodyParameter);
  }

  updateUserInformation(bodyParameter: UserInformationBP){
    this.url = this.configuration.URL + this.configuration.UPDATE_USER_INFORMATION;
    return this.getPostApiResultData(bodyParameter);
  }
  changePassword(bodyParameter: ChangePasswordBP){
    this.url = this.configuration.URL + this.configuration.CHANGE_PASSWORD;
    return this.getPostApiResultData(bodyParameter);
  }
  forgotPassword(bodyParameter: LoginDTO){
    this.url = this.configuration.URL + this.configuration.FORGOT_PASSWORD;
    return this.getPostApiResultData(bodyParameter);
  }
  activeUser(bodyParameter: IToken){
    this.url = this.configuration.URL + this.configuration.ACTIVE_USER;
    return this.getPostApiResult(bodyParameter);
  }
  getUsersByCustomer(bodyParameter: IToken){
    this.url = this.configuration.URL + this.configuration.GET_USERS_BY_CUSTOMER;
    return this.getPostApiResultListData(bodyParameter);
  }
  getCustomerByUser(bodyParameter: IToken){
    this.url = this.configuration.URL + this.configuration.GET_CUSTOMER_BY_USER;
    return this.getPostApiResultData(bodyParameter);
  }
  saveUser(bodyParameter: CreateUser){
    this.url = this.configuration.URL + this.configuration.SAVE_USER;
    return this.getPostApiResultData(bodyParameter);
  }

  getCurrentSubscription(bodyParameter: IToken){
    this.url = this.configuration.URL + this.configuration.GET_CURRENT_SUBSCRIPTION;
    return this.getPostApiResultData(bodyParameter);
  }
  getPayments(bodyParameter: IToken){
    this.url = this.configuration.URL + this.configuration.GET_PAYMENTS;
    return this.getPostApiResultListData(bodyParameter);
  }
}
