<!-- Terms of use -->
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-12 text-center fadeInUp">
        <div>
          <div class="sec-title text-center">Terms Of Use</div>
          <div class="sec-line m-auto"></div>
        </div>
        <p>
          This web site is operated by Pascal IP LLC (Pascal). Please read these
          Terms of Use carefully before using this web site. By using the web
          site, you agree to be bound by this Terms of Service.
        </p>
        <p class="mb-4">
          These Terms of Service PASCAL IP ("Terms of Service") are effective
          immediately upon the User's acceptance of the Terms of Service by
          checking the "I have read and agree the terms" checkbox, and User's
          first use of the Global IP Estimator Online Service ("Service") as
          defined herein. As used in the Terms of Service, the terms "you",
          "your" or "User" all refer to the person using the Service in any way
        </p>
      </div>

      <div class="col-md-12 fadeInUp mt-4">
        <h5 class="text-black bold">Nature of information and materials</h5>
        <p class="mb-4">
          Although this web site may provide information concerning intellectual
          property issues, such information is not intended to constitute a
          legal advise or be a substitute for legal advice from qualified
          counsel. You agree that Inventia is not engaged in rendering legal
          services or advice by providing the information and materials on this
          web site. Inventia assumes no responsibility for any consequence
          relating directly or indirectly to any action or inaction you take
          based on the information or material on this web site. While Inventia
          takes reasonable measures to keep the information on this web site
          accurate, complete and up-to-date, Inventia will not be responsible
          for any damage or loss related to the inaccuracy, incompleteness or
          lack of timeliness of the information.
        </p>

        <h5 class="text-black bold">Restrictions on use of materials</h5>
        <p class="">
          All materials contained on this web site, and all intellectual
          property rights thereto, including copyrights, trademarks, service
          marks, trade names and look and feel, are owned by Pascal or its
          affiliates. You agree not to copy, reproduce, republish, transmit,
          modify or distribute any of the materials contained on the web site,
          except for your personal, noncommercial use, absent the prior written
          approval of Pascal.
        </p>
        <p class="mb-4">
          Subject to the terms and conditions of the Terms of Service, Pascal IP
          will provide a service that allows you to estimate costs for filing,
          prosecuting and maintaining Patent, Trademark, Design and Utility
          Model applications on the World Wide Web (the Web) via Pascal IP (the
          "Service"). You cannot use the Service until you have read and
          accepted all of the Terms of Service by checking the "I accept"
          checkbox and clicking the "Next" button. To use the Service, you are
          responsible at your own expense to access the World Wide Web. You must
          also provide the necessary equipment to make such connection to the
          Web, including a computer and modem or other access device. You shall
          not attempt to access any other of Pascal IP's systems, programs or
          data that are not made available for public use.
        </p>

        <h5 class="text-black bold">Registration Duties</h5>
        <p class="mb-4">
          By accepting the terms of service, you agree to: - provide complete
          and true information about yourself and to keep it current. If you
          provide any information that is false, inaccurate, or incomplete
          Pascal IP has reasonable grounds to suspect that such information is
          false, inaccurate, or incomplete, and your account may be suspended,
          terminated, and refuse any current or future use of the Service.
        </p>

        <h5 class="text-black bold">Disclaimer</h5>
        <p class="mb-4">
          The materials provided on this site are provided 'as is' and without
          warranty of any kind, express or implied. Pascal disclaims all express
          and implied warranties with regard to the information and materials
          contained on this site, including without limitation implied
          warranties of merchantability, fitness for a particular purpose and
          non-infringement.
        </p>

        <h5 class="text-black bold">Limitation of liability</h5>
        <p>
          In no event shall Pascal, or its partners or affiliates, or
          contributors to this web site, be liable for any loss or injury, or
          any damages, whether direct, special, indirect, punitive, incidental,
          exemplary, consequential, or otherwise, whether based on breach of
          contract, tort (including negligence), product liability or otherwise,
          resulting from your access or use of this web site. You hereby waive
          any and all such claims against Pascal, its partners, affiliates, and
          contributors. You agree that the limitations set forth above are
          fundamental elements of this agreement, and that the site and its
          materials would not be provided to you absent such limitations. Some
          state statutes may restrict this limitation of liability.
        </p>
        <p>
          Pascal and its partners or affiliates, or contributors shall not be
          liable for any damagesresulting from: 1) the use or the inability to
          use the service; 2) the cost of obtaining substitute services
          resulting from any service obtained through or from the service; 3)
          unauthorized access to or alteration of your transmissions or data; 4)
          any other matter relating to the service.
        </p>
        <p>
          Pascal will not be liable for any interruption of business, access
          delays or interruptions to this website that you access through this
          service; non-delivery, mis-delivery, corruption, destruction or other
          modification of data, and any eventbeyond Pascal's reasonable control.
        </p>
        <p class="mb-4">
          In no event shall Pascal maximum aggregate liability exceed the total
          amount paid by you to Pascal IPLLC for the service. In those states in
          which the exclusion or limitation of liability is not allowed for
          consequential or incidental damages, our liability is limited to the
          extent permitted by law.
        </p>
        <h5 class="text-black bold">Billing</h5>
        <p>
          You agree to pay the current fees associated with the Service, as
          appear on the PRICING page. Pascal IP reserves the right to modify its
          fees with or without notice.
        </p>
        <p class="mb-4">
          All sales are final, and the Company does not offer any money-back
          guarantees. You recognize and agree that you shall not be entitled to
          a refund for any purchase under any circumstances. THE COMPANY MAKES
          NO WARRANTIES REGARDING THE PERFORMANCE OR OPERATION OF THIS WEBSITE.
          THE COMPANY FURTHER MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY
          KIND, EXPRESS OR IMPLIED, AS TO THE INFORMATION, CONTENTS, MATERIALS,
          DOCUMENTS, PROGRAMS, PRODUCTS, OR SERVICES INCLUDED ON OR THROUGH THIS
          WEBSITE. TO THE FULLEST EXTENT PERMISSIBLE UNDER THE LAW, THE COMPANY
          DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING IMPLIED
          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
        </p>

        <h5 class="text-black bold">Indemnification</h5>
        <p class="mb-4">
          You agree that you will not use this web site for any unlawful
          purpose, or for any purpose prohibited by these Terms of Use. You
          agree to indemnify, defend and hold harmless Pascal, its partners,
          affiliates and contributors from any liability, loss, claim and
          expense (including attorneys' reasonable fees) related to (i) your
          violation of these Terms of Use, and/or (ii) your posting or use of
          materials on this web site.
        </p>

        <h5 class="text-black bold">Governing law and forum</h5>
        <p class="mb-4">
          These Terms of Use shall be governed by and construed in accordance
          with the laws of the State of Florida, United States, without giving
          effect to any principles of conflicts of law. Any dispute between you
          and Pascal regarding the content or use of the web site shall be
          resolved by arbitration under the Commercial Rules of the American
          Arbitration Association, with hearings held in the English language in
          the State of Florida, the United States, and shall not be joined with
          any dispute that any other person or entity may have with Pascal.
        </p>

        <h5 class="text-black bold">No Third-Party Beneficiaries.</h5>
        <p class="mb-4">
          This Agreement is intended for the benefit of the parties hereto and
          their respective permitted successors and assigns, and is not for the
          benefit of, nor may any provision hereof be enforced by, any other
          person.
        </p>

        <h5 class="text-black bold">Termination</h5>
        <p>
          Either party may terminate this Agreement prior to the beginning of
          the Renewal Term by providing 15 (fifteen) days written notice to the
          other party prior to the end of the then-current term.Notwithstanding
          the foregoing and any other provision contained herein, the following
          Sections of this Agreement shall remain in effect and shall survive
          the termination of this Agreement: Billing; Limitation on Liability;
          Indemnification; Nature of information and materials; Governing law
          and forumand No Third-Party Beneficiaries.
        </p>
        <p class="mb-4">
          You acknowledge and agree that Pascal IP may suspend or terminate your
          account and/or deny you access to, or use of, all or part of the
          Service, without prior notice, if you engage in any conduct that
          Pascal believes, in its sole discretion: violates any term or
          provision of the Terms of Service, violates the rights of Pascal IP or
          third parties, or is otherwise inappropriate for continued access and
          use of the Service. In addition, Pascal IP reserves the right to
          terminate inactive membership accounts. You agree that upon
          termination, we may delete all files and information related to your
          account and may bar your access to your account and the Service.
        </p>

        <h5 class="text-black bold">Non-waiver</h5>
        <p class="mb-4">
          Pascal's failure to insist upon strict enforcement of any provision of
          this agreement shall not be construed as a waiver of any provision or
          right.
        </p>

        <h5 class="text-black bold">Updates</h5>
        <p class="mb-4">
          Pascal may modify or update these Terms of Use from time to time at
          any time without prior notice. You can check the 'Last updated' date
          above to see when the policy was last changed. Your continued use of
          the web site constitutes your consent to the contents of these Terms
          of Use, as it may be modified from time to time.
        </p>

        <h5 class="text-black bold">Warranties</h5>
        <p>
          THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE”. PASCAL EXPRESSLY
          DISCLAIMS ALL IMPLIED AND EXPRESS WARRANTIES IN THE SERVICES. THIS
          DISCLAIMER INCLUDES ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT AND IS EFFECTIVE TO THE
          MAXIMUM EXTENT ALLOWED BY LAW. PASCAL DOES NOT GUARANTEE THAT 1) THE
          SERVICES WILL MEET SUBSCRIBER’S REQUIREMENTS OR EXPECTATIONS OR 2)
          THAT ACCESS TO THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
          ERROR-FREE.
        </p>
        <p>
          ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
          SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY
          RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA
          THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
        </p>
        <p class="mb-0">
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM PASCAL IP OR THROUGH OR FROM THE SERVICE SHALL CREATE ANY
          WARRANTY NOT EXPRESSLY STATED IN THE TERMS OF SERVICE.
        </p>
      </div>
    </div>
  </div>
</div>
