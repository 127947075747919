import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import 'lodash';
import {
  ApiResultData,
  ApiResultListData,
} from 'src/app/core/models/base/ApiResult';
import { Session } from 'src/app/core/models/base/session';
import {
  continents,
  CurrencyDTO,
  LanguageDTO,
  Jurisdiction,
  JurisdictionDTO,
  MatterDTO,
  MatterGroupDTO,
  MatterTariffDTO,
  OrderParameter,
  OrderParameterReduce,
  ParamUserDTO,
  TariffGroupIds,
  TierOptionDTO,
} from 'src/app/core/models/stepFormDTO';
import { StepFormService } from 'src/app/core/services/apis/stepForm.service';
import { StorageService } from 'src/app/core/services/base/storage.service';
import { HelperService } from '../../core/services/shared/helper.service';
import { LoadingService } from '../../core/services/shared/loading.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LawFirmListComponent } from '../quote-detail/modal/law-firm-list/law-firm-list.component';
import { LoginService } from '../../core/services/auth/login.service';
import { ViewportScroller } from '@angular/common';
import { ExtraParameterUser } from 'src/app/core/models/extra-parameter-user';
import { SendEmailComponent } from '../your-quotes/modal/send-email/send-email.component';
import { TypeOfApplicantEnum } from '../../core/enums/type-of-applicant-enum';
declare var _: any;
declare var $: any;

@Component({
  selector: 'app-step-form',
  templateUrl: './step-form.component.html',
  styleUrls: ['./step-form.component.css'],
})
export class StepFormComponent implements OnInit {
  listMatterGroup: MatterGroupDTO[] = [];
  listMatter: MatterDTO[] = [];
  listJurisdiction: JurisdictionDTO[];
  listCurrency: CurrencyDTO[];
  listLanguage: LanguageDTO[];
  listInputs: ParamUserDTO[];
  listTariffGroupByMatter: MatterTariffDTO[];
  listTierOptions: TierOptionDTO[];
  listOrders: ApiResultData;
  listEurope: JurisdictionDTO[];
  listAsia: JurisdictionDTO[];
  listAmerica: JurisdictionDTO[];
  listAfrica: JurisdictionDTO[];
  listAustralia: JurisdictionDTO[];
  listRegionalAgreements: JurisdictionDTO[] = [];
  inputPatentNumber: boolean;
  matterId: number;
  showFavorite: boolean = false;
  showByName: boolean = false;
  showByContinents: boolean = false;
  paramUsercomtrols: any;
  formsArr = [];
  paramUserForm: FormGroup;
  matterIdSelf: boolean = false;
  submMatterId: number;
  calculateOldParameter: OrderParameter;
  orderId: string = null;
  PCTLanguage: string;
  PCTLanguageId: number;
  get f() {
    return this.paramUserForm.controls;
  }
  listEstimate: any;
  listOtherParams: ExtraParameterUser[] = [];
  cancelNext: string;
  pctTitle: string;
  tierTitle: string;
  clientName: string;
  reference: string;
  session: Session;
  matterName: string;
  isEmailSend: boolean = false;
  emailError : any = null;
  public lawFirmList: any[] = null;
  public tierOption: any;
  constructor(
    private srvStepForm: StepFormService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    public helperService: HelperService,
    private svcLoading: LoadingService,
    private svcStorage: StorageService,
    public router: Router,
    private svcModal: BsModalService,
    private loginService: LoginService,
    private scroller: ViewportScroller
  ) {
    this.paramUserForm = this.formBuilder.group({});
  }
  ngOnInit(): void {
    this.svcLoading.show();
    this.loginService.isValidToken().then(result => {
      if(result.IsSucess){
        this.init();
      }else{
        this.loginService.logout();
        this.router.navigate(['/login']);
      }
    }).finally(()=>this.svcLoading.hide());
  }

  private init() {
    this.session = this.storageService.getCurrentSession();
    // form step
    this.srvStepForm.OrderRequest.PatentPCT = null;
    var that = this;
    $(document).ready(function () {
      var currentGfgStep, nextGfgStep, previousGfgStep;
      var opacity;
      var current = 1;
      var steps = $('fieldset').length;

      $('#step_1_2').css({
        display: 'none',
      });

      setProgressBar(current);

      $('.next-step').click(function () {
        debugger;
        let cancelNext = $('#cancelNext')[0].dataset.cancelNext;
        if (cancelNext == 'true') {
          return;
        }
        let isFirstStep = $(this).attr('id') == "next-step1";
        if (isFirstStep) {
          currentGfgStep = $(this).parent().parent();
          nextGfgStep = $(this).parent().parent().next();
        } else {
          currentGfgStep = $(this).parent();
          nextGfgStep = $(this).parent().next();

        }

        $('#progressbar li')
          .eq($('fieldset').index(nextGfgStep))
          .addClass('active');
        $('#progressbar li')
          .eq($('fieldset').index(currentGfgStep))
          .addClass('done');
        nextGfgStep.show();
        currentGfgStep.animate(
          { opacity: 0 },
          {
            step: function (now) {
              opacity = 1 - now;

              currentGfgStep.css({
                display: 'none',
                position: 'relative',
              });
              nextGfgStep.css({ opacity: opacity });
            },
            duration: 500,
          }
        );
        setProgressBar(++current);
        if (that.srvStepForm.OrderRequest.PatentPCT && current == 4) {
          $('#next-step-4').click();
        }else if($(this).attr('id') == "revise-parameter-button"){
          $('#finish').click();
        }
        that.scroller.scrollToPosition([0,0]);
      });

      $('.previous-step').click(function () {
        currentGfgStep = $(this).parent();
        previousGfgStep = $(this).parent().prev();

        $('#progressbar li')
          .eq($('fieldset').index(currentGfgStep))
          .removeClass('active');
        $('#progressbar li')
          .eq($('fieldset').index(previousGfgStep))
          .removeClass('done');
        previousGfgStep.show();

        currentGfgStep.animate(
          { opacity: 0 },
          {
            step: function (now) {
              opacity = 1 - now;

              currentGfgStep.css({
                display: 'none',
                position: 'relative',
              });
              previousGfgStep.css({ opacity: opacity });
            },
            duration: 500,
          }
        );
        setProgressBar(--current);
        if (that.srvStepForm.OrderRequest.PatentPCT && current == 4) {
          $('#previous-step-4').click();
        }
      });

      function setProgressBar(currentStep) {
        var percent = (100 / steps) * current;
        percent = Number(percent.toFixed());
        $('.progress-bar').css('width', percent + '%');
      }

      $('.submit').click(function () {
        return false;
      });
    });
    //debugger;
    this.svcLoading.show();
    this.srvStepForm
      .getMatterGroups()
      .then((result: ApiResultListData) => {
        this.svcLoading.hide();
        if (result.IsSucess) {
          this.listMatterGroup = result.ListData;
        } else {
          this.helperService.showErrorMessage(
            result.MessageError,
            this.svcLoading
          );
        }
      })
      .catch((error) => {
        this.helperService.showErrorMessage(
          JSON.stringify(error.error),
          this.svcLoading
        );
      });

    this.srvStepForm.OrderRequest.Token =
      this.svcStorage.getCurrentSession().token;
  }

  //step 1
  //onChanges

  onChangeMatterGroup(id: number): void {
    debugger;
    this.inputPatentNumber = false;
    this.svcLoading.show();
    this.srvStepForm
      .getMattersFromMatterGroup({ MatterGroup: id })
      .then((result: ApiResultListData) => {
        this.svcLoading.hide();
        if (result.IsSucess) {
          this.listMatter = result.ListData;
          debugger;
          if (this.listMatter.length > 1) {
            this.setDisplay('step_1_2', true);
            // this.setDisplay('step_1_3', false);
          } else {
            //guardar el MatterId en el servicio
            this.srvStepForm.OrderRequest.MatterId = id;
            this.submMatterId = result.ListData[0].Id;
            this.setMatterName(this.submMatterId);
            this.setDisplay('step_1_2', false);

            if (this.listMatter[0].InputPatentNumber == true) {
              this.setDisplay('step_1_3', true);
            } else {
              this.setDisplay('step_1_3', false);
              $('#cancelNext')[0].dataset.cancelNext = 'false';
              //$('#next-step1').click();
            }
          }
        } else {
          alert(result.MessageError);
        }
      })
      .catch((error) => {
        this.helperService.showErrorMessage(
          JSON.stringify(error.error),
          this.svcLoading
        );
      });
  }
  onChangeMatter(id: number, inputPatentNumber: boolean) {
    debugger;
    this.inputPatentNumber = inputPatentNumber;
    if(!this.inputPatentNumber){
      this.srvStepForm.OrderRequest.PatentPCT = "";
    }
    //guardar el MatterId en el servicio
    this.srvStepForm.OrderRequest.MatterId = id;
    this.submMatterId = id;
    if (this.inputPatentNumber) {
      this.setDisplay('step_1_3', true);
    } else {
      this.setDisplay('step_1_3', false);
      $('#cancelNext')[0].dataset.cancelNext = 'false';
      //$('#next-step1').click();
    }
    this.setMatterName(id);
  }

  private setMatterName(id: number) {
    const matter = this.listMatter.find(x => x.Id == id);
    if (matter) {
      this.matterName = matter.Name;
    }
  }

  pctValidate(id, error): boolean {
    const input = <HTMLInputElement>document.getElementById(id);
    const label = <HTMLInputElement>document.getElementById("pctLabelNextButton");
    const inputError = <HTMLInputElement>document.getElementById(error);
    if (input.value == '' && this.inputPatentNumber) {
      inputError.className = 'd-block';
      label.classList.remove('d-none');
      return false;
    }
    if (!input.checkValidity()) {
      inputError.className = 'd-block';
      label.classList.remove('d-none');
      return false;
    } else {
      inputError.className = 'd-none';
      label.classList.add('d-none');
      return true;
    }
  }

  setDisplay(id: string, display: boolean): void {
    $('#' + id).css({
      display: display ? 'block' : 'none',
    });
  }
  async clickNextStep1(data) {
    let matterGroup: NodeListOf<HTMLInputElement> =
      document.body.querySelectorAll('*[name="matterGroup"]:checked');
    if (matterGroup.length == 0) {
      this.showRequired('matterGroupError', 'd-block');
      $('#cancelNext')[0].dataset.cancelNext = 'true';
      return;
    } else {
      this.showRequired('matterGroupError', 'd-none');
    }
    const input = <HTMLInputElement>document.getElementById('step_1_2');
    if (input.style.display != 'none') {
      let matters: NodeListOf<HTMLInputElement> =
        document.body.querySelectorAll('*[name="matters"]:checked');
      if (matters.length == 0) {
        this.showRequired('matterError', 'd-block');
        $('#cancelNext')[0].dataset.cancelNext = 'true';
        return;
      } else {
        this.showRequired('matterError', 'd-none');
      }
    }
    let isInvalidPCT = !this.pctValidate('pct', 'pctError') ||
    !this.pctValidate('pctAdditional', 'pctError');
    if (
      isInvalidPCT
    ) {
      $('#cancelNext')[0].dataset.cancelNext = 'false';
    } else {
      $('#cancelNext')[0].dataset.cancelNext = 'false';
    }

    $('#checkAll').click(function () {
      $('#jurisdictionsChecks input:checkbox').prop('checked', this.checked);
    });
    if(this.srvStepForm.OrderRequest.PatentPCT && !this.inputPatentNumber){
      let patentPct =
      'PCT/' + $('#pct').val() + '/' + $('#pctAdditional').val();
      if(patentPct != "PCT//0"){
        this.inputPatentNumber = this.srvStepForm.OrderRequest.PatentPCT != patentPct;
      }
    }
    if (this.inputPatentNumber && !isInvalidPCT) {
      let patentPct =
        'PCT/' + $('#pct').val() + '/' + $('#pctAdditional').val();
      this.srvStepForm.OrderRequest.PatentPCT = patentPct;
      this.pctTitle = patentPct;

      this.svcLoading.show();
      await this.srvStepForm
        .getDataPatentPCT({
          //PatentPct: 'PCT/PL2014/000130', //dato de prueba
          PatentPct: patentPct,
        })
        .then((result: ApiResultData) => {
          this.svcLoading.hide();
          if (result.IsSucess) {
            debugger;
            this.srvStepForm.OrderRequest.OrderParameters = result.Data.OrderParameters;
            this.PCTLanguage = result.Data.PCTLanguage;
            this.listEstimate = this.srvStepForm.OrderRequest.OrderParameters;
          } else {
            alert(result.MessageError);
          }
        })
        .catch((error) => {
          this.helperService.showErrorMessage(
            JSON.stringify(error.error),
            this.svcLoading
          );
        });
    } else {
      this.srvStepForm.OrderRequest.OrderParameters = [];
      this.listEstimate = [];
    }
    this.clientName = $('#clientName').val();
    this.reference = $('#reference').val();

    if (this.srvStepForm.OrderRequest.MatterId == this.submMatterId) {
      this.matterIdSelf = true;
    } else {
      this.srvStepForm.OrderRequest.MatterId = this.submMatterId;
      let input = <HTMLInputElement>document.getElementById('checkAll');
      input.checked = false;
      this.matterIdSelf = false;
    }
    if (true) {
      this.svcLoading.show();
      this.srvStepForm
        .getJurisdictionByMatterFromOrder({
          MatterId: this.srvStepForm.OrderRequest.MatterId,
          Token: this.srvStepForm.OrderRequest.Token,
        }) //1,1
        .then((result: ApiResultListData) => {
          this.svcLoading.hide();
          if (result.IsSucess) {
            this.listJurisdiction = _.orderBy(
              result.ListData,
              ['NameEn'],
              ['asc']
            );
            this.listRegionalAgreements = _.filter(
              this.listJurisdiction,
              function (item) {
                return item.IsRegion;
              }
            );
            for (let item = 0; item < continents.length; item++) {
              switch (item + 1) {
                case 1:
                  this.listEurope = _.filter(
                    this.listJurisdiction,
                    function (item) {
                      return item.Continent === 1 && !item.IsRegion;
                    }
                  );
                  break;
                case 2:
                  this.listAsia = _.filter(
                    this.listJurisdiction,
                    function (item) {
                      return item.Continent === 2 && !item.IsRegion;
                    }
                  );
                  break;
                case 3:
                  this.listAmerica = _.filter(
                    this.listJurisdiction,
                    function (item) {
                      return item.Continent === 3 && !item.IsRegion;
                    }
                  );
                  break;
                case 4:
                  this.listAfrica = _.filter(
                    this.listJurisdiction,
                    function (item) {
                      return item.Continent === 4 && !item.IsRegion;
                    }
                  );
                  break;
                case 5:
                  this.listAustralia = _.filter(
                    this.listJurisdiction,
                    function (item) {
                      return item.Continent === 5 && !item.IsRegion;
                    }
                  );
                  break;
                default:
                  break;
              }
            }
            this.onChangeByNameOption(1);
          } else {
            alert(result.MessageError);
          }
        })
        .catch((error) => {
          this.helperService.showErrorMessage(
            JSON.stringify(error.error),
            this.svcLoading
          );
        });
    }
  }

  isDisabledMatterGroup(matterGroup: MatterGroupDTO){
    let array = this.session.user.subscription.SubscriptionMatter;
    let matterGroupIdList = [...new Set(array.map(x => x.Matter.MatterGroupId))];
    return !matterGroupIdList.includes(matterGroup.Id);
  }
  isDisabledMatter(matter: MatterDTO){
    let array = this.session.user.subscription.SubscriptionMatter;
    let matterIdList = [...new Set(array.map(x => x.Matter.Id))];
    return !matterIdList.includes(matter.Id);
  }

  onChangeTypeOfApplicant(value: any){
    this.srvStepForm.OrderRequest.TypeOfApplicant = value;
  }

  //step 2
  //onChanges
  onChangeByNameOption(id: number): void {
    this.showByName = true;
    this.showByContinents = false;
    this.showFavorite = false;
  }
  onChangeByContinentOption(id: number): void {
    this.showByContinents = true;
    this.showByName = false;
    this.showFavorite = false;
    $('#checkAll').prop('checked', false);
  }
  onChangeByFavouriteOption(id: number): void {
    this.showFavorite = true;
    this.showByContinents = false;
    this.showByName = false;
  }

  setJurisdictions() {
    var values: Jurisdiction[] = [];
    //debugger;
    let inputsCollection: NodeListOf<HTMLInputElement> =
      document.body.querySelectorAll(
        '#jurisdictionsChecks input[type=checkbox]:checked'
      );
    for (var i = 0; i < inputsCollection.length; i++) {
      var inp = inputsCollection[i];
      let isFavorite = null;
      if(this.showFavorite){
        isFavorite = true;
      }
      values.push({ JurisdictionId: Number(inp.value), IsFavorite: isFavorite });
    }
    this.srvStepForm.OrderRequest.OrderJurisdictions = values;
    this.srvStepForm.OrderRequest.ChangeJurisdictionFavorite = this.showFavorite;
  }

  clickNextStep2(data) {
    let mode: NodeListOf<HTMLInputElement> = document.body.querySelectorAll(
      '*[name="mode"]:checked'
    );
    if (mode.length == 0) {
      this.showRequired('modeError', 'd-block');
      $('#cancelNext')[0].dataset.cancelNext = 'true';
      return;
    } else {
      this.showRequired('modeError', 'd-none');
      $('#cancelNext')[0].dataset.cancelNext = 'false';
    }

    let jurisdictions: NodeListOf<HTMLInputElement> =
      document.body.querySelectorAll(
        '#jurisdictionsChecks input[type=checkbox]:checked'
      );
    if (jurisdictions.length == 0) {
      this.showRequired('jurisdictionsError', 'd-block');
      $('#cancelNext')[0].dataset.cancelNext = 'true';
      return;
    } else {
      this.showRequired('jurisdictionsError', 'd-none');
      $('#cancelNext')[0].dataset.cancelNext = 'false';
    }
    // var jurisdictionChecks: Jurisdiction[] = [];
    // // var div = $('#Chile');
    // // console.log(div.data("isfavority"));
    // $('#jurisdictionsChecks input[type=checkbox]:checked').each(function () {
    //   jurisdictionChecks.push({
    //     JurisdictionId: this.dataset.id,
    //     // NameEn: this.dataset.nameen,
    //     // Continent: this.dataset.continent,
    //     // IsFavority: this.dataset.isfavority,
    //   });
    // });
    // this.srvStepForm.OrderRequest.OrderJurisdictions = jurisdictionChecks;
    //debugger;
    this.setJurisdictions();

    if (!this.listCurrency) {
      this.svcLoading.show();
      this.srvStepForm
        .getCurrency()
        .then((result: ApiResultListData) => {
          this.svcLoading.hide();
          if (result.IsSucess) {
            this.listCurrency = result.ListData;
            this.srvStepForm.OrderRequest.CurrencyId = this.listCurrency[0].Id;
          } else {
            alert(result.MessageError);
          }
        })
        .catch((error) => {
          this.helperService.showErrorMessage(
            JSON.stringify(error.error),
            this.svcLoading
          );
        });
    }

    if (!this.listLanguage) {
      this.svcLoading.show();
      this.srvStepForm
        .getLanguage()
        .then((result: ApiResultListData) => {
          this.svcLoading.hide();
          if (result.IsSucess) {
            this.listLanguage = result.ListData;
            console.log(this.PCTLanguage);
            this.srvStepForm.OrderRequest.LanguageId = this.PCTLanguage === undefined ? this.listLanguage[0].Id : this.listLanguage.find(x => x.Name == this.PCTLanguage).Id;
            this.PCTLanguageId = this.PCTLanguage === undefined ? this.listLanguage[0].Id : this.listLanguage.find(x => x.Name == this.PCTLanguage).Id;
            console.log(this.PCTLanguageId);
            console.log(this.listLanguage);
          } else {
            alert(result.MessageError);
          }
        })
        .catch((error) => {
          this.helperService.showErrorMessage(
            JSON.stringify(error.error),
            this.svcLoading
          );
        });
    }
  }

  //step 3
  //onChanges
  onChangeSelectCurrency(id: number): void {
    //guardar el CurrencyId en el servicio
    this.srvStepForm.OrderRequest.CurrencyId = id;
  }
  onChangeSelectLanguage(id: number): void {
    //guardar el LanguageId en el servicio
    this.srvStepForm.OrderRequest.LanguageId = id;
  }

  paramUserformValidation() {
    // debugger
    let i = 0;
    this.listInputs.forEach((post) => {
      let control = new FormControl(this.paramUsercomtrols[i++], (this.getParameterUserValidators(post)));
      if(post.Value){
        if(post.Code == "OFD"){
          let dateSplit = post.Value.split('/');
          let day = parseInt(dateSplit[0]);
          let month = parseInt(dateSplit[1]);
          let year = parseInt(dateSplit[2]);
          control.setValue(`${year}-${month < 10 ? "0"+dateSplit[1] : month}-${day < 10 ? "0"+dateSplit[2] : day}`);
        }else{
          control.setValue(post.Value);
        }
      }
      this.paramUserForm.addControl(
        String(post.Id),
        control
      );
    });
  }
  private getParameterUserValidators(parameter: ParamUserDTO){
    return parameter.SiteIsRequired ? [Validators.required] : [];
  }
  async clickNextStep3(data) {
    // alert(data);
    // alert(this.srvStepForm.OrderRequest.CurrencyId);
    this.svcLoading.show();
    await this.srvStepForm
      .getParameterUsersByMatter({ Id: this.srvStepForm.OrderRequest.MatterId }) //6
      .then((result: ApiResultListData) => {
        this.svcLoading.hide();
        if (result.IsSucess) {
          debugger;
          this.listInputs = result.ListData;
          //this.listInputs = this.listInputs.sort((x, y) => x.SiteOrder - y.SiteOrder);
          this.listInputs = this.listInputs.filter(x => !x.SiteIsCalculated);
          if(this.listEstimate && this.listEstimate.length > 0){
            this.listInputs.forEach(input => {
              debugger;
              let parameterValued = this.listEstimate.find(item => item.ParameterUserId == input.Id);
              if(parameterValued){
                input.Value = parameterValued.Value;
              }else{
                input.Value = "0";
              }
            });
          }
          this.paramUsercomtrols = new Array<Comment>();
          this.paramUserformValidation();
          debugger;
        } else {
          alert(result.MessageError);
        }
      })
      .catch((error) => {
        this.helperService.showErrorMessage(
          JSON.stringify(error.error),
          this.svcLoading
        );
      });
  }

  //step 4

  checkForError(item: any) {
    const inputForm = this.paramUserForm.get(item.Id);
    if (inputForm.errors) {
      return true;
    }
    return false;
  }

  async clickNextStep4(data) {
    debugger;
    if (!this.srvStepForm.OrderRequest.OrderParameters) {
      this.srvStepForm.OrderRequest.OrderParameters = [];
    }

    Object.keys(this.paramUserForm.controls).forEach((key) => {
      let input = <HTMLInputElement>document.getElementById(key);
      if (input) {
        const paramUserDTO: ParamUserDTO = {
          Id: Number(key),
          Code: input.dataset.code,
          Name: input.dataset.name,
          SiteLabel: input.dataset.sitelabel,
          SitePlaceHolder: input.dataset.siteplaceholder,
          SiteIsFormatDate: Boolean(input.dataset.siteisformatdate),
          SiteIsFormatNumber: Boolean(input.dataset.siteisformatnumber),
          SiteUseInFormule: Boolean(input.dataset.siteuseinformule),
          SiteUseRowComplete: Boolean(input.dataset.siteuserowcomplete),
          SiteOrder: Number(input.dataset.siteorder),
          SiteIsRequired: Boolean(input.dataset.siteisrequired),
        };

        if (this.srvStepForm.OrderRequest.OrderParameters) {
          let index: number = _.findIndex(
            this.srvStepForm.OrderRequest.OrderParameters,
            function (o: OrderParameter) {
              return o.ParameterUserId == Number(key);
            }
          );
          if (index > 0) {
            this.srvStepForm.OrderRequest.OrderParameters[index].Value =
              input.value;
          } else {
            this.srvStepForm.OrderRequest.OrderParameters.push({
              ParameterUserId: Number(key),
              ParameterUser: paramUserDTO,
              Value: input.value,
            });
          }
        }
      }
      this.paramUserForm.controls[key].markAsDirty();
    });

    this.listEstimate = this.srvStepForm.OrderRequest.OrderParameters;
    this.createOtherUserParams();
    //debugger;

    this.svcLoading.show();
    await this.srvStepForm
      .getTariffGroupByMatter({
        Id: this.srvStepForm.OrderRequest.MatterId,
        Token: this.srvStepForm.OrderRequest.Token,
      }) //1,1
      .then((result: ApiResultListData) => {
        this.svcLoading.hide();
        if (result.IsSucess) {
          this.listTariffGroupByMatter = result.ListData;
        } else {
          alert(result.MessageError);
        }
      });
    if (!this.listTierOptions) {
      this.svcLoading.show();
      await this.srvStepForm
        .getTiersOptions()
        .then((result: ApiResultListData) => {
          this.svcLoading.hide();
          if (result.IsSucess) {
            this.listTierOptions = result.ListData;
            this.listTierOptions.forEach(item => {
              if(item.Selected){
                this.srvStepForm.OrderRequest.TierOption = item.Id;
                this.tierTitle = 'TIER ' + item.Name;
              }
            })
          } else {
            alert(result.MessageError);
          }
        })
        .catch((error) => {
          this.helperService.showErrorMessage(
            JSON.stringify(error.error),
            this.svcLoading
          );
        });
    }
  }

  private createOtherUserParams() {
    this.listOtherParams = [{
      SiteLabel: "Client",
      Show: this.clientName.trim().length > 0,
      Value: this.clientName
    },
    {
      SiteLabel: "Reference",
      Show: this.reference.trim().length > 0,
      Value: this.reference
    },
    {
      SiteLabel: "Pct patent",
      Show: this.pctTitle && this.pctTitle.trim().length > 0,
      Value: this.pctTitle
    },
    {
      SiteLabel: "Currency",
      Show: true,
      Value: this.listCurrency.find(x => x.Id == this.srvStepForm.OrderRequest.CurrencyId).Name
    },
    {
      SiteLabel: "Language",
      Show: true,
      Value: this.listLanguage.find(x => x.Id == this.srvStepForm.OrderRequest.LanguageId).Name
    },
    {
      SiteLabel: "Type of applicant",
      Show: true,
      Value: this.getTypeOfApplicant(this.srvStepForm.OrderRequest.TypeOfApplicant)
    }
    ];
    this.listOtherParams = this.listOtherParams.filter(x => x.Show);
  }

  getTypeOfApplicant(typeOfApplicant){
    switch(typeOfApplicant){
      case TypeOfApplicantEnum.Individual: return "Individual";
      case TypeOfApplicantEnum.Company: return "Company";
      default: return "Indistinct";
    }
  }
  //step 5
  //onChanges
  onChangeTierOption(id: number, name: string): void {
    this.srvStepForm.OrderRequest.TierOption = id;
    this.tierTitle = 'TIER ' + name;
  }
  onKeyPressTiers(keyCode, formatNumber) {
    if (formatNumber && isNaN(Number(String.fromCharCode(keyCode))))
      return false;
  }

  setTariffGroup() {
    var values: TariffGroupIds[] = [];
    //debugger;
    let inputsCollection: NodeListOf<HTMLInputElement> =
      document.body.querySelectorAll('*[name="tariffName"]:checked');
    // #jurisdictionsChecks input[type=checkbox]:checked
    for (var i = 0; i < inputsCollection.length; i++) {
      var inp = inputsCollection[i];
      values.push({ Id: Number(inp.value) });
    }
    this.srvStepForm.OrderRequest.TariffGroups = values;
  }

  clickFinishStep5(data) {
    if (!this.srvStepForm.OrderRequest.TierOption) {
      this.showRequired('tierError', 'd-block');
      $('#cancelNext')[0].dataset.cancelNext = 'true';
      return;
    } else {
      this.showRequired('tierError', 'd-none');
      $('#cancelNext')[0].dataset.cancelNext = 'false';
    }
    
    this.setTariffGroup();
    if(this.srvStepForm.OrderRequest.TariffGroups.length == 0){
      this.showRequired('tarrifError', 'd-block');
      $('#cancelNext')[0].dataset.cancelNext = 'true';
      return;
    }else{
      this.showRequired('tarrifError', 'd-none');
      $('#cancelNext')[0].dataset.cancelNext = 'false';
    }
    this.svcLoading.show();
    let orderId = this.listOrders?.Data?.Id;
    this.listOrders = null;

    this.reduceList(this.srvStepForm.OrderRequest.OrderParameters);
    this.srvStepForm.OrderRequest.ClientName = this.clientName;
    this.srvStepForm.OrderRequest.Reference = this.reference;
    this.srvStepForm.OrderRequest.Id = orderId;
    if(this.calculateOldParameter != null && this.isPct){
      if(!this.srvStepForm.OrderRequest.OrderParameters.find(x => x.ParameterUserId == this.calculateOldParameter.ParameterUserId)){
        this.srvStepForm.OrderRequest.OrderParameters.push(this.calculateOldParameter);
      }
    }
    debugger;
    this.srvStepForm
      .generateOrder(this.srvStepForm.OrderRequest)
      .then((result: ApiResultData) => {
        this.svcLoading.hide();
        if (result.IsSucess) {
          this.tierOption = this.srvStepForm.OrderRequest.TierOption;
          $("#result").show();
          $("#form").hide();
          this.listOrders = result;
          if(result.DataResult != null && result.DataResult.length > 0){
            alert(result.DataResult.join(','))
          }
        } else {
          $("#result").hide();
          $("#form").show();
          $("#lastStep").show().css('opacity', '1');
          alert(result.MessageError);
        }
      })
      .catch((error) => {
        this.helperService.showErrorMessage(
          JSON.stringify(error.error),
          this.svcLoading
        );
      });
  }

  showRequired(error, value) {
    const inputError = <HTMLInputElement>document.getElementById(error);
    inputError.className = value;
  }
  reduceList(list) {
    let orderRequest: any = new Array<any>();
    orderRequest = this.srvStepForm.OrderRequest;
    orderRequest.OrderParameters = [];

    for (var i = 0; i < list.length; i++) {
      let reduced = new OrderParameterReduce();
      _.assign(reduced, _.pick(list[i], _.keys(reduced)));
      orderRequest.OrderParameters.push(reduced);
    }
    this.srvStepForm.OrderRequest.OrderParameters =
      orderRequest.OrderParameters;
    debugger;
  }
  isDisabledTariffGroup(tariffGroup: MatterTariffDTO){
    let array = this.session.user.subscription.SubscriptionTariffGroup;
    let tariffGroupIdList = [...new Set(array.map(x => x.TariffGroupId))];
    return !tariffGroupIdList.includes(tariffGroup.TariffGroupId);
  }

  reloadCurrentRoute(){
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

  //LawFirm
  getLawFirmInformation(): void{
    const session = this.svcStorage.getCurrentSession();
    let id = this.listOrders.Data.Id;
    if(session && id){
      this.svcLoading.show();
      if(this.lawFirmList == null){
        this.srvStepForm.getLawFirmByOrderId({Id: id, Token: session.token}).then(result => {
          this.svcLoading.hide();
          if(result.IsSucess){
            this.lawFirmList = result.ListData;
            this.openModal();
          }else{
            alert(result.MessageError);
          }
        }).catch(err => {
          alert(JSON.stringify(err));
        })
      }else{
        this.openModal()
        this.svcLoading.hide();
      }
    }
  }

  backToStepFour(){
    this.calculateOldParameter = this.srvStepForm.OrderRequest.OrderParameters?.find(x => x.ParameterUserId == 25);
    this.srvStepForm.OrderRequest.OrderParameters = [];
    $("#result").hide();
    $("#form").show();
    $("#fourthStep").show().css('opacity', '1');

    $("#next-step-4").hide();
    $("#previous-step-4").hide();
    $("#revise-parameter-button").show();
    $("#step5").removeClass('done').removeClass('active');
    let isPct = this.isPct();
    let originalFilingDate = $("#12");
    if(isPct){
      if(originalFilingDate.length > 0){
        let val = this.listEstimate.find(x => x.ParameterUserId == 12).Value;
        originalFilingDate.val(val);
      }

    }
  }

  downloadPDF(){
    this.orderId = this.listOrders.Data.Id;
  }

  sendMail(){
    this.isEmailSend = false;
    this.emailError = null;
    let id = this.listOrders.Data.Id;
    const modal = this.svcModal.show(SendEmailComponent, {
      initialState: {
        orderId: id
      }
    });
    modal.onHide.subscribe(val => {
      if(val == true){
        this.isEmailSend = true;
      }
    });
  }

  private isPct() {
    return this.pctTitle != null && this.pctTitle.length > 0;
  }

  private openModal() {
    const bsModalRef = this.svcModal.show(LawFirmListComponent, {
      initialState: {
        list: this.lawFirmList,
        orderId: this.listOrders.Data.Id
      },
      class: 'modal-xl modal-xl-law-firm'
    });
  }
}
