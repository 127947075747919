<!-- hero -->
<div class="hero inner">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-7 text-center">
        <h1
          class="wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
          "
        >
          Why Pascal IP
        </h1>
        <p
          class="wow fadeInUp"
          data-wow-delay=".4s"
          style="
            visibility: visible;
            animation-delay: 0.4s;
            animation-name: fadeInUp;
          "
        >
          Our approach to the quoting service is based on three fundamentals:
          Accuracy, Reliability, Flexibility
        </p>
      </div>
    </div>
  </div>
</div>

<!-- What is Pascal IP -->
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 text-center fadeInLeft">
        <img
          src="./assets/img/why-pascal-ip/braisle-pascal.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
      <div class="col-md-6 text-start text-md-start fadeInRight">
        <div>
          <div class="sec-title text-start text-md-start">BLAISE PASCAL</div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>
        <p>
          Blaise Pascal was a brilliant French mathematician, physicist,
          inventor, philosopher, writer, and theologian. A child prodigy, he was
          educated by his father, a tax collector.
        </p>
        <p class="mb-4">
          While still a teenager, in an effort to ease his father's endless and
          exhausting calculations of taxes owed and paid, Pascal constructed a
          mechanical calculator capable of addition and subtraction. It came to
          be known as the Pascaline.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-12 fadeInUp">
        <div class="bg-primary-box">
          <div>
            <div class="sec-title text-center">Marvelous Device</div>
            <div class="sec-line m-auto"></div>
          </div>
          <div class="text-center">
            <img
              src="./assets/img/why-pascal-ip/marvelous-device.png"
              class="img-fluid mb-4"
              alt="Pascal"
            />
          </div>
          <div class="text-start">
            <p>
              This marvelous device was a direct adding machine — the value of a
              number gets added to the accumulator as it is being dialed in. By
              moving a display bar, the operator can see either the number
              stored in the calculator, or the complement of its value.
            </p>
            <p>
              Guided by the same spirit that motivated Pascal — to ease your
              time-consuming, difficult calculations of IP costs — we honor the
              memory of this genius, who helped improve our lives forever.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
