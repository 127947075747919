import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  sortByOrder(array: any): void {
    array.sort(function (
      a: { Order: number | undefined },
      b: { Order: number | undefined }
    ) {
      if (a.Order != undefined && b.Order != undefined) {
        if (a.Order > b.Order) {
          return 1;
        }
        if (a.Order < b.Order) {
          return -1;
        }
      }
      if (a.Order == undefined && b.Order != undefined) {
        return 1;
      }
      if (a.Order != undefined && b.Order == undefined) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }
  showErrorMessage(error, serviceLoadign) {
    alert(error);
    serviceLoadign.hide();
  }
  // Generate Fake Object Array with skeleton
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
}
