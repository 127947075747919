import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/core/services/apis/account.service';
import { StorageService } from 'src/app/core/services/base/storage.service';
import { ValidatorRegister } from 'src/app/core/validators/register';
import { LoadingService } from '../../../../core/services/shared/loading.service';
import { StepFormService } from '../../../../core/services/apis/stepForm.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {
  formSendEmail: FormGroup;
  messageError: any;
  orderId: string;
  constructor(
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private svcStorage: StorageService,
    private svcLoading: LoadingService,
    private srvStepForm: StepFormService
  ) { }

  ngOnInit(): void {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.formSendEmail = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(regexEmail)]],
    });
  }

  sendEmail():void{
    const session = this.svcStorage.getCurrentSession();
    this.svcLoading.show();
    this.messageError = null;
    this.srvStepForm.sendMail({Token: session.token, Id: this.orderId, Email: this.formSendEmail.get('email').value}).then(result => {
      debugger;
      this.svcLoading.hide();
      if(result.IsSucess){
        this.bsModalRef.hide();
        this.bsModalRef.onHide.emit(true);
      }else{
        this.messageError = result.MessageError;
      }
    });
  }

}
