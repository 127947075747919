<div class="footer">
  <div class="primary-footer">
    <div class="container">
      <div class="row">
        <div
          class="col-md-4 col-lg-3 wow fadeInUp"
          style="visibility: hidden; animation-name: none"
        >
          <img
            src="./assets/img/logo-white.png"
            class="./assets/img-fluid mb-3"
            alt="logo"
          />
          <p>
            Pascal IP is a powerful tool to calculate the costs of registering
            and maintaining your IP assets around the world.
          </p>
          <div class="social pt-2">
            <ul class="list-unstyled list-inline">
              <li class="list-inline-item">
                <a href="#" target="_blank"
                  ><i class="fa fa-facebook-square"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a href="#" target="_blank"><i class="fa fa-instagram"></i></a>
              </li>
              <li class="list-inline-item">
                <a href="#" target="_blank"
                  ><i class="fa fa-linkedin-square"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a href="#" target="_blank"
                  ><i class="fa fa-twitter-square"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="col-md-4 col-lg-6 wow fadeInUp"
          style="visibility: hidden; animation-name: none"
        >
          <div class="footer-ql">
            <div class="row">
              <div class="col-6">
                <ul class="list-unstyled mb-0">
                  <li><a routerLink="/main">Home</a></li>
                  <li><a routerLink="/about">About</a></li>
                  <li><a routerLink="/features">Features</a></li>
                  <li><a routerLink="/price">Price</a></li>
                  <li><a routerLink="/how-it-works">How it Works</a></li>
                  <li>
                    <a routerLink="/is-pascal-ip-for-me"
                      >Is Pascal IP for Me?</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-6">
                <ul class="list-unstyled mb-0">
                  <li>
                    <a routerLink="/ip-kinds-covered">IP Kinds Covered</a>
                  </li>
                  <li><a routerLink="/pricing">Pricing</a></li>
                  <li><a routerLink="/terms-of-use">Terms of use</a></li>
                  <li><a routerLink="/privacy">Privacy Policy</a></li>
                  <li><a routerLink="/contact">Contact</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-4 col-lg-3 wow fadeInUp"
          style="visibility: hidden; animation-name: none"
        >
          <p class="footer-title">Contact Information</p>
          <div class="d-flex align-items-center ms-1 mb-3">
            <div class="c-details-icon">
              <img
                src="./assets/img/icon/mobile-phone.png"
                class="./assets/img-fluid me-2"
                alt="icon"
              />
            </div>

            <div>
              <a href="tel:305-328-8556">305-328-8556</a>
            </div>
          </div>
          <div class="d-flex align-items-center ms-1 mb-3">
            <div class="c-details-icon">
              <img
                src="./assets/img/icon/email.png"
                class="./assets/img-fluid me-2"
                alt="icon"
              />
            </div>

            <div>
              <a href="mailto:info@pascalip.com">info@pascalip.com</a>
            </div>
          </div>
          <div class="d-flex align-items-start ms-1">
            <div class="c-details-icon">
              <img
                src="./assets/img/icon/map-marker.png"
                class="./assets/img-fluid me-2 ml-9"
                alt="icon"
              />
            </div>

            <div class="ml-5">
              INVENTIA LLC <br />1001 Brickell Bay Dr. Suite 2700 Miami, FL, USA
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="secondry-footer">
    <i class="fa fa-registered"></i> 2021-2022 Pascal IP LLC
  </div>
</div>
