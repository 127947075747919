<!-- hero -->
<div class="hero inner">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-7 text-center">
        <h1
          class="wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
          "
        >
          Contact us
        </h1>
        <p
          class="wow fadeInUp"
          data-wow-delay=".4s"
          style="
            visibility: visible;
            animation-delay: 0.4s;
            animation-name: fadeInUp;
          "
        >
          Our approach to the quoting service is based on three fundamentals:
          Accuracy, Reliability, Flexibility
        </p>
      </div>
    </div>
  </div>
</div>
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div
        class="col-md-12 text-center wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div>
          <div class="sec-title text-center">Contact information</div>
          <div class="sec-line m-auto"></div>
        </div>
        <p class="mb-4">Get in touch with us</p>
      </div>
      <div class="mt-4"></div>
      <div
        class="col-12 col-md-4 col-lg-3 mb-4 wow fadeInUp p-0"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div class="contact-box">
          <img
            src="./assets/img/mobile-phone.svg"
            class="img-fluid mb-3"
            alt="img"
          />
          <p class="op-7 mb-1">Phone Number</p>
          <p class="text-black mb-0">305-328-8556</p>
        </div>
      </div>
      <div
        class="col-12 col-md-4 col-lg-3 mb-4 wow fadeInUp p-0"
        data-wow-delay=".2s"
        style="
          visibility: visible;
          animation-delay: 0.2s;
          animation-name: fadeInUp;
        "
      >
        <div class="contact-box">
          <img src="./assets/img/email.svg" class="img-fluid mb-26" alt="img" />
          <p class="op-7 mb-1">Email Address</p>
          <p class="text-black mb-0">info@pascalip.com</p>
        </div>
      </div>
      <div
        class="col-12 col-md-4 col-lg-3 mb-4 wow fadeInUp p-0"
        data-wow-delay=".4s"
        style="
          visibility: visible;
          animation-delay: 0.4s;
          animation-name: fadeInUp;
        "
      >
        <div class="contact-box">
          <img
            src="./assets/img/map-marker.svg"
            class="img-fluid mb-3"
            alt="img"
          />
          <p class="op-7 mb-1">Address</p>
          <p class="text-black mb-0">
            INVENTIA LLC<br />
            1001 Brickell Bay Dr., Suite 2700<br />
            Miami, FL 33131 - USA
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="sec-pad pt-0">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div
        class="col-md-12 text-center wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.207797083297!2d-80.19159698461172!3d25.76369911480492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b6823bcb94c5%3A0x87631203ec9c3e19!2s1001%20Brickell%20Bay%20Dr%20%232700%2C%20Miami%2C%20FL%2033131%2C%20USA!5e0!3m2!1sen!2sin!4v1639656878643!5m2!1sen!2sin"
          class="w-100 map"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</div>
