import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  JurisdictionBP,
  matterBP,
  OrderFilterBP,
  OrderRequestBP,
  OrderRequestDTO,
  ParamMatterUSerBP,
  ParamUserBP,
  patentPctBP,
} from '../../models/stepFormDTO';
import { DataService } from '../base/data.service';
import { Config } from '../base/endpoints.config';
@Injectable()
export class StepFormService extends DataService {
  public OrderRequest: OrderRequestDTO = new OrderRequestDTO();
  constructor(public http: HttpClient, private configuration: Config) {
    super(http, configuration.URL);
  }

  getMatterGroups() {
    this.url =
      this.configuration.URL + this.configuration.STEP1_GET_MATTER_GROUPS;
    return this.getPostApiResultListData();
  }
  getMattersFromMatterGroup(bodyParameters: matterBP) {
    this.url =
      this.configuration.URL + this.configuration.STEP1_GET_MATTERS_FROM_GROUP;
    return this.getPostApiResultListData(bodyParameters);
  }

  getDataPatentPCT(bodyParameters: patentPctBP) {
    this.url =
      this.configuration.URL + this.configuration.STEP1_GET_DATA_PATENT_PCT;
    return this.getPostApiResultData(bodyParameters);
  }
  getJurisdictionByMatterFromOrder(bodyParameters: JurisdictionBP) {
    this.url =
      this.configuration.URL +
      this.configuration.STEP2_GET_JURIDICTION_BY_MATTER_FROM_ORDER;
    return this.getPostApiResultListData(bodyParameters);
  }
  //   getCountryBody(bodyParameters: countryBodyBP) {
  //     this.url = this.configuration.URL + this.configuration.COUNTRY_BODY;
  //     return this.getPostApiResultData(bodyParameters);
  //   }
  getCurrency() {
    this.url = this.configuration.URL + this.configuration.STEP3_GET_CURRENCY;
    return this.getPostApiResultListData();
  }
  getLanguage() {
    this.url = this.configuration.URL + this.configuration.STEP3_GET_LANGUAGE;
    return this.getPostApiResultListData();
  }
  getParameterUsersByMatter(bodyParameters: ParamUserBP) {
    this.url =
      this.configuration.URL +
      this.configuration.STEP4_GET_PARAMETERS_USERS_BY_MATTER;
    return this.getPostApiResultListData(bodyParameters);
  }
  //
  getTariffGroupByMatter(bodyParameters: ParamMatterUSerBP) {
    this.url =
      this.configuration.URL +
      this.configuration.STEP5_GET_TARIFF_GROUP_BY_MATTER;
    return this.getPostApiResultListData(bodyParameters);
  }

  getTiersOptions() {
    this.url =
      this.configuration.URL + this.configuration.STEP5_GET_TIERS_OPTION;
    return this.getPostApiResultListData();
  }

  generateOrder(bodyParameters: OrderRequestBP) {
    this.url = this.configuration.URL + this.configuration.STEP5_GENERATE_ORDER;
    return this.getPostApiResultData(bodyParameters);
  }
  getLawFirmByOrderId(bodyParameters: OrderFilterBP) {
    this.url = this.configuration.URL + this.configuration.STEPS_GET_LAW_FIRM_BY_ORDER_ID;
    return this.getPostApiResultListData(bodyParameters);
  }
  sendMail(bodyParameters: OrderFilterBP){
    this.url = this.configuration.URL + this.configuration.SEND_ORDER_MAIL;
    return this.getPostApiResult(bodyParameters);
  }
  sendMailToLawFirm(bodyParameters: OrderFilterBP){
    this.url = this.configuration.URL + this.configuration.SEND_LAWFIRM_MAIL;
    return this.getPostApiResult(bodyParameters);
  }
}
