import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-is-pascal-ip-for-me',
  templateUrl: './is-pascal-ip-for-me.component.html',
  styleUrls: ['./is-pascal-ip-for-me.component.css'],
})
export class IsPascalIpForMeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
