import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../../models/userDTO';
import { DataService } from '../base/data.service';
import { Config } from '../base/endpoints.config';
@Injectable()
export class RegisterService extends DataService {
  constructor(public http: HttpClient, private configuration: Config) {
    super(http, configuration.URL);
  }

  registerUser(bodyParameters: User) {
    this.url = this.configuration.URL + this.configuration.REGISTER_USER;
    return this.getPostApiResultData(bodyParameters);
  }
}
