<div *ngIf="item" class="container p-4">
  <div *ngIf="IsSponsor">
    <img
      *ngIf="item.Logo"
      class="img-fluid"
      src="{{ 'data:image/jpg;base64,' + item.Logo }}"
      height="200"
      style="width: 100%;"
    />
    <div *ngIf="!item.Logo">
      <h2>
        <span>{{ item.Name }}</span>
      </h2>
    </div>
  </div>
  <div *ngIf="!IsSponsor">
    <h5>
      <span>{{ item.Name }}</span>
    </h5>
  </div>
  <div class="mt-4"></div>
  <div style="font-size: 16px;">
    <span>{{ item.Detail.Address }},</span><br />
    <span>{{ item.Detail.City }}</span
    ><br />
    <span>{{ item.Detail.ZipCode }}<span *ngIf="item.Detail.ZipCode && item.Detail.CountryName"> - </span>{{ item.Detail.CountryName }}</span
    ><br />
    <div class="mt-2"></div>
    <span *ngIf="OnlyOneMatter"><b>Matter: </b>{{Matters}}</span>
    <span *ngIf="!OnlyOneMatter"><b>Matters: </b>{{Matters}}</span>
    <br />
    <span><b>Contact name:</b> {{ item.Detail.ContactName }}</span
    ><br />
    <span><b>Email: </b> <a class="link-primary" href="mailto:{{item.Detail.Email}}">{{item.Detail.Email}}</a></span
    ><br />
    <div *ngIf="!IsSponsor">
      <span><b>Phone:</b> {{ item.Detail.Phone }}</span
      ><br />
    </div>
    <div *ngIf="item.Detail.WebSite">
      <span><b>Website: </b><a class="link-primary" [href]="Link" target="_blank">{{ item.Detail.WebSite }}</a></span
        ><br />
    </div>
    <div *ngIf="IsSponsor" class="mt-2">
      <span><b>Description of the Firm:</b></span><br />
      <p>{{ item.Detail.Description }}</p>
    </div>
  </div>
</div>
