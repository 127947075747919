<!-- hero -->
<div class="hero inner">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-7 text-center">
        <h1
          class="wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
          "
        >
          Features
        </h1>
        <p
          class="wow fadeInUp"
          data-wow-delay=".4s"
          style="
            visibility: visible;
            animation-delay: 0.4s;
            animation-name: fadeInUp;
          "
        >
          Our approach to the quoting service is based on three fundamentals:
          Accuracy, Reliability, Flexibility
        </p>
      </div>
    </div>
  </div>
</div>

<!-- What is Pascal IP -->
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-center wow fadeInLeft"
        style="visibility: visible; animation-name: fadeInLeft"
      >
        <img
          src="./assets/img/features/feature-01.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
      <div
        class="col-md-6 text-start text-md-start wow fadeInRight"
        style="visibility: visible; animation-name: fadeInRight"
      >
        <div>
          <div class="sec-title text-start text-md-start">Accuracy</div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>
        <p class="mb-4">
          We understand how difficult it can be to provide accurate estimates to
          a client before filing. If you overprice the service, you may lose the
          client. If you underestimate the cost, you may lose money. Our
          estimates are based on the updated tariff of each IP firm, including
          their professional fees, official fees, and any extra fees they may
          typically charge. No surprises — peace of mind every time you provide
          an estimate.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-start text-md-end order-2 order-md-1 wow fadeInLeft"
        style="visibility: visible; animation-name: fadeInLeft"
      >
        <div>
          <div class="sec-title text-start text-md-end">Reliability</div>
          <div class="sec-line ms-0 ms-md-auto"></div>
        </div>
        <p class="mb-4">
          Our quotes are based on the updated tariff of multiple IP attorneys in
          each country. No hidden costs, fees, or procedures. No unpleasant
          surprises — the cost you get from Pascal IP is the cost you will pay
          for this filing. That’s our commitment!
        </p>
      </div>
      <div
        class="col-md-6 text-center order-1 order-md-2 wow fadeInRight"
        style="visibility: visible; animation-name: fadeInRight"
      >
        <img
          src="./assets/img/features/feature-02.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
    </div>
  </div>
</div>
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-center wow fadeInLeft"
        style="visibility: visible; animation-name: fadeInLeft"
      >
        <img
          src="./assets/img/features/feature-03.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
      <div
        class="col-md-6 text-start text-md-start wow fadeInRight"
        style="visibility: visible; animation-name: fadeInRight"
      >
        <div>
          <div class="sec-title text-start text-md-start">Flexibility</div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>
        <p class="mb-4">
          We have a wide network of foreign patent and trademark lawyers,
          covering over 180 countries. However, if you feel comfortable with a
          specific foreign IP attorney outside of our network, you do not have
          to leave them to work with us. We can incorporate the tariff of your
          preferred IP firm at no extra cost to you. Learn more about how
          flexible our patent and trademark quoting services really are.
        </p>
      </div>
    </div>
  </div>
</div>
