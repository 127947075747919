import { Component, Input, OnInit } from '@angular/core';
import { IpFirmsDirectoryDetailDTO, IpFirmsDirectoryDTO, LawFirmMatterCategoryEnum } from '../../../../core/models/ip-firms-directoryDTO';
//TODO: Revisar que campos son obligatorios.
@Component({
  selector: 'app-law-firm-detail',
  templateUrl: './law-firm-detail.component.html',
  styleUrls: ['./law-firm-detail.component.css']
})
export class LawFirmDetailComponent implements OnInit {
  @Input("item")item: IpFirmsDirectoryDTO;
  get IsSponsor(){
    return this.item.Category == LawFirmMatterCategoryEnum.Sponsor;
  }
  get Link(){
    let link = this.item.Detail.WebSite;
    if(!link.startsWith("http://") && !link.startsWith("https://")){
      return "http://"+link;
    }
    return link;
  }
  get OnlyOneMatter(){
    return this.item.Detail.Matters.length == 1;
  }
  get Matters(){
    return this.item.Detail.Matters.join(", ");
  }
  constructor() { }

  ngOnInit(): void {
  }

}
