import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/auth/login.service';
import { StorageService } from '../services/base/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthSubscriptionWithShowCountryGuideGuard implements CanActivate {
  constructor(private svclogin: LoginService, private router: Router, private svcLocalStorage: StorageService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const session = this.svcLocalStorage.getCurrentSession();
    if(session?.user?.subscription?.ShowCountryGuide == true){
      return true;
    }else{
      this.router.navigate(['main']);
      return false;
    }
  }
  
}
