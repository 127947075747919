import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/core/services/base/storage.service';
import { LoadingService } from 'src/app/core/services/shared/loading.service';
import { LoginService } from '../../core/services/auth/login.service';

@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.css'],
})
export class HeadComponent implements OnInit {
  loggedIn: boolean = false;
  userName: string;
  loadingStatus$: Observable<boolean>;
  constructor(
    private svcStorage: StorageService,
    private svcLoading: LoadingService,
    public svcLogin: LoginService
  ) {}

  ngOnInit(): void {
    this.initProgress();
    let loginStatus = this.svcLogin.loginStatus();
    if (loginStatus) {
      this.loggedIn = loginStatus.isAuthenticated;
      this.userName = loginStatus.username;
    }
    this.svcLogin.loginStatusChange().subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
      if (this.loggedIn) {
        this.userName =
          this.svcStorage.getCurrentUser().firstName +
          ' ' +
          this.svcStorage.getCurrentUser().lastName;
      }
    });
  }
  initProgress(): void {
    this.loadingStatus$ = this.svcLoading.listenLoading();
  }
  validSubscription():boolean{
    const session = this.svcStorage.getCurrentSession();
    return session?.user?.subscription?.ShowCountryGuide == true;
  }
}
