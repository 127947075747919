<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-12 text-center fadeInUp">
        <div>
          <div class="sec-title text-center">Privacy Policy</div>
          <div class="sec-line m-auto"></div>
        </div>
        <p class="mb-4">
          This privacy policy ("policy") will help you understand how Pascal IP
          uses andprotects the data you provide to us when you visit and use our
          service.We reserve the right to change this policy at any given time,
          of which you will be promptlyupdated. If you want to make sure that
          you are up to datewith the latest changes, we adviseyou to frequently
          visit this page.Your use of the Site following the posting of such
          changes or revised statement shall constitute your acceptance of any
          such changes. We encourage you to review our privacy statement
          whenever you visit our website to make sure that you understand how
          any personal information that you provide will be used. This privacy
          statement applies to all users of this Site, including but not limited
          to, Pascal IP's customers who register for our services ("Registered
          Users").
        </p>
      </div>

      <div class="col-md-12 fadeInUp mt-4">
        <h5 class="text-black text-uppercase bold">INFORMATION COLLECTION</h5>
        <h5 class="text-black bold">What User Data We Collect</h5>
        <p class="mb-2">
          When you visit the website, we may collect the following data:
        </p>
        <ul class="mb-4">
          <li>Your IP address.</li>
          <li>Your contact information and email address.</li>
          <li>Other information such as interests and preferences.</li>
          <li>Data profile regarding your online behavior on our website.</li>
        </ul>

        <h5 class="text-black bold">Why We Collect Your Data</h5>
        <p class="mb-2">We are collecting your data for several reasons:</p>
        <ul class="mb-4">
          <li>To provide services you have requested.</li>
          <li>To better understand your needs.</li>
          <li>To improve our services and products.</li>
          <li>To respond to your comments or requests.</li>
          <li>
            To send you promotional emails containing the information we think
            you will findinteresting.
          </li>
          <li>
            To contact you to fill out surveys and participate in other types of
            market research.
          </li>
          <li>
            To customize our website according to your online behavior and
            personal preferences.
          </li>
        </ul>

        <h5 class="text-black bold">Safeguarding and Securing the Data</h5>
        <p class="mb-4">
          Pascal IP is committed to securing your data and keeping it
          confidential. Pascal IPhas done all in itspower to prevent data theft,
          unauthorized access, and disclosure by implementing the
          latesttechnologies and software, which help us safeguard all the
          information we collect online.
        </p>

        <h5 class="text-black bold">Our Cookie Policy</h5>
        <p>
          Once you agree to allow our website to use cookies, you also agree to
          use the data it collectsregarding your online behavior (analyze web
          traffic, web pages you spend the most time on,and websites you visit).
        </p>
        <p>
          The data we collect by using cookies is used to customize our website
          to your needs. After weuse the data for statistical analysis, the data
          is completely removed from our systems.
        </p>
        <p>
          Please note that cookies donot allow us to gain control of your
          computer in any way. They arestrictly used to monitor which pages you
          find useful and which you do not so that we canprovide a better
          experience for you.
        </p>
        <p class="mb-4">
          If a user rejects a cookie, they may still use our service, however,
          they will be limited in some of its areas. Pascal IP reserves the
          right to prohibit access to and use of the website if cookies are
          disabled.If you want to disable cookies, you can do it by accessing
          the settings of your internet browser.
        </p>
        <h5 class="text-black text-uppercase bold">
          REGISTERED USERS’ INFORMATION USE
        </h5>
        <p>
          Registered Users will occasionally receive site and service
          announcement updates. Registered Users are not able to unsubscribe
          from such announcements which contain important information about our
          service.
        </p>
        <p class="mb-4">
          Registered Users and anyone who has signed up for our mailing list
          will occasionally receive information on products, services, or
          special promotions. Anyone receiving these communications may
          unsubscribe at any time by clicking on the “Unsubscribe” bottom or by
          sending a request to Pascal IP using the contact information below.
        </p>
        <h5 class="text-black text-uppercase bold">INFORMATION DISCLOSURE</h5>
        <p>
          We will not sell, share, or rent any personal information to others in
          ways different from what is disclosed in this statement without first
          obtaining your authorization.
        </p>
        <p>
          We may disclose identifying information that you provide to
          independent contractors, service providers and consultants who are
          engaged by or working with Pascal IP for the purpose of providing
          services. For example, we may use credit card processing companies to
          process payments for goods and services.
        </p>
        <p class="mb-4">
          We may also disclose personal information that we have collected if we
          are required to do so by law or, if in our good faith judgment, such
          action is reasonably necessary to comply with legal process, to
          respond to any claims, or to protect the rights of Pascal IP and its
          Registered Users and the public.
        </p>

        <h5 class="text-black text-uppercase bold">DATA RETENTION</h5>
        <p>
          Pascal IP seeks to ensure that it retains only data necessary to
          effectively conduct its service and work in fulfilment of its
          mission.The need to retain data varies widely with the type of data
          and the purpose for which it wascollected. Pascal IP strives to ensure
          that data is only retained for the periodnecessary to fulfil the
          purpose for which it was collected and is fully deleted when no
          longerrequired. This policy sets forth Pascal IP ’s guidelines on data
          retention and is tobe consistently applied throughout the
          organization.
        </p>
        <p class="mb-4">
          You may delete your account by sending a request to Pascal IP using
          the contact information below, and we will delete the personal
          information it holds about you (unless we need to retain it for the
          purposes set out in this Privacy Policy).
        </p>

        <h5 class="text-black text-uppercase bold">
          LEGAL BASES FOR PROCESSING
        </h5>
        <p class="mb-4">
          The legal bases for processing of personal data are primarily that the
          processing is necessary for providing the Service in accordance with
          Pascal IP’s Terms of Service and that the processing is carried out in
          Pascal IP’s legitimate interests, which are further explained in the
          section “Information Use” of this Policy. We may also process data
          upon your consent, asking for it as appropriate.
        </p>
        <h5 class="text-black text-uppercase bold">DATA OWNERSHIP</h5>
        <p>
          Customer retains all rights, title and interest to its Customer Data.
          Customer grants Pascal IP a non- exclusive right to process, use,
          copy, store, transmit, modify, display, perform and create derivative
          works of Customer Data only to the amount as necessary to provide the
          Service as defined in the Service Agreement and as permitted by
          applicable law.
        </p>
        <p class="mb-5">
          Where applicable law requires (and subject to any relevant exceptions
          under law), you may have the right to access, update, change or delete
          personal information. You may have the right to restrict or object to
          the processing of your personal information or to exercise a right to
          data portability under applicable law. You also may have the right to
          lodge a complaint with a competent supervisory authority, subject to
          applicable law. To exercise these rights, or if you have any questions
          about the processing of your personal information, please send a
          request to Pascal IP:
        </p>
        <h5 class="text-black bold">By postal mail</h5>
        <p class="mb-4">
          Pascal IP <br />Legal Department <br />1001 Brickell Bay Dr., Suite
          2700<br />
          Miami, FL 33131
        </p>
        <h5 class="text-black bold">By email</h5>
        <p class="">
          <a href="mailto: legal@pascalip.com" class="text-primary">
            legal@pascalip.com</a
          >
        </p>
      </div>
    </div>
  </div>
</div>
