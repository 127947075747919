import { Component, OnInit } from '@angular/core';
import {JuridictionDTO} from '../../core/models/country-guideDTO';
import { CombosService } from '../../core/services/apis/combos.service';
import { ApiResultListData } from 'src/app/core/models/base/ApiResult';
import { LoadingService } from 'src/app/core/services/shared/loading.service';
import { HelperService } from '../../core/services/shared/helper.service';
import { IpFirmsDirectoryService } from 'src/app/core/services/apis/ip-firms-directory.service';
import { IpFirmsDirectoryDTO, LawFirmMatterCategoryEnum } from 'src/app/core/models/ip-firms-directoryDTO';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LawFirmDetailComponent } from './modal/law-firm-detail/law-firm-detail.component';
@Component({
  selector: 'app-ip-firms-directory',
  templateUrl: './ip-firms-directory.component.html',
  styleUrls: ['./ip-firms-directory.component.css']
})
export class IpFirmsDirectoryComponent implements OnInit {
  listCountrys: JuridictionDTO[] = [];
  listLawFirm: IpFirmsDirectoryDTO[] = [];
  jurisdictionId?: number = null;
  get CurrentJurisdiction(){
    return this.listCountrys.find(x => x.Id == this.jurisdictionId);
  }
  get SponsorList(){
    return this.listLawFirm.filter(x => x.Category == LawFirmMatterCategoryEnum.Sponsor);
  }
  get FeaturedList(){
    return this.listLawFirm.filter(x => x.Category == LawFirmMatterCategoryEnum.Featured);
  }
  get StandardList(){
    return this.listLawFirm.filter(x => x.Category == LawFirmMatterCategoryEnum.Standard);
  }
  constructor(    
    private srvCombos: CombosService,
    private srvLoading: LoadingService,
    private helperService: HelperService,
    private srvIpfirmsDirectory: IpFirmsDirectoryService,
    private srvModal: BsModalService) { }

  ngOnInit(): void {
    this.srvCombos
    .getAllCountries()
    .then((result: ApiResultListData) => {
      this.srvLoading.hide();
      debugger;
      if (result.IsSucess) {
        this.listCountrys = result.ListData;
        this.helperService.sortByOrder(this.listCountrys);
      } else {
        alert(result.MessageError);
      }
    })
    .catch((error) => {
      alert(JSON.stringify(error.error));
    });
  }

  onChangeSelectJurisdiciton(jurisdictionId?: number){
    this.jurisdictionId = jurisdictionId;
    if(this.jurisdictionId == 0){
      this.listLawFirm = [];
      this.jurisdictionId = null;
    }else{
      this.srvLoading.show();
      this.srvIpfirmsDirectory.getLawFirms({JurisdictionId: jurisdictionId}).then(result => {
        this.srvLoading.hide();
        if (result.IsSucess) {
          this.listLawFirm = result.ListData;
        } else {
          alert(result.MessageError);
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error.error));
      });
    }
  }
  onClickLawFirm(lawFirm: IpFirmsDirectoryDTO){
    this.srvLoading.show();
    this.srvIpfirmsDirectory.getLawFirmByIdAndCategory({
      Id: lawFirm.Id,
      Category: lawFirm.Category
    }).then(result => {
      this.srvLoading.hide();
      if (result.IsSucess) {
        this.srvModal.show(LawFirmDetailComponent, {
          initialState: {
            item: result.Data
          }
        });
      } else {
        alert(result.MessageError);
      }
    }).catch((error) => {
      alert(JSON.stringify(error.error));
    });
  }
}
