import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CustomerSubscriptionDTO, CustomerSubscriptionResponseDTO, PaypalOrderResponseDTO } from 'src/app/core/models/customerSubscriptionDTO';
import { PriceService } from 'src/app/core/services/apis/price.service';
import { StorageService } from 'src/app/core/services/base/storage.service';
import { Session } from '../../../../core/models/base/session';
import { Router } from '@angular/router';
import { LoadingService } from '../../../../core/services/shared/loading.service';
import { LoginService } from '../../../../core/services/auth/login.service';
import { TariffPromotion } from '../../../../core/models/tariff-promotion';

interface PaymentTypeDTO{
  Id: number;
  Name: string;
}

enum PaymentType{
  CreditOrDebit=1,
  Paypal=2
};

@Component({
  selector: 'app-payment-subscription',
  templateUrl: './payment-subscription.component.html',
  styleUrls: ['./payment-subscription.component.css'],
})
export class PaymentSubscriptionComponent implements OnInit {
  formPayment!: FormGroup;
  submitted: boolean = false;
  isLoading: boolean = false;
  numberOfItems = 0;
  customerSubscription: CustomerSubscriptionDTO;
  public item: any = null;
  onlyPaypal: boolean = false;
  public event: EventEmitter<any> = new EventEmitter<any>();
  public session: Session;
  public showCardFields : boolean = false;
  public paymentTypes: Array<PaymentTypeDTO> = [
    {Id: PaymentType.Paypal, Name: "Paypal"},
    {Id: PaymentType.CreditOrDebit, Name: "Credit or Debit"},
  ]
  public messageError = "";
  public discountCodeMessageError = "";
  get cardNumber(){
    return this.formPayment.get('cardNumber').value;
  }
  public isValidDiscountCode: boolean=null;
  discount: TariffPromotion = null;
  constructor(
    private formBuilder: FormBuilder,
    private svcPrice: PriceService,
    public bsModalRef: BsModalRef,
    public options: ModalOptions,
    private storageService: StorageService,
    public router: Router,
    private svcLoading: LoadingService,
    private svcLogin: LoginService
  ) {
  }
  ngOnInit(): void {
    let test = this.item;
    this.session = this.storageService.getCurrentSession();
    const isPerQuote = this.item.SubType == 1;
    const isPerUser = this.item.SubType == 2;
    this.formPayment = this.formBuilder.group({
      cardNumber: [
        '',
        null
      ],
      cardName: [''],
      cardExpiration1: [''],
      cardExpiration2: [''],
      cardSecretCode: [''],
      countQuote: ['',isPerQuote ? [Validators.required] : []],
      countUser: ['', isPerUser ? [Validators.required] : []],
      discountCode: ['']
    });
    if (test.SubType == 3) {
      this.formPayment.removeControl('cardNumber');
      this.formPayment.removeControl('cardName');
      this.formPayment.removeControl('cardExpiration1');
      this.formPayment.removeControl('cardExpiration2');
      this.formPayment.removeControl('cardSecretCode');
    }
    this.svcLogin.isValidToken().then(result => {
      debugger;
      if(result.IsSucess == false){
        this.storageService.logout();
        setTimeout(()=>{
          this.bsModalRef.hide();
        }, 300);
      }
    }).catch(err => {
      this.storageService.logout();
      setTimeout(()=>{
        this.bsModalRef.hide();
      }, 300);
    });
  }
  saveSubscription(form) {
    debugger;
    this.submitted = true;
    if (!form.valid) {
      // alert('Please check required form fields!');
      return false;
    } else {
      this.isLoading = true;
      let token = this.storageService.getCurrentToken();
      this.customerSubscription = {
        Token: token,
        SubscriptionsId: this.item.Id,
        CountQuote: form.value.countQuote,
        CountUser: form.value.countUser,
        CardNumber: form.value.cardNumber,
        CardName: form.value.cardName,
        CardExpiration:
          form.value.cardExpiration2 +'-'+form.value.cardExpiration1,
        CardSecretCode: form.value.cardSecretCode,
        PaymentMethod: this.showCardFields ? PaymentType.CreditOrDebit : PaymentType.Paypal,
        DiscountCode: form.value.discountCode
      };
      if(!this.isValidDiscountCode){
        this.customerSubscription.DiscountCode = null;
      }
      this.messageError = null;
      this.svcLoading.show();
      this.svcPrice
        .subscriptionPlan(this.customerSubscription)
        .then((result) => {
          this.svcLoading.hide();
          if (result.IsSucess) {
            let _session = this.storageService.loadSessionData();
            let data:CustomerSubscriptionResponseDTO = result.Data;
            if(data.PaypalOrderResponse){
              if(PaypalOrderResponseDTO.isCreated(data.PaypalOrderResponse)){
                if(_session.user.customerSubscriptions == null){
                  _session.user.customerSubscriptions = [];
                }
                _session.user.customerSubscriptions.push(data.CustomerSubscription);
                this.storageService.setCurrentSession(_session);
                window.location.href = PaypalOrderResponseDTO.getCheckoutNowLink(data.PaypalOrderResponse);
              }else if(PaypalOrderResponseDTO.isCompleted(data.PaypalOrderResponse)){
                this.router.navigate(['account/your-subscription'], {queryParams: {success: true}})
              }
            }else{
              this.router.navigate(['account/your-subscription'], {queryParams: {success: true}})
            }
            this.bsModalRef.hide();
          } else {
            this.isLoading = false;
            this.messageError = result.MessageError;
          }
        });
      return true;
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  numberOnly12(event): boolean {
    if (event.currentTarget.value < 13) {
      return true;
    } else {
      return (event.currentTarget.value = event.currentTarget.value.substring(
        0,
        event.currentTarget.value.length - 1
      ));
    }
  }
  redirectToSignIn(){
    this.router.navigate(['login'], {queryParams: {redirectURL: 'price'}});
    this.bsModalRef.hide();
  }
  onChangeSelectPaymentType(val){
    this.showCardFields = val == PaymentType.CreditOrDebit;
    if(val == PaymentType.CreditOrDebit){
      console.log(document.getElementById("cardNumberInput"));
      this.formPayment.get('cardNumber').setValidators([
        Validators.required,
        Validators.maxLength(19),
        Validators.minLength(19),
      ]);
      this.formPayment.get('cardName').setValidators([Validators.required]);
      this.formPayment.get('cardExpiration1').setValidators([Validators.required, Validators.minLength(2)]);
      this.formPayment.get('cardExpiration2').setValidators([Validators.required, Validators.minLength(4)]);
      this.formPayment.get('cardSecretCode').setValidators( [Validators.required,        Validators.maxLength(4),
        Validators.minLength(3),]);
    }else{
      this.formPayment.get('cardNumber').clearValidators();
      this.formPayment.get('cardName').clearValidators();
      this.formPayment.get('cardExpiration1').clearValidators();
      this.formPayment.get('cardExpiration2').clearValidators();
      this.formPayment.get('cardSecretCode').clearValidators();
    }
    this.formPayment.get('cardNumber').updateValueAndValidity();
      this.formPayment.get('cardName').updateValueAndValidity();
      this.formPayment.get('cardExpiration1').updateValueAndValidity();
      this.formPayment.get('cardExpiration2').updateValueAndValidity();
      this.formPayment.get('cardSecretCode').updateValueAndValidity();
  }
  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData
    let pastedText = clipboardData.getData('text');
    pastedText = pastedText.replace(' ',"");
    let finalText = "";
    for(let i=0;i<pastedText.length;i++){
      if((i+1)%4 == 0){
        finalText = finalText+pastedText[i] +" ";
      }else{
        finalText += pastedText[i];
      }
    }
    this.formPayment.get('cardNumber').setValue(finalText);
    this.formPayment.get('cardNumber').updateValueAndValidity();
  }
  async checkDiscount(){
    let discountCodeInput = this.formPayment.get('discountCode');
    this.discountCodeMessageError = "";
    if(this.isValidDiscountCode){
      return;
    }else if(this.isValidDiscountCode == false){
      this.isValidDiscountCode = null;
      this.discount = null;
      discountCodeInput.setValue(null);
      discountCodeInput.updateValueAndValidity();
      return;
    }
    let value = discountCodeInput.value;
    if(!value){
      this.isValidDiscountCode = null;
    }
    this.svcLoading.show();
    this.isLoading = true;
    discountCodeInput.disable();
    let result = await this.svcPrice.checkDiscountCode({Code: value});
    discountCodeInput.enable();
    this.isLoading = false;
    this.svcLoading.hide();
    if(result.IsSucess){
      this.discount = result.Data;
      this.isValidDiscountCode=true;
    }else{
      this.discountCodeMessageError = result.MessageError;
      this.isValidDiscountCode=false;
    }
  }

  getSubTotal(){
    let subTotal = 0;
    if(this.item.SubType == 1){
      subTotal = this.item.Price * this.formPayment.controls["countQuote"].value;
    }else{
      subTotal = this.item.Price * this.formPayment.controls["countUser"].value
    }
    return subTotal;
  }
  getTotal(){
    let subTotal = this.getSubTotal();
    return subTotal - subTotal*this.discount.DiscountPercentage/100;
  }
  getDiscountTooltip(){
    if(this.isValidDiscountCode){
      return 'Check';
    }else if(this.isValidDiscountCode == null){
      return 'Verify';
    }else if(this.isValidDiscountCode == false){
      return 'Try again';
    }
  }
}
