<div class="modal-bodypt-0">
    <div class="border-box-padding text-start">
        <div class="sec-title ft-25 text-start mb-2">
            {{item != null ? "Edit User" : "Create User"}}
        </div>
        <p class="mb-4">
            {{item != null ? "Update user information" : "New user with basic details"}}
            </p>
        <form class="pt-4" [formGroup]="formUserForm" (ngSubmit)="saveUser()">
            <input type="hidden" name="" value="" id="userId" class="form-control" formControlName="userId">
            <div class="row">
                <div class="col col-6">
                    <div class="mb-4 form-group position-relative">
                        <label>First name</label>
                        <input type="text" name="" placeholder="John" id="firstName" class="form-control" formControlName="firstName">
                        <img
                        src="./assets/img/icon/user-avatar-filled-alt.svg"
                        class="img-fluid"
                        alt="icon"
                      />
                    </div>
                </div>
                <div class="col col-6">
                    <div class="mb-4 form-group position-relative">
                        <label>Last name</label>
                        <input type="text" name="" placeholder="Deo" id="lastName" class="form-control" formControlName="lastName">
                        <img
                        src="./assets/img/icon/user-avatar-filled-alt.svg"
                        class="img-fluid"
                        alt="icon"
                      />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-8">
                    <div class="mb-4 form-group position-relative">
                        <label>Email</label>
                        <input type="text" name="" value="john_deo@pascalip.com" placeholder="Email" id="email"
                            class="form-control" formControlName="email">
                        <img src="./assets/img/icon/email.svg" class="img-fluid" alt="icon">
                    </div>
                </div>
                <div class="col col-4">
                    <div
                    class="mb-4 form-group position-relative"
                  >
                    <label>Active</label>
                    <select
                      tabindex="3"
                      class="form-select active-select"
                      id="active"
                      aria-label="Floating label select example"
                      formControlName="active"
                      placeholder="Select"
                    >
                      <option selected="true" value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>
            </div>

            <div class="mb-4 form-group position-relative" *ngIf="item==null">
                <label>New Password</label>
                <input tabindex="6" type="password" name="" placeholder="6+ Strong Charecter" id="password"
                    class="form-control" formControlName="password"/>
                <img src="./assets/img/icon/key.svg" class="img-fluid" alt="icon" />
            </div>
            <div class="mb-4 form-group position-relative" *ngIf="item==null">
                <label>Confirm Password</label>
                <input tabindex="7" type="password" name="" placeholder="6+ Strong Charecter" id="confirmPassword"
                    class="form-control" formControlName="confirmPassword"/>
                <img src="./assets/img/icon/key.svg" class="img-fluid" alt="icon" />
            </div>
            <div class="mb-3">
                <button class="btn btn-theme w-100 br-8" type="submit" [disabled]="!formUserForm.valid">{{item != null ? "Update" : "Create"}}</button>
                <p
                *ngIf="
                  formUserForm.controls['firstName'].hasError('required') &&
                  formUserForm.controls['firstName'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter name
              </p>
              <p
                *ngIf="
                  formUserForm.controls['lastName'].hasError('required') &&
                  formUserForm.controls['lastName'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter last name
              </p>
              <p
                *ngIf="
                  formUserForm.controls['email'].hasError('required') &&
                  formUserForm.controls['email'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter email
              </p>
              <p
                *ngIf="
                  formUserForm.controls['email'].hasError('email') &&
                  formUserForm.controls['email'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter valid email
              </p>
              <p
                *ngIf="
                  formUserForm.controls['email'].hasError('pattern') &&
                  formUserForm.controls['email'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter valid email
              </p>
              <p
                *ngIf="
                  formUserForm.controls['password'].hasError('required') &&
                  formUserForm.controls['password'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter password
              </p>
              <p
                *ngIf="
                  formUserForm.controls['password'].hasError('pattern') &&
                  formUserForm.controls['password'].touched
                "
                class="alert alert-danger error-margin"
              >
                Check that a password has a minimum of 6 characters, at least 1
                uppercase letter and 1 lowercase letter
              </p>
              <p
                *ngIf="
                  formUserForm.controls['confirmPassword'].hasError(
                    'pattern'
                  ) && formUserForm.controls['confirmPassword'].touched
                "
                class="alert alert-danger error-margin"
              >
                Check that a confirm password has a minimum of 6 characters, at
                least 1 uppercase letter and 1 lowercase letter
              </p>
              <p
                *ngIf="
                  formUserForm.controls['confirmPassword'].hasError(
                    'required'
                  ) && formUserForm.controls['confirmPassword'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter confirm password
              </p>
              <p
                *ngIf="
                  formUserForm.errors?.matching &&
                  !formUserForm.controls['password'].hasError('pattern') &&
                  !formUserForm.controls['confirmPassword'].hasError('pattern')
                "
                class="alert alert-danger error-margin"
              >
                Confirm Password does not match
              </p>
            </div>
            <div *ngIf="messageError != null" class="alert alert-danger">{{messageError}}</div>
        </form>
    </div>
</div>