<div class="sec-pad">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div
        class="col-md-12 text-center wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div>
          <div class="sec-title text-center">Your Quotes</div>
          <div class="sec-line m-auto"></div>
        </div>
        <p class="mb-4">Your previous quotations</p>
      </div>
      <div class="mt-4"></div>
      <div
        class="col-md-12 wow fadeInUp table-responsive"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div class="row form-group ">
          <div class="col col-2 position-relative mt-3">
            <label>Date From</label>
            <input
              type="text"
              placeholder="Range"
              class="form-control"
              [(ngModel)]="dateFrom"
              [maxDate]="dateTo"
              [bsConfig]="bsConfig"
              bsDatepicker
            />
          </div>
          <div class="col col-2 position-relative mt-3">
            <label>Date To</label>
            <input
              type="text"
              placeholder="Range"
              class="form-control"
              [minDate]="dateFrom"
              [(ngModel)]="dateTo"
              [bsConfig]="bsConfig"
              bsDatepicker
            />
          </div>
          <div class="col col-2 mt-3 d-flex flex-row">
            <input type="button" class="form-btn m-0 align-items-center" value="Search" (click)="onClickSearchButton()"/>
          </div>
        </div>
        <ng-container *ngIf="listQuotes != null; else elseTemplate">
          <table class="table">
            <thead class="">
              <tr>
                <th class="text-start">Number</th>
                <th class="text-start">Date</th>
                <th class="text-start">Jurisdictions</th>
                <th>Matter</th>
                <th>Currency</th>
                <th>Client</th>
                <th>Reference</th>
                <th>Quotes</th>
                <th>Download</th>
                <th>Send Email</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of listQuotes">
                <td class="text-start">{{ item.Id }}</td>
                <td class="text-start">
                  {{ item.DateOrder | date : "dd MMM YY" }}
                </td>
                <td class="text-start">{{ getJurisdictions(item) }}</td>
                <td>{{ item.Matter.Name }}</td>
                <td>{{ item.Currency.Acronym }}</td>
                <td>
                  <span class="text-theme">{{ item.ClientName ?? "NN" }}</span>
                </td>
                <td>{{ item.Reference ?? "NN" }}</td>
                <td>
                  <a
                    (click)="openQuoteModal(item)"
                    class="text-warning text-decoration-underline cursor-pointer"
                    >Quote</a
                  >
                </td>
                <td>
                  <a
                    (click)="downloadPdf(item)"
                    class="text-warning text-decoration-underline cursor-pointer"
                    tooltip="Download"
                    ><i class="fa fa-download"></i
                  ></a>
                </td>
                <td>
                  <a
                    (click)="sendEmail(item, template)"
                    class="text-warning text-decoration-underline cursor-pointer"
                    tooltip="Send Email"
                    ><i class="fa fa-envelope"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-template #elseTemplate>
          <table class="table">
            <thead class="">
              <tr>
                <th class="text-start">Number</th>
                <th class="text-start">Date</th>
                <th class="text-start">Jurisdictions</th>
                <th>Matter</th>
                <th>Currency</th>
                <th>Client</th>
                <th>Reference</th>
                <th>Download</th>
                <th>Send email</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of generateFake(7)">
                <td *ngFor="let j of generateFake(9)">
                  <ngx-skeleton-loader
                    [theme]="{ width: '100px' }"
                  ></ngx-skeleton-loader>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </div>
      <div class="col-12 text-center">
        <button
          (click)="returnToMyProfile()"
          class="btn btn-theme wow fadeInUp mt-4"
          data-wow-delay=".4s"
          style="
            visibility: visible;
            animation-delay: 0.4s;
            animation-name: fadeInUp;
          "
        >
          DONE
        </button>
      </div>
    </div>
  </div>
</div>
<div style="position: absolute; top: -2000px; left: -2000px" *ngIf="orderId">
  <app-generate-order-pdf
    [orderId]="orderId"
    (finishDownload)="orderId = null"
  ></app-generate-order-pdf>
</div>
<ng-template #template>
  <div *ngIf="sendEmailError">
    <div class="modal-header">
      <h4 class="modal-title pull-left text-danger">Send email error!</h4>
    </div>
    <div class="modal-body text-danger">
      {{ sendEmailError }}
    </div>
  </div>

  <div *ngIf="!sendEmailError">
    <div class="modal-header">
      <h4 class="modal-title pull-left text-success">
        Email sent successfully!
      </h4>
    </div>
    <div class="modal-body text-success">Check your email.</div>
  </div>
</ng-template>
