import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { countryBodyBP, countryBP, sectionBP } from '../../models/country-guideDTO';
import { DataService } from '../base/data.service';
import { Config } from '../base/endpoints.config';
@Injectable()
export class CombosService extends DataService {
  constructor(public http: HttpClient, private configuration: Config) {
    super(http, configuration.URL);
  }

  getMatters() {
    this.url = this.configuration.URL + this.configuration.MATTER;
    return this.getPostApiResultListData();
  }
  getContrys(bodyParameters: countryBP) {
    this.url = this.configuration.URL + this.configuration.JURIDICTION;
    return this.getPostApiResultListData(bodyParameters);
  }
  getAllCountries() {
    this.url = this.configuration.URL + this.configuration.JURIDICTION_ALL;
    return this.getPostApiResultListData();
  }

  getSections(bodyParameters: sectionBP) {
    this.url = this.configuration.URL + this.configuration.SECTION;
    return this.getPostApiResultListData(bodyParameters);
  }

  getSubSections(bodyParameters: sectionBP) {
    this.url = this.configuration.URL + this.configuration.SUBSECTION;
    return this.getPostApiResultListData(bodyParameters);
  }

  getCountryBody(bodyParameters: countryBodyBP) {
    this.url = this.configuration.URL + this.configuration.COUNTRY_BODY;
    return this.getPostApiResultData(bodyParameters);
  }
}
