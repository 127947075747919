import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/auth/login.service';
import { StorageService } from '../services/base/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthLoginWithSubscriptionGuard implements CanActivate {
  constructor(
    private svclogin: LoginService,
    private router: Router,
    private svcStorage: StorageService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    debugger;
    if (
      this.svcStorage.getCurrentSession() &&
      this.svcStorage.getCurrentSession().user.subscription &&
      this.svclogin.isLogged()
    ) {
      return true;
    } else {
      const session = this.svcStorage.getCurrentSession();
      if(session && !session.user.subscription){
        this.router.navigate(['price'], {
          queryParams: { redirectURL: state.url },
        });
      }else{
        this.router.navigate(['login'], {
          queryParams: { redirectURL: state.url },
        });
      }
      return false;
    }
  }
}
