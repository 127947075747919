export interface IpFirmsDirectoryDTO{
    Id: number;
    Logo: string;
    Name: string;
    Category?: number;
    Detail?: IpFirmsDirectoryDetailDTO;
    DocsForFiling?: Array<IpFirmsDirectoryDocForFilingDTO>;
}
export interface IpFirmsDirectoryDetailDTO{
    Matters: Array<string>;
    CountryName: string;
    City: string;
    Address: string;
    ZipCode: string;
    ContactName: string;
    Email: string;
    Phone: string;
    WebSite: string;
    Description: string;
    RequerimentsForFiling: string;
}
export interface IpFirmsDirectoryDocForFilingDTO{
    Name: string;
    Base64: string;
}
export enum LawFirmMatterCategoryEnum
{
    Standard = 1,
    Featured = 2,
    Sponsor = 3
}