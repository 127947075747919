import { Component, OnInit } from '@angular/core';
import { ApiResultListData } from 'src/app/core/models/base/ApiResult';
import { ApiResultData } from '../../core/models/base/ApiResult';
import {
  CountryGuideBodyDTO,
  CountryGuideSectionTitlesDTO,
  CountryGuideSubSectionTitlesDTO,
  JuridictionDTO,
  MatterDTO,
} from '../../core/models/country-guideDTO';
import { CombosService } from '../../core/services/apis/combos.service';
import { HelperService } from '../../core/services/shared/helper.service';
import { LoadingService } from 'src/app/core/services/shared/loading.service';
import { Session } from '../../core/models/base/session';
import { StorageService } from '../../core/services/base/storage.service';

@Component({
  selector: 'app-country-guide',
  templateUrl: './country-guide.component.html',
  styleUrls: ['./country-guide.component.css'],
})
export class CountryGuideComponent implements OnInit {
  listMatter: MatterDTO[] = [];
  listCountrys: JuridictionDTO[] = [];
  listSection: CountryGuideSectionTitlesDTO[] = [];
  listSubSection: CountryGuideSubSectionTitlesDTO[] = [];
  countryGuideBody?: CountryGuideBodyDTO;
  public matterId: number = 0;
  public jurisdictionId: number = 0;
  public sectionId: number = 0;
  public subSectionId: number = 0;
  public countryGuideBodyId: number = 0;
  private session: Session;
  constructor(
    private srvCombos: CombosService,
    private helperService: HelperService,
    private srvLoading: LoadingService,
    private svcStorage: StorageService
  ) {}

  ngOnInit(): void {
    this.session = this.svcStorage.getCurrentSession();
    this.srvLoading.show();
    this.srvCombos
      .getMatters()
      .then((result: ApiResultListData) => {
        this.srvLoading.hide();
        if (result.IsSucess) {
          this.listMatter = result.ListData;
          this.helperService.sortByOrder(this.listMatter);
          this.matterId = 0;
        } else {
          alert(result.MessageError);
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error.error));
      });
  }
  onChangeSelectMatter(id: number): void {
    this.jurisdictionId = 0;
    this.sectionId = 0;
    this.subSectionId = 0;
    this.countryGuideBody = null;
    this.srvLoading.show();
    this.srvCombos
      .getContrys({ MatterId: id })
      .then((result: ApiResultListData) => {
        this.srvLoading.hide();
        if (result.IsSucess) {
          this.listCountrys = result.ListData;
          this.matterId = id;
        } else {
          alert(result.MessageError);
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error.error));
      });
  }

  onChangeSelectCountry(id: number): void {
    this.subSectionId = 0;
    this.sectionId = 0;
    this.countryGuideBody = undefined;
    this.jurisdictionId = 0;
    if(id == 0){
      return;
    }
    this.srvLoading.show();
    this.srvCombos
      .getSections({
        JurisdictionId: id,
        Token: this.session.token
      })
      .then((resp: ApiResultListData) => {
      this.srvLoading.hide();
        if (resp.IsSucess) {
          this.listSection = resp.ListData;
          this.jurisdictionId = id;
        } else {
          alert(resp.MessageError);
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error.error));
      });
  }
  onChangeSelectSection(id: number): void {
    this.subSectionId = 0;
    this.countryGuideBody = undefined;
    this.sectionId = 0;
    if(id == 0){
      return;
    }
    this.srvLoading.show();
    this.srvCombos
      .getSubSections({
        SectionId: id,
        Token: this.session.token
      })
      .then((resp: ApiResultListData) => {
        this.srvLoading.hide();
        if (resp.IsSucess) {
          this.listSubSection = resp.ListData;
          if (this.listSubSection != null && this.listSubSection.length > 0) {
            this.countryGuideBodyId = this.listSubSection[0].CountryGuideBodyId;
          }
          this.sectionId = id;
        } else {
          alert(resp.MessageError);
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error.error));
      });
  }
  onChangeSelectSubSection(id: number): void {
    if(id == 0){
      this.countryGuideBody = null;
      return;
    }
    this.srvLoading.show();
    this.srvCombos
      .getCountryBody({
        MatterId: this.matterId,
        JurisdictionId: this.jurisdictionId,
        SectionId: this.sectionId,
        SubSectionId: id,
        Token: this.session.token
      })
      .then((resp: ApiResultData) => {
        this.srvLoading.hide();
        if (resp.IsSucess) {
          if(resp.Data.Id == 0){
            this.countryGuideBody = null;
          }else{
            this.countryGuideBody = resp.Data;
          }
        } else {
          alert(resp.MessageError);
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error.error));
      });
  }
}
