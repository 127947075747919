<div [classList]="['border-'+type+' border row alert-custom']" style="height: 60px;margin: 0!important;" *ngIf="show">
    <div [classList]="['col text-white bg-'+type+' alert-icon text-center']" style="height: 60px;">
        <div class="row align-items-center h-100">
            <div class="col-12">
                <i [classList]="[iconByType]"></i>
            </div>
        </div>
    </div>
    <div [classList]="['alert-body col-11 '+textColor]">
       <div class="row align-items-center h-100">
        <div class="col-12">
            <span class="alert-title mt-2" [innerHtml]="title"></span>
            <small class="m-0 d-block"[innerHtml]="body"></small>
        </div>
       </div>
    </div>
</div>