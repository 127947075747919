import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiResultData } from 'src/app/core/models/base/ApiResult';
import { Session } from 'src/app/core/models/base/session';
import { LoginDTO } from 'src/app/core/models/loginDTO';
import { LoginService } from 'src/app/core/services/auth/login.service';
import { StorageService } from 'src/app/core/services/base/storage.service';
import { LoadingService } from 'src/app/core/services/shared/loading.service';
import { AccountService } from '../../core/services/apis/account.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  formLogin!: FormGroup;
  user: LoginDTO;
  loadingStatus$: Observable<boolean>;
  errorLogin: string;
  successMessage: string;
  redirectURL: string;
  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.formLogin = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(regexEmail)]],
    });
    window.scrollTo(0,0);
  }

  onSubmit() {
    this.errorLogin = null;
    this.successMessage = null;
    this.loadingService.show();
    if (!this.formLogin.valid) {
      alert('Please check required form fields!');
      return false;
    } else {
      this.user = {
        email: this.formLogin.get('email').value,
        password: "",
      };
      this.accountService.forgotPassword(this.user).then(result => {
        debugger;
        if(result.IsSucess){
          this.successMessage = result.MessageError;
        }
      }).catch(err => {
        alert(JSON.stringify(err.error));
      }).finally(()=>this.loadingService.hide());
      // this.srvLogin
      //   .loginUser(this.user)
      //   .then((result: ApiResultData) => {
      //     if (result.IsSucess) {
      //     } else {
      //       this.errorLogin = result.MessageError;
      //     }
      //   })
      //   .catch((error) => {
      //     alert(JSON.stringify(error.error));
      //   })
      //   .finally(() => {
      //     this.loadingService.hide();
      //   });

      return true;
    }
  }

}
