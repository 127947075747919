<div class="sec-pad">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div
        class="col-md-10 col-lg-7 wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div class="login-box text-start">
          <div class="sec-title ft-25 text-start mb-2">
            Login &amp; Security
          </div>
          <p class="mb-4">
            Here you will be able to modify your information and reset your
            password.
          </p>
          <form
            class="pt-4"
            [formGroup]="formLoginSecurity"
            (ngSubmit)="onSubmit()"
          >
            <div class="mb-4 form-group position-relative">
              <label>First Name</label>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value="John"
                id="firstName"
                class="form-control"
                formControlName="firstName"
                name="firstName"
              />
              <img
                src="./assets/img/icon/user.svg"
                class="img-fluid"
                alt="icon"
              />
            </div>
            <div class="mb-4 form-group position-relative">
              <label>Last Name</label>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value="Deo"
                id="lastName"
                class="form-control"
                formControlName="lastName"
                name="lastName"
              />
              <img
                src="./assets/img/icon/user.svg"
                class="img-fluid"
                alt="icon"
              />
            </div>
            <div class="mb-4 form-group position-relative">
              <label>Email</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value="info@paskalip.com"
                id="email"
                class="form-control"
                formControlName="email"
                name="email"
              />
              <img
                src="./assets/img/icon/email.svg"
                class="img-fluid"
                alt="icon"
              />
            </div>
            <p class="mb-0">
              <a
                (click)="openModalWithComponent()"
                class="text-theme cursor-pointer"
                ><span class="fa fa-lock me-2"></span>Change Password</a
              >
            </p>
            <div class="mt-5">
              <button
                class="btn btn-theme w-100 br-8"
                [disabled]="!formLoginSecurity.valid || !formLoginSecurity.touched"
              >
                Update
              </button>
              <p
                *ngIf="
                  formLoginSecurity.controls['firstName'].hasError(
                    'required'
                  ) && formLoginSecurity.controls['firstName'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter name
              </p>
              <p
                *ngIf="
                  formLoginSecurity.controls['lastName'].hasError('required') &&
                  formLoginSecurity.controls['lastName'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter lastname
              </p>
              <p
                *ngIf="
                  formLoginSecurity.controls['email'].hasError('required') &&
                  formLoginSecurity.controls['email'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter email
              </p>
              <p
                *ngIf="
                  formLoginSecurity.controls['email'].hasError('pattern') &&
                  formLoginSecurity.controls['email'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter valid email
              </p>
            </div>
            <div class="mt-3">
             <alert *ngIf="messageSuccess != null" type="success"> {{ messageSuccess }}</alert>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
