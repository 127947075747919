import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiResultData } from 'src/app/core/models/base/ApiResult';
import { HelperService } from '../../core/services/shared/helper.service';
import { AccountService } from '../../core/services/apis/account.service';
import { StorageService } from '../../core/services/base/storage.service';
import { OrderTierDescription } from '../../core/models/yourQuotes';
import { LoadingService } from '../../core/services/shared/loading.service';
import { StepFormService } from '../../core/services/apis/stepForm.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LawFirmListComponent } from './modal/law-firm-list/law-firm-list.component';
import { ExtraParameterUser } from 'src/app/core/models/extra-parameter-user';
import { SendEmailComponent } from '../your-quotes/modal/send-email/send-email.component';
import { TypeOfApplicantEnum } from 'src/app/core/enums/type-of-applicant-enum';

@Component({
  selector: 'app-quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.css'],
})
export class QuoteDetailComponent implements OnInit {
  public pctTitle: string;
  public tierTitle: string;
  public listEstimate: any;
  public listOrders: ApiResultData;
  public tierOption: number;
  public lawFirmList: any[] = null;
  isEmailSend: boolean = false;
  emailError: any = null;
  orderId: string = null;
  listOtherParams: ExtraParameterUser[] = [];
  matterName: string;
  constructor(
    public router: Router,
    public helperService: HelperService,
    private svcAccount: AccountService,
    private svcStorage: StorageService,
    private activatedRoute: ActivatedRoute,
    private svcLoading: LoadingService,
    private svcStepForm: StepFormService,
    private svcModal: BsModalService
  ) {
    this.pctTitle = '';
    this.tierTitle = '';
  }

  ngOnInit(): void {
    const session = this.svcStorage.getCurrentSession();
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    this.svcLoading.show();
    this.svcAccount
      .getOrderByCustomerAndId({ Id: id, token: session.token })
      .then(async (result) => {
        let tierOptionList = await this.svcStepForm.getTiersOptions();
        const order = result.DataResult;
        this.svcLoading.hide();
        this.pctTitle = order.PatentPCT ?? '';
        this.listEstimate = order.OrderParameters;
        this.createOtherParams(order);
        this.listOrders = result;
        this.tierOption = order.TierOption;
        this.tierTitle =
          'TIER ' +
          tierOptionList.ListData.find((x) => x.Id == this.tierOption).Name;
        this.matterName = order.Matter.Name;
      });
  }

  private createOtherParams(order: any) {
    this.listOtherParams = [
      {
        SiteLabel: 'Client',
        Show: order.ClientName.trim().length > 0,
        Value: order.ClientName,
      },
      {
        SiteLabel: 'Reference',
        Show: order.Reference.trim().length > 0,
        Value: order.Reference,
      },
      {
        SiteLabel: 'Pct patent',
        Show: order.PatentPCT && order.PatentPCT.trim().length > 0,
        Value: order.PatentPCT,
      },
      {
        SiteLabel: 'Currency',
        Show: true,
        Value: order.Currency.Name,
      },
      {
        SiteLabel: "Language",
        Show: true,
        Value: order.Language != null ? order.Language.Name : "",
      },
      {
        SiteLabel: "Type of applicant",
        Show: true,
        Value: this.getTypeOfApplicant(order.TypeOfApplicant)
      }
    ];
    this.listOtherParams = this.listOtherParams.filter((x) => x.Show);
  }
  getTypeOfApplicant(typeOfApplicant){
    switch(typeOfApplicant){
      case TypeOfApplicantEnum.Individual: return "Individual";
      case TypeOfApplicantEnum.Company: return "Company";
      default: return "Indistinct";
    }
  }
  getLawFirmInformation(): void {
    const session = this.svcStorage.getCurrentSession();
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    if (session && id) {
      this.svcLoading.show();
      if (this.lawFirmList == null) {
        this.svcStepForm
          .getLawFirmByOrderId({ Id: id, Token: session.token })
          .then((result) => {
            this.svcLoading.hide();
            if (result.IsSucess) {
              this.lawFirmList = result.ListData;
              this.openModal();
            } else {
              alert(result.MessageError);
            }
          })
          .catch((err) => {
            alert(JSON.stringify(err));
          });
      } else {
        this.openModal();
        this.svcLoading.hide();
      }
    }
  }

  private openModal() {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    let initialState = {
      list: this.lawFirmList,
      orderId: id
    };
    const bsModalRef = this.svcModal.show(LawFirmListComponent, {
      initialState: initialState,
      class: 'modal-xl modal-xl-law-firm',
    });
  }
  downloadPDF(){
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    this.orderId = id;
  }
  sendMail(){
    this.isEmailSend = false;
    this.emailError = null;
    let id = this.listOrders.Data.Id;
    const modal = this.svcModal.show(SendEmailComponent, {
      initialState: {
        orderId: id
      }
    });
    modal.onHide.subscribe(val => {
      if(val == true){
        this.isEmailSend = true;
      }
    });
  }
}
