import { Component, OnInit, ViewChild } from '@angular/core';
import {
  IpFirmsDirectoryDocForFilingDTO,
  IpFirmsDirectoryDTO,
  LawFirmMatterCategoryEnum,
} from 'src/app/core/models/ip-firms-directoryDTO';
import { IpFirmsDirectoryService } from 'src/app/core/services/apis/ip-firms-directory.service';
import { LoadingService } from 'src/app/core/services/shared/loading.service';
import { StorageService } from 'src/app/core/services/base/storage.service';
import { StepFormService } from '../../../../core/services/apis/stepForm.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sponsor-law-firm',
  templateUrl: './sponsor-law-firm.component.html',
  styleUrls: ['./sponsor-law-firm.component.css'],
})
export class SponsorLawFirmComponent implements OnInit {
  lawFirmId: any;
  orderId: any;
  item: IpFirmsDirectoryDTO;
  isSuccess: boolean = null;
  messageError: any = '';
  isLoading: boolean = false;
  constructor(
    private srvIpfirmsDirectory: IpFirmsDirectoryService,
    private srvLoading: LoadingService,
    private svcStorage: StorageService,
    private srvStepForm: StepFormService,
    private bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    this.srvLoading.show();
    let result = await this.srvIpfirmsDirectory.getLawFirmByIdAndCategory({
      Id: this.lawFirmId,
      Category: LawFirmMatterCategoryEnum.Sponsor,
    });
    if (!result.IsSucess) {
      alert(result.MessageError);
      return;
    }
    this.item = result.Data;
    this.srvLoading.hide();
  }
  async sendEmail() {
    const session = this.svcStorage.getCurrentSession();
    this.isLoading = true;
    this.srvLoading.show();
    this.srvStepForm
      .sendMailToLawFirm({
        Token: session.token,
        Id: this.orderId,
        Email: this.item.Detail.Email,
        ContactName: this.item.Detail.ContactName,
      })
      .then((result) => {
        this.srvLoading.hide();
        if (result.IsSucess) {
          this.isSuccess = true;
        } else {
          this.isSuccess = false;
          this.messageError = result.MessageError;
        }
      });
  }
  close() {
    this.bsModalRef.hide();
  }
  download(doc: IpFirmsDirectoryDocForFilingDTO) {
    const linkSource =
      'data:application/pdf;base64,' +
      doc.Base64;
    const downloadLink = document.createElement('a');
    const fileName = doc.Name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
