<div class="modal-bodypt-0" *ngIf="isSuccess == null || isSuccess == true">
    <div class="sec-title ft-25 text-start p-3">
        Law firm information
    </div>
    <div class="table-responsive px-2">
        <table class="table" style="border-radius: 0!important;">
            <thead>
              <tr>
                <th scope="col">Jurisdiction</th>
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col">Contact</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Website</th>
  
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of list">
                <td>{{item.JurisdictionName}} 
                  <span *ngIf="item.IsSponsor" class="badge badge-primary c-p" (click)="onClickSponsor(item)">Sponsor</span>
                  <div class="mt-1">
                    <button *ngIf="item.IsSponsor" class="btn btn-primary p-0 click-here-btn" (click)="sendEmail(item)" [disabled]="isLoading" [ngClass]="{'btn-success disabled':item.isSuccess==true}">
                      <span *ngIf="!item.isLoading && item.isSuccess == null">Click here <br>To contact this firm</span>
                      <div *ngIf="item.isLoading" class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <span *ngIf="item.isSuccess==true">Email sent successfully!</span>
                    </button>
                  </div>
                </td>
                <td>{{item.Name}}</td>
                <td>{{item.CompleteAddress}}</td>
                <td>{{item.ContactName}}</td>
                <td><a href="mailto: {{item.Email}}" class="link-primary">{{item.Email}}</a></td>
                <td>{{item.Phone}}</td>
                <td>
                  <a class="link-primary" [href]="item.Website" target="_blank">{{createLink(item.Website)}}</a>
                </td>
              </tr>
            </tbody>
          </table>
    </div>
</div>
<div class="container p-4" *ngIf="isSuccess==false">
  <alert type="danger" [dismissible]="true" (onClose)="close()">{{messageError}}</alert>
</div>