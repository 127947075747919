import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { CombosService } from './core/services/apis/combos.service';
import { PriceService } from './core/services/apis/price.service';
import { RegisterService } from './core/services/apis/register.service';
import { StepFormService } from './core/services/apis/stepForm.service';
import { LoginService } from './core/services/auth/login.service';
import { StorageService } from './core/services/base/storage.service';
import { LoadingService } from './core/services/shared/loading.service';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CountryGuideComponent } from './pages/country-guide/country-guide.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FeaturesComponent } from './pages/features/features.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { IpKindsCoveredComponent } from './pages/ip-kinds-covered/ip-kinds-covered.component';
import { IsPascalIpForMeComponent } from './pages/is-pascal-ip-for-me/is-pascal-ip-for-me.component';
import { MainComponent } from './pages/main/main.component';
import { NopagefoundComponent } from './pages/nopagefound/nopagefound.component';
import { PaymentSubscriptionComponent } from './pages/price/modal/payment-subscription/payment-subscription.component';
import { PriceComponent } from './pages/price/price.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { RegisterComponent } from './pages/register/register.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { StepFormComponent } from './pages/step-form/step-form.component';
import { TermOfUseComponent } from './pages/term-of-use/term-of-use.component';
import { WhyPascalIpComponent } from './pages/why-pascal-ip/why-pascal-ip.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeadComponent } from './shared/head/head.component';
import { AccountComponent } from './pages/account/account.component';
import { YourQuotesComponent } from './pages/your-quotes/your-quotes.component';
import { LoginSecurityComponent } from './pages/login-security/login-security.component';
import { YourProfileInformationComponent } from './pages/your-profile-information/your-profile-information.component';
import { YourSubscriptionComponent } from './pages/your-subscription/your-subscription.component';
import { CustomerServicesComponent } from './pages/customer-services/customer-services.component';
import { YourPaymentsComponent } from './pages/your-payments/your-payments.component';
import { AccountService } from './core/services/apis/account.service';
import { ChangePasswordComponent } from './pages/login-security/modal/change-password/change-password.component';
import { ProfileInformationUserFormComponent } from './pages/your-profile-information/modal/profile-information-user-form/profile-information-user-form.component';
import { QuoteDetailComponent } from './pages/quote-detail/quote-detail.component';
import { LawFirmListComponent } from './pages/quote-detail/modal/law-firm-list/law-firm-list.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ActiveUserComponent } from './pages/active-user/active-user.component';
import { AlertComponent } from './shared/alert/alert.component';
import { GenerateOrderPdfComponent } from './pages/generate-order-pdf/generate-order-pdf.component';
import {TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { MainVideoComponent } from './pages/main/modal/main-video/main-video.component';
import { SendEmailComponent } from './pages/your-quotes/modal/send-email/send-email.component';
import { IpFirmsDirectoryComponent } from './pages/ip-firms-directory/ip-firms-directory.component';
import { LawFirmDetailComponent } from './pages/ip-firms-directory/modal/law-firm-detail/law-firm-detail.component';
import { SponsorLawFirmComponent } from './pages/quote-detail/modal/sponsor-law-firm/sponsor-law-firm.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    NopagefoundComponent,
    AboutComponent,
    ContactComponent,
    FaqComponent,
    FeaturesComponent,
    HowItWorksComponent,
    IpKindsCoveredComponent,
    IsPascalIpForMeComponent,
    LoginComponent,
    PriceComponent,
    PrivacyComponent,
    RegisterComponent,
    TermOfUseComponent,
    WhyPascalIpComponent,
    HeadComponent,
    FooterComponent,
    MainComponent,
    ServicesPageComponent,
    CountryGuideComponent,
    StepFormComponent,
    PaymentSubscriptionComponent,
    AccountComponent,
    YourQuotesComponent,
    LoginSecurityComponent,
    YourProfileInformationComponent,
    YourSubscriptionComponent,
    CustomerServicesComponent,
    YourPaymentsComponent,
    ChangePasswordComponent,
    ProfileInformationUserFormComponent,
    QuoteDetailComponent,
    LawFirmListComponent,
    ForgotPasswordComponent,
    ActiveUserComponent,
    AlertComponent,
    GenerateOrderPdfComponent,
    MainVideoComponent,
    SendEmailComponent,
    IpFirmsDirectoryComponent,
    LawFirmDetailComponent,
    SponsorLawFirmComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    AlertModule,
    NgxLoadingModule.forRoot({}),
    NgxSkeletonLoaderModule,
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [
    HttpClient,
    CombosService,
    LoginService,
    RegisterService,
    StorageService,
    PriceService,
    LoadingService,
    BsModalService,
    StepFormService,
    AccountService,
    {provide:LocationStrategy, useClass:HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
