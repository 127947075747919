<div class="hero inner">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-7 text-center">
        <h1
          class="wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
          "
        >
          IP Firms Directory
        </h1>
        <p
          class="wow fadeInUp"
          data-wow-delay=".4s"
          style="
            visibility: visible;
            animation-delay: 0.4s;
            animation-name: fadeInUp;
          "
        >
          Placeholder text
        </p>
      </div>
    </div>
  </div>
</div>
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-12 text-center wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div>
          <div class="sec-title text-center">IP Firms Directory</div>
          <div class="sec-line m-auto"></div>
        </div>
      </div>
      <div
        class="col-md-12 text-center wow fadeInUp"
        style="visibility: hidden; animation-name: none"
      >
        <div class="country-guide-box">
          <div class="sec-title text-start text-md-start mb-2">
            Select country of your choice
          </div>
          <form>
            <div tabindex="1" class="form-floating mb-5 mt-4">
              <select
                class="form-select"
                id="selectCountryIP"
                aria-label="Floating label select example"
                (change)="onChangeSelectJurisdiciton($any($event.target).value)"
                [disabled]="listCountrys.length == 0"
              >
                <option selected="" value="0">Select Country</option>
                <option
                  *ngFor="let country of listCountrys"
                  [value]="country.Id"
                >
                  {{ country.NameEn }}
                </option>
              </select>
              <label for="selectCountryIP">Select the country</label>
            </div>
          </form>
        </div>
        <div class="country-guide-box mt-4" *ngIf="jurisdictionId != null">
          <div class="mt-2 title-container">
            <div>
              <div class="sec-title text-end text-md-end sec-title-lg">
                <b>{{ CurrentJurisdiction?.NameEn }}</b>
              </div>
              <div class="sec-line ms-auto"></div>
            </div>
            <h4 class="mt-5 sec-title text-start"><b>Our sponsors</b></h4>
            <span *ngIf="SponsorList.length > 0"
              >These are the Law Firms Sponsoring this jurisdiction:
            </span>
            <div *ngFor="let lawFirm of SponsorList" class="mt-3">
              <div class="row" *ngIf="lawFirm.Logo" style="text-align: center">
                <div class="col col-lg-3 col-md-6 col-sm-12">
                  <img
                    (click)="onClickLawFirm(lawFirm)"
                    class="img-fluid cursor-pointer"
                    src="{{ 'data:image/jpg;base64,' + lawFirm.Logo }}"
                  />
                </div>
              </div>
              <div *ngIf="!lawFirm.Logo">
                <h2>
                  <span
                    (click)="onClickLawFirm(lawFirm)"
                    class="cursor-pointer"
                    >{{ lawFirm.Name }}</span
                  >
                </h2>
              </div>
            </div>
            <div class="mt-3"></div>

            <h4 class="mt-5 sec-title text-start">
              <b>Our featured listings</b>
            </h4>
            <div *ngFor="let lawFirm of FeaturedList">
              <h5>
                <span
                  (click)="onClickLawFirm(lawFirm)"
                  class="cursor-pointer"
                  >{{ lawFirm.Name }}</span
                >
              </h5>
            </div>
            <div class="mt-3"></div>

            <h4 class="mt-5 sec-title text-start">
              <b>Our regular listings</b>
            </h4>
            <div *ngFor="let lawFirm of StandardList">
              <span (click)="onClickLawFirm(lawFirm)" class="cursor-pointer">{{
                lawFirm.Name
              }}</span>
            </div>
            <div class="mt-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
