import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { ApiResultData } from 'src/app/core/models/base/ApiResult';
import { Session } from 'src/app/core/models/base/session';
import { LoginDTO } from 'src/app/core/models/loginDTO';
import { LoginService } from 'src/app/core/services/auth/login.service';
import { StorageService } from 'src/app/core/services/base/storage.service';
import { LoadingService } from 'src/app/core/services/shared/loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  formLogin!: FormGroup;
  user: LoginDTO;
  loadingStatus$: Observable<boolean>;
  errorLogin: string;
  redirectURL: string;
  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private router: Router,
    private srvLogin: LoginService,
    private loadingService: LoadingService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initProgress();
    const regexPassword = /^((?=\S*?[A-Z])(?=\S*?[a-z]).{6,})\S$/;
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.formLogin = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(regexEmail)]],
      password: ['', [Validators.required, Validators.pattern(regexPassword)]],
    });
  }
  initProgress(): void {
    this.loadingStatus$ = this.loadingService.listenLoading();
  }

  onSubmit() {
    this.errorLogin = null;
    this.loadingService.show();
    if (!this.formLogin.valid) {
      alert('Please check required form fields!');
      return false;
    } else {
      this.user = {
        email: this.formLogin.get('email').value,
        password: this.formLogin.get('password').value,
      };

      this.srvLogin
        .loginUser(this.user)
        .then((result: ApiResultData) => {
          if (result.IsSucess) {
            let _session = new Session();
            _session.token = result.Data.Token;
            _session.user = {
              id: result.Data.Id,
              firstName: result.Data.FirstName,
              lastName: result.Data.LastName,
              email: result.Data.Email,
              companyName: result.Data.Company,
              customerSubscriptions: result.Data.Customer.CustomerSubscriptions,
              subscription: result.Data.Customer.Subscription,
              isCustomerAdmin: result.Data.IsCustomerAdmin,
              customerId: result.Data.CustomerId,
            };
            this.storageService.setCurrentSession(_session);
            this.srvLogin.loginSuccess();
            this.srvLogin.loginStatusChange();
            let params = this.route.snapshot.queryParams;
            if (params['redirectURL']) {
              this.redirectURL = params['redirectURL'];
            }
            if (this.redirectURL) {
              this.router
                .navigateByUrl(this.redirectURL)
                .catch(() => this.router.navigate(['main']));
            } else {
              this.router.navigate(['main']);
            }
          } else {
            this.errorLogin = result.MessageError;
          }
        })
        .catch((error) => {
          alert(JSON.stringify(error.error));
        })
        .finally(() => {
          this.loadingService.hide();
        });

      return true;
    }
  }
}
