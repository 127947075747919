<!-- hero -->
<div class="hero inner">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-7 text-center">
        <h1
          class="wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
          "
        >
          About Us
        </h1>
      </div>
    </div>
  </div>
</div>

<div class="sec-pad pb-0">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-center wow fadeInLeft"
        style="visibility: visible; animation-name: fadeInLeft"
      >
        <img
          src="./assets/img/about/about-us-01.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>

      <div
        class="col-md-6 text-start text-md-start wow fadeInRight"
        style="visibility: visible; animation-name: fadeInRight"
      >
        <div>
          <div class="sec-title text-start text-md-start">
            What is Pascal IP?
          </div>
          <div class="sec-line ms-0 ms-md-0"></div>
        </div>
        <p class="mb-4">
          Pascal IP is an adjunct of
          <a href="http://www.inventiaglobal.com" class="text-primary"
            >Inventia Global Patent Solutions</a
          >, a company dedicated to Intellectual Property (IP) and legal-related
          services and solutions for more than 20 years. They are the developers
          of LexisNexis® Global IP Law Service, IPQuester.com, and the World
          Manual on Patent and Trademarks from Oxford University Press.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="sec-pad pb-0">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-md-6 text-start text-md-end order-2 order-md-1 wow fadeInLeft"
        style="visibility: hidden; animation-name: none"
      >
        <p class="mb-4">
          Inventia, headquartered in the US with representatives in 180+
          countries, is a worldwide leader in IP, providing international patent
          and trademark filing solutions to the IP community. Pascal IP offers a
          powerful, affordable, and reliable IP quoting service that covers all
          stages of your IP assets, from filing to granting and maintenance of
          any type of patent or trademark, anywhere in the world.
        </p>
      </div>
      <div
        class="col-md-6 text-center order-1 order-md-2 wow fadeInRight"
        style="visibility: hidden; animation-name: none"
      >
        <img
          src="./assets/img/about/about-us-02.png"
          class="img-fluid"
          alt="Pascal"
        />
      </div>
    </div>
  </div>
</div>
