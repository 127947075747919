<!-- Register -->
<div class="sec-pad">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div
        class="col-md-10 col-lg-7 wow fadeInUp"
        style="visibility: visible; animation-name: fadeInUp"
      >
      <p
      *ngIf="successMessage!=null; else form"
      class="alert alert-success"
    >
    {{successMessage}} <a [routerLink]="['/main']" class="text-theme">Go to Home.</a>
    </p>
    <ng-template #form>
      <div class="login-box text-start">
        <div class="sec-title ft-25 text-start mb-2">
          Register to Pascal IP
        </div>
        <p class="mb-4">Create your account with basic details</p>
        <form class="pt-4" [formGroup]="formRegister" (ngSubmit)="onSubmit()">
          <div class="mb-4 form-group position-relative col-lg-6 float-right">
            <label>Last name</label>
            <input
              tabindex="2"
              formControlName="lastName"
              name="lastName"
              type="text"
              placeholder="Deo"
              class="form-control"
              #refName
            />
            <img
              src="./assets/img/icon/user-avatar-filled-alt.svg"
              class="img-fluid"
              alt="icon"
            />
          </div>

          <div
            class="mb-4 form-group position-relative col-lg-6 paddin-right"
          >
            <label>First name</label>
            <input
              tabindex="1"
              formControlName="firstName"
              type="text"
              name=""
              placeholder="John"
              id=""
              class="form-control"
            />
            <img
              src="./assets/img/icon/user-avatar-filled-alt.svg"
              class="img-fluid paddin-right"
              alt="icon"
            />
          </div>

          <div
            *ngIf="type == 2"
            class="mb-4 form-group position-relative col-lg-6 float-right"
          >
            <label>Company</label>
            <input
              tabindex="4"
              formControlName="company"
              type="text"
              name=""
              placeholder="Company"
              id=""
              class="form-control"
            />
            <!-- <img
            src="./assets/img/icon/user-avatar-filled-alt.svg"
            class="img-fluid"
            alt="icon"
          /> -->
          </div>
          <div
            class="mb-4 form-group position-relative col-lg-6 paddin-right"
          >
            <label>Customer type</label>
            <select
              tabindex="3"
              (change)="changeCustomerType($event)"
              class="form-select customer-type"
              id="selectCustomerType"
              aria-label="Floating label select example"
              formControlName="customerType"
              placeholder="Select"
            >
              <option selected="true" value="0">Select</option>
              <option value="1">Individual</option>
              <option value="2">Company</option>
            </select>
          </div>

          <div class="mb-4 form-group position-relative col-lg-12">
            <label>Email</label>
            <input
              tabindex="5"
              type="email"
              name=""
              placeholder="example@mail.com"
              id=""
              class="form-control"
              formControlName="email"
            />
            <img
              src="./assets/img/icon/at.svg"
              class="img-fluid"
              alt="icon"
            />
          </div>
          <div class="mb-4 form-group position-relative">
            <label>Password</label>
            <input
              tabindex="6"
              type="password"
              name=""
              placeholder="6+ Strong Charecter"
              id=""
              class="form-control"
              formControlName="password"
            />
            <img
              src="./assets/img/icon/lock.svg"
              class="img-fluid"
              alt="icon"
            />
          </div>
          <div class="mb-4 form-group position-relative">
            <label>Confirm Password</label>
            <input
              tabindex="7"
              type="password"
              name=""
              placeholder="6+ Strong Charecter"
              id=""
              class="form-control"
              formControlName="confirmPassword"
            />
            <img
              src="./assets/img/icon/lock.svg"
              class="img-fluid"
              alt="icon"
            />
          </div>
          <div class="mb-3">
            <button
              tabindex="8"
              class="btn btn-theme w-100 br-8"
              type="submit"
              [disabled]="
                !formRegister.valid ||
                formRegister.controls['customerType'].value == 0
              "
            >
              Register
            </button>
            <p
              *ngIf="formRegister.errors?.typeRequireCompany"
              class="alert alert-danger error-margin"
            >
              please enter the Company
            </p>

            <p
              *ngIf="
                formRegister.controls['firstName'].hasError('required') &&
                formRegister.controls['firstName'].touched
              "
              class="alert alert-danger error-margin"
            >
              please enter name
            </p>
            <p
              *ngIf="
                formRegister.controls['lastName'].hasError('required') &&
                formRegister.controls['lastName'].touched
              "
              class="alert alert-danger error-margin"
            >
              please enter last name
            </p>
            <p
              *ngIf="
                (formRegister.controls['customerType'].value == 0 &&
                  formRegister.controls['customerType'].touched) ||
                (formRegister.controls['customerType'].hasError('required') &&
                  formRegister.controls['customerType'].touched)
              "
              class="alert alert-danger error-margin"
            >
              please select the Customer type
            </p>
            <p
              *ngIf="formRegister.controls['company'].hasError('required')"
              class="alert alert-danger error-margin"
            >
              please enter company
            </p>
            <p
              *ngIf="
                formRegister.controls['email'].hasError('required') &&
                formRegister.controls['email'].touched
              "
              class="alert alert-danger error-margin"
            >
              please enter email
            </p>
            <p
              *ngIf="
                formRegister.controls['email'].hasError('email') &&
                formRegister.controls['email'].touched
              "
              class="alert alert-danger error-margin"
            >
              please enter valid email
            </p>
            <p
              *ngIf="
                formRegister.controls['email'].hasError('pattern') &&
                formRegister.controls['email'].touched
              "
              class="alert alert-danger error-margin"
            >
              please enter valid email
            </p>
            <p
              *ngIf="
                formRegister.controls['password'].hasError('required') &&
                formRegister.controls['password'].touched
              "
              class="alert alert-danger error-margin"
            >
              please enter password
            </p>
            <p
              *ngIf="
                formRegister.controls['password'].hasError('pattern') &&
                formRegister.controls['password'].touched
              "
              class="alert alert-danger error-margin"
            >
              Check that a password has a minimum of 6 characters, at least 1
              uppercase letter and 1 lowercase letter
            </p>
            <p
              *ngIf="
                formRegister.controls['confirmPassword'].hasError(
                  'pattern'
                ) && formRegister.controls['confirmPassword'].touched
              "
              class="alert alert-danger error-margin"
            >
              Check that a confirm password has a minimum of 6 characters, at
              least 1 uppercase letter and 1 lowercase letter
            </p>
            <p
              *ngIf="
                formRegister.controls['confirmPassword'].hasError(
                  'required'
                ) && formRegister.controls['confirmPassword'].touched
              "
              class="alert alert-danger error-margin"
            >
              please enter confirm password
            </p>
            <p
              *ngIf="
                formRegister.errors?.matching &&
                !formRegister.controls['password'].hasError('pattern') &&
                !formRegister.controls['confirmPassword'].hasError('pattern')
              "
              class="alert alert-danger error-margin"
            >
              Confirm Password does not match
            </p>
          </div>
          <div *ngIf="messageError != null" class="alert alert-danger">{{messageError}}</div>
          <p tabindex="9" class="text-center mb-0">
            Have an account?
            <a routerLink="/login" class="text-theme">Sign In</a>
          </p>
        </form>
      </div>
    </ng-template>

      </div>
    </div>
  </div>
</div>
