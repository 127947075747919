import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../core/services/base/storage.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  public isCustomerAdmin: boolean;
  constructor(
    private svcStorage: StorageService
  ) { }

  ngOnInit(): void {
    const session = this.svcStorage.getCurrentSession();
    if(session){
      this.isCustomerAdmin = session.user.isCustomerAdmin;
    }
  }

}
