<!-- hero -->

<div class="hero inner">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-7 text-center">
        <h1
          class="wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
          "
        >
          How it Works
        </h1>
        <p
          class="wow fadeInUp"
          data-wow-delay=".4s"
          style="
            visibility: visible;
            animation-delay: 0.4s;
            animation-name: fadeInUp;
          "
        >
          The system is easy and straightforward. Follow these five easy steps
          to get your quote instantly
        </p>
      </div>
    </div>
  </div>
</div>

<div class="sec-pad">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-10 col-lg-8">
        <div
          class="htw-box wow fadeInUp"
          style="visibility: visible; animation-name: fadeInUp"
        >
          <div class="mtm-60">
            <div class="htw-box-circle fw-500">1</div>
          </div>
          <img
            src="./assets/img/how-it-works/select.png"
            class="img-fluid mt-4 mb-3"
            alt="img"
          />
          <p class="fw-500 ft-25 mb-2">Select Your Matter</p>
          <p class="mb-0">
            Start by choosing the type of matter you need to quote: Patents,
            Trademarks, Designs, Utility Models.
          </p>
        </div>

        <div
          class="htw-box wow fadeInUp"
          style="visibility: visible; animation-name: fadeInUp"
        >
          <div class="mtm-60">
            <div class="htw-box-circle fw-500">2</div>
          </div>
          <img
            src="./assets/img/how-it-works/australia.png"
            class="img-fluid mt-4 mb-3"
            alt="img"
          />
          <p class="fw-500 ft-25 mb-2">Select Your Countries</p>
          <p class="mb-0">
            Choose the countries where you need to get IP rights.
          </p>
        </div>

        <div
          class="htw-box wow fadeInUp"
          style="visibility: visible; animation-name: fadeInUp"
        >
          <div class="mtm-60">
            <div class="htw-box-circle fw-500">3</div>
          </div>
          <img
            src="./assets/img/how-it-works/resume.png"
            class="img-fluid mt-4 mb-3"
            alt="img"
          />
          <p class="fw-500 ft-25 mb-2">Insert Your Details</p>
          <p class="mb-0">Provide the details of your case.</p>
        </div>

        <div
          class="htw-box wow fadeInUp"
          style="visibility: visible; animation-name: fadeInUp"
        >
          <div class="mtm-60">
            <div class="htw-box-circle fw-500">4</div>
          </div>
          <img
            src="./assets/img/how-it-works/quotation.png"
            class="img-fluid mt-4 mb-3"
            alt="img"
          />
          <p class="fw-500 ft-25 mb-2">Pick Your Quote</p>
          <p class="mb-0">
            Choose the type of quote you need — average or personalized.
          </p>
        </div>

        <div
          class="htw-box wow fadeInUp"
          style="visibility: visible; animation-name: fadeInUp"
        >
          <div class="mtm-60">
            <div class="htw-box-circle fw-500">5</div>
          </div>
          <img
            src="./assets/img/how-it-works/estimation.png"
            class="img-fluid mt-4 mb-3"
            alt="img"
          />
          <p class="fw-500 ft-25 mb-2">Get your Estimate</p>
          <p class="mb-0">
            You will get an instant estimate specifying professional and
            official fees, translation costs, and more.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
