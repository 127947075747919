<div class="modal-body">
  <div class="alert alert-danger" *ngIf="messageError">{{ messageError }}</div>
  <h4 class="sec-title">{{ this.item.Name }} Plan</h4>
  <div *ngIf="session == null">
    <div class="button-subscribe">
      <button
        class="btn btn-theme mx-2 mb-4 mb-lg-0"
        (click)="redirectToSignIn()"
      >
        Sign in now!
      </button>
    </div>
  </div>
  <form
    *ngIf="session != null"
    [formGroup]="formPayment"
    (ngSubmit)="saveSubscription(formPayment)"
  >
    <div *ngIf="this.item.SubType == 1">
      <div class="col-md-12">
        <label class="bold">Number of quotes</label>
      </div>
      <div class="col-md-12">
        <input
          (keypress)="numberOnly($event)"
          type="text"
          class="form-control"
          formControlName="countQuote"
          [ngClass]="{
            'is-invalid':
              submitted &&
              formPayment.controls['countQuote'].hasError('required')
          }"
        />
        <p
          class="alert alert-danger"
          *ngIf="
            submitted && formPayment.controls['countQuote'].hasError('required')
          "
        >
          number of quotes is required.
        </p>
      </div>
    </div>
    <div *ngIf="this.item.SubType == 2">
      <div class="col-md-12">
        <label class="bold">Number of users</label>
      </div>
      <div class="col-md-12">
        <input
          min="1"
          (keypress)="numberOnly($event)"
          type="text"
          class="form-control"
          formControlName="countUser"
          [ngClass]="{
            'is-invalid': submitted && !formPayment.controls['countUser'].value
          }"
        />
        <p
          class="alert alert-danger"
          *ngIf="submitted && !formPayment.controls['countUser'].value"
        >
          number of users is required.
        </p>
      </div>
    </div>
    <div tabindex="1" class="mt-4 mb-4" *ngIf="this.item.SubType != 3">
      <div class="col-md-12">
        <label class="bold">Select payment type</label>
      </div>
      <select
        style="padding: 17px 35px 17px 25px"
        class="form-select"
        id="selectCountry"
        aria-label="Floating label select example"
        (change)="onChangeSelectPaymentType($any($event.target).value)"
      >
        <option
          *ngFor="let paymentType of paymentTypes"
          [value]="paymentType.Id"
        >
          {{ paymentType.Name }}
        </option>
      </select>
    </div>
    <div *ngIf="this.item.SubType != 3 && !this.onlyPaypal && showCardFields">
      <div class="col-md-12">
        <label class="bold">Card number</label>
      </div>
      <div class="col-md-12">
        <input
          id="cardNumberInput"
          (keypress)="numberOnly($event)"
          (paste)="onPaste($event)"
          [value]="cardNumber | mask: '0000 0000 0000 0000'"
          type="text"
          maxlength="19"
          class="form-control"
          formControlName="cardNumber"
          [ngClass]="{
            'is-invalid':
              submitted &&
              formPayment.controls['cardNumber'].hasError('required')
          }"
        />
        <p
          class="alert alert-danger"
          *ngIf="
            submitted &&
            formPayment.controls['cardNumber'].hasError('maxlength')
          "
        >
          card number cannot exceed 16 characters.
        </p>
        <p
          class="alert alert-danger"
          *ngIf="
            submitted &&
            formPayment.controls['cardNumber'].hasError('minlength')
          "
        >
          credit card must have 16 characters.
        </p>
        <p
          class="alert alert-danger"
          *ngIf="
            submitted && formPayment.controls['cardNumber'].hasError('required')
          "
        >
          card number is required.
        </p>
      </div>
      <div class="col-md-12">
        <label class="bold">Card name</label>
      </div>
      <div class="col-md-12">
        <input
          type="text"
          class="form-control"
          formControlName="cardName"
          [ngClass]="{
            'is-invalid':
              submitted && formPayment.controls['cardName'].hasError('required')
          }"
        />
        <p
          class="alert alert-danger"
          *ngIf="
            submitted && formPayment.controls['cardName'].hasError('required')
          "
        >
          card name is required.
        </p>
      </div>
      <div class="col-md-12">
        <label class="bold">Card secret code</label>
      </div>
      <div class="col-md-12">
        <input
          maxlength="4"
          (keypress)="numberOnly($event)"
          type="text"
          class="form-control"
          formControlName="cardSecretCode"
          [ngClass]="{
            'is-invalid':
              submitted &&
              formPayment.controls['cardSecretCode'].hasError('required')
          }"
        />
        <p
          class="alert alert-danger"
          *ngIf="
            submitted &&
            formPayment.controls['cardSecretCode'].hasError('required')
          "
        >
          secret code is required.
        </p>
        <p
          class="alert alert-danger"
          *ngIf="
            submitted &&
            formPayment.controls['cardSecretCode'].hasError('maxlength')
          "
        >
          secret code cannot exceed 4 characters.
        </p>
        <p
          class="alert alert-danger"
          *ngIf="
            submitted &&
            formPayment.controls['cardSecretCode'].hasError('minlength')
          "
        >
          secret code must have 3 characters.
        </p>
      </div>
      <div class="col-md-12">
        <label class="bold">Card expiration</label>
      </div>
      <div class="row col-md-12">
        <div class="col-md-4 float-left">
          <input
            maxlength="2"
            max="12"
            class="col-md-4"
            placeholder="MM"
            (keypress)="numberOnly($event)"
            (keyup)="numberOnly12($event)"
            type="text"
            class="form-control"
            formControlName="cardExpiration1"
            [ngClass]="{
              'is-invalid':
                submitted &&
                formPayment.controls['cardExpiration1'].hasError('required')
            }"
          />
          <p
            class="alert alert-danger"
            *ngIf="
              submitted &&
              formPayment.controls['cardExpiration1'].hasError('required')
            "
          >
            card expiration is required.
          </p>
          <p
            class="alert alert-danger"
            *ngIf="
              submitted &&
              formPayment.controls['cardExpiration1'].hasError('minlength')
            "
          >
            is required min length 2 characters.(MM)
          </p>
        </div>
        <div class="col-md-1 float-left"></div>
        <div class="col-md-4 float-left">
          <input
            maxlength="4"
            class="col-md-4"
            placeholder="YYYY"
            (keypress)="numberOnly($event)"
            type="text"
            class="form-control"
            formControlName="cardExpiration2"
            [ngClass]="{
              'is-invalid':
                submitted &&
                formPayment.controls['cardExpiration2'].hasError('required')
            }"
          />

          <p
            class="alert alert-danger"
            *ngIf="
              submitted &&
              formPayment.controls['cardExpiration2'].hasError('required')
            "
          >
            card expiration is required.
          </p>
          <p
            class="alert alert-danger"
            *ngIf="
              submitted &&
              formPayment.controls['cardExpiration2'].hasError('minlength')
            "
          >
            is required min length 4 characters.(YYYY)
          </p>
        </div>
      </div>
    </div>
    <hr>
    <div class="col-md-12">
      <label class="bold">Discount code</label>
    </div>
    <div class="col-md-12">
      <div class="input-group flex-nowrap">
        <input
          [disabled]="isLoading"
          type="text"
          class="form-control"
          (input)="isValidDiscountCode = null; discount = null;discountCodeMessageError=null;"
          aria-describedby="addon-wrapping"
          formControlName="discountCode"
        />
        <button
        type="button"
          [disabled]="isLoading"
          style="display: flex !important"
          class="input-group-text btn btn-animation"
          (click)="checkDiscount()"
          [tooltip]="getDiscountTooltip()"
          [ngClass]="{
            'btn-success': isValidDiscountCode,
            'btn-primary': isValidDiscountCode == null,
            'btn-danger': isValidDiscountCode == false
          }"
          id="addon-wrapping"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-check': isValidDiscountCode,
              'fa-search': isValidDiscountCode == null,
              'fa-times': isValidDiscountCode == false
            }"
          >
            <span *ngIf="isValidDiscountCode == null"></span>
          </i>
        </button>
      </div>
      <div class="col-12">
        <span *ngIf="discountCodeMessageError" class="text-danger mt-1">{{discountCodeMessageError}}</span>
      </div>
    </div>
    <div class="col-md-12">
      <br />
      <div class="alert alert-info">
        <div class="d-flex align-items-center">
          <span>
            Sub Total:
            <span
              [ngClass]="{
                'l-th': discount != null
              }"
              >{{ getSubTotal() | currency }}</span
            >
            <span *ngIf="discount!=null" class="total">
              {{ getTotal() | currency }}
            </span>
            </span
          >
          <span *ngIf="discount" class="discount"
            >{{ discount.DiscountPercentage / 100 | percent:'0.2-2' }} OFF</span
          >
        </div>
      </div>
    </div>
    <div class="button-subscribe">
      <button
        class="btn btn-theme mx-2 mb-4 mb-lg-0"
        type="submit"
        [disabled]="isLoading"
      >
        Subscribe
      </button>
    </div>
  </form>
</div>
