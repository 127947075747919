<div class="modal-bodypt-0">
    <div class="border-box-padding text-start">
        <div class="sec-title ft-25 text-start mb-2">
            Send Email
        </div>
        <p class="mb-4">
            Send Quote to...
            </p>
        <form class="pt-4" [formGroup]="formSendEmail" (ngSubmit)="sendEmail()">
            <div class="row">
                <div class="col col-12">
                    <div class="mb-4 form-group position-relative">
                        <label>Email</label>
                        <input type="text" name="" value="john_deo@pascalip.com" placeholder="Email" id="email"
                            class="form-control" formControlName="email">
                        <img src="./assets/img/icon/email.svg" class="img-fluid" alt="icon">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <button class="btn btn-theme w-100 br-8" type="submit" [disabled]="!formSendEmail.valid">Send</button>
              <p
                *ngIf="
                formSendEmail.controls['email'].hasError('required') &&
                formSendEmail.controls['email'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter email
              </p>
              <p
                *ngIf="
                formSendEmail.controls['email'].hasError('email') &&
                formSendEmail.controls['email'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter valid email
              </p>
              <p
                *ngIf="
                formSendEmail.controls['email'].hasError('pattern') &&
                formSendEmail.controls['email'].touched
                "
                class="alert alert-danger error-margin"
              >
                please enter valid email
              </p>
            </div>
            <div *ngIf="messageError != null" class="alert alert-danger">{{messageError}}</div>
        </form>
    </div>
</div>