import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Config {
  //PROXY --- See file proxy.conf.json
  // --- UAT ---
  //public URL = 'http://localhost:32723/';
  // --- PRODUCTION
  //public URL = 'http://localhost:4200/api/';
  public URL = 'https://pascalip.com/admin/api/';
  //---- DEV ----

  //public URL = 'http://srv8.pisobredos.com:1580/api/';

  //----- TEST  -----

  //   public URL = 'https://test/';

  //ENDPOINTS
  //[COUNTRY GUIDE ]
  public MATTER = 'frontEnd/matterFront/getFromCountryGuide';
  public JURIDICTION = 'frontEnd/jurisdictionFront/getJurisdictionByMatter';
  public SECTION = 'frontEnd/countryGuideFront/getSectionsByJurisdiction';
  public SUBSECTION =
    'frontEnd/countryGuideFront/getSubSectionsByJurisdictionAndSeccion';
  public COUNTRY_BODY = 'frontEnd/countryGuideFront/getCountryGuideBodyById';
    //[IP FIRMS DIRECTORY ]
  public JURIDICTION_ALL = 'frontEnd/jurisdictionFront/getAllJurisdiction';
  public GET_LAW_FIRMS_BY_JURISDICTION = 'frontend/lawFirmFront/getIpFirmsDirectoryByJurisdictionId';
  public GET_LAW_FIRMS_BY_ID_AND_CATEGORY = 'frontend/lawFirmFront/getIpFirmsDirectoryByLawFirmIdAndCategory';

  //[REGISTER]
  public REGISTER_USER = 'frontend/accountFront/registerUser';
  //[LOGIN]
  public LOGIN = 'frontend/accountFront/login';
  //[PRICE]
  public PRICE_SUBSCRIPTIONS =
    'frontend/subscriptionFront/getAllActivesWithMatterAndTariff';
  public PRICE_MATTERS = 'frontend/matterFront/getAllActives';
  public PRICE_TARIFFGROUPS = 'frontend/tariffGroupFront/getAll';
  public SUBSCRIPTION_PLAN = 'frontend/customerFront/associateSuscription';
  public PAYPAL_CAPTURE_ORDER = 'frontend/externalPayment/paypalCaptureOrder';
  public CHECK_DISCOUNT_CODE = 'frontEnd/subscriptionFront/checkDiscountCode';
  //[YOUR QUOTES]
  public ORDERS_BY_CUSTOMER = 'frontEnd/orderFront/getByCustomerId';
  public ORDERS_BY_CUSTOMER_COMPLETE = 'frontEnd/orderFront/getByCustomerIdComplete';
  //[LOGIN & SECURITY]
  public UPDATE_USER_INFORMATION = 'frontend/accountFront/updateUserInformation';
  public CHANGE_PASSWORD = 'frontend/accountFront/changePassword';
  public FORGOT_PASSWORD = 'frontend/accountFront/forgotPassword';
  public ACTIVE_USER = 'frontend/accountFront/activeUser';
  public IS_VALID_TOKEN = 'frontend/accountFront/isValidToken';
  //[YOUR PROFILE]
  public GET_USERS_BY_CUSTOMER = 'frontend/customerFront/getUsersByCustomer';
  public GET_CUSTOMER_BY_USER = 'frontend/customerFront/getCustomerByUser';
  public SAVE_USER = 'frontend/accountFront/saveUser';
  //[YOUR SUBSCRIPTION]
  public GET_CURRENT_SUBSCRIPTION = 'frontend/customerFront/getCurrentSubscription';
  //[YOUR PAYMENTs]
  public GET_PAYMENTS = 'frontend/accountFront/getPayments';
  //[OTHERS PAGES]
  //[STEP-FORMS]
  public SEND_ORDER_MAIL = 'frontend/orderFront/sendMail';
  public SEND_LAWFIRM_MAIL = 'frontend/orderFront/sendMailToLawFirm';
  //////[STEP-1]
  public STEP1_GET_MATTER_GROUPS = 'frontEnd/matterFron/getMatterGroups';
  public STEP1_GET_MATTERS_FROM_GROUP =
    'frontend/matterFront/getMattersFromMatterGroup';
  public STEP1_GET_DATA_PATENT_PCT = 'frontend/orderFront/getDataPatentPCT';
  //////[STEP-2]
  public STEP2_GET_JURIDICTION_BY_MATTER_FROM_ORDER =
    'frontend/jurisdictionFront/getJurisdictionByMatterFromOrder';
  //////[STEP-3]
  public STEP3_GET_CURRENCY = 'frontend/currencyFront/getAll';
  public STEP3_GET_LANGUAGE = 'frontEnd/languageFront/getAll';
  //////[STEP-4]
  public STEP4_GET_PARAMETERS_USERS_BY_MATTER =
    'frontend/matterFront/getParameterUsersByMatter';
  //////[STEP-5]
  public STEP5_GET_TARIFF_GROUP_BY_MATTER =
    'frontend/matterFront/getTariffGroupByMatter';
  public STEP5_GET_TIERS_OPTION = 'frontend/lawFirmFront/getTiersOptions';
  public STEPS_GET_LAW_FIRM_BY_ORDER_ID = 'frontEnd/orderFront/GetLawFirmByOrderId';

  public STEP5_GENERATE_ORDER = 'frontEnd/orderFront/generateOrder';
}
