import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MainVideoComponent } from './modal/main-video/main-video.component';
declare var $;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  private modal: BsModalRef; 
  constructor(
    private svcModal:BsModalService
  ) { }

  ngOnInit(): void {
  }

  openModal(){
    this.modal = this.svcModal.show(MainVideoComponent, {
      class: "modal-dialog-centered modal-lg"
    });
    this.modal.onHide.subscribe(value => {

    });
    $(".modal-content").css("border","0");
  }
}
