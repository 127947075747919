import { Component, OnInit } from '@angular/core';
import { PriceService } from '../../core/services/apis/price.service';
import { LoadingService } from '../../core/services/shared/loading.service';
import { MatterDTO } from '../../core/models/country-guideDTO';

@Component({
  selector: 'app-customer-services',
  templateUrl: './customer-services.component.html',
  styleUrls: ['./customer-services.component.css']
})
export class CustomerServicesComponent implements OnInit {
  public matters: MatterDTO[]
  constructor(
    private svcPrice: PriceService,
    private svcLoading: LoadingService
  ) { }

  ngOnInit(): void {
    this.svcLoading.show();
    this.svcPrice.getMatters().then(result => {
      this.svcLoading.hide();
      if(result.IsSucess){
        this.matters = result.ListData;
      }
    }).catch((error) => {
      this.svcLoading.hide();
      alert(JSON.stringify(error.error));
    });
  }
  onClickMatter(matter: MatterDTO){
    if(matter.LinkVideo == null){
      return;
    }
    window.open(matter.LinkVideo, "_blank");
  }
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

}
