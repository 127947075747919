import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from '../../core/services/apis/account.service';
import { LoadingService } from '../../core/services/shared/loading.service';

@Component({
  selector: 'app-active-user',
  templateUrl: './active-user.component.html',
  styleUrls: ['./active-user.component.css'],
})
export class ActiveUserComponent implements OnInit {
  alerTitle: string;
  alertBody: string;
  alertType: string;
  showAlert: boolean = null;
  constructor(
    private router: ActivatedRoute,
    private accountService: AccountService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.alerTitle = 'Account activated succesfully';
    this.alertBody = `Your account is activated. <a href='/#/login'>Please sign in.<a/>`;
    this.alertType = `info`;
    this.router.params.subscribe((params) => {
      const token = params.token;
      this.loadingService.show();
      this.accountService
        .activeUser({ Token: token })
        .then((result) => {
          if (result.IsSucess) {
          }else{
            this.alerTitle = 'Error in account activation!';
            this.alertBody = result.MessageError;
            this.alertType = "danger";
          }
          this.showAlert = true;
        })
        .catch((err) => alert(JSON.stringify(err.error)))
        .finally(() => this.loadingService.hide());
    });
  }
}
