<div class="header">
  <div class="container">
    <ngx-loading
      [show]="loadingStatus$ | async"
      [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }"
      style="z-index: 10000;"
    ></ngx-loading>
    <div class="row">
      <div class="col-12">
        <nav class="navbar navbar-expand-lg p-0">
          <div class="container-fluid">
            <a class="navbar-brand" routerLink="/main">
              <img
                src="./assets/img/logo.png"
                class="./assets/img-fluid"
                alt="logo"
              />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              id="ChangeToggle"
            >
              <span id="navbar-hamburger" class="show">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  fill="currentColor"
                  class="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  ></path>
                </svg>
              </span>
              <span id="navbar-close" class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                  ></path>
                </svg>
              </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    aria-current="page"
                    routerLinkActive="active"
                    routerLink="/main"
                    >Home</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLinkActive="active"
                    routerLink="/about"
                    >About</a
                  >
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    [ngClass]="{
                      active:
                        service.isActive ||
                        features.isActive ||
                        howItWorks.isActive ||
                        pascalForMe.isActive ||
                        ipKindsCovered.isActive ||
                        whyPascal.isActive
                    }"
                  >
                    More
                  </a>
                  <ul
                    class="dropdown-menu fade-up"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        routerLink="/services"
                        routerLinkActive="active"
                        #service="routerLinkActive"
                        >Our Services</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        routerLink="/features"
                        routerLinkActive="active"
                        #features="routerLinkActive"
                      >
                        Features</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        routerLinkActive="active"
                        routerLink="/how-it-works"
                        #howItWorks="routerLinkActive"
                        >How it works
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        routerLinkActive="active"
                        routerLink="/is-pascal-ip-for-me"
                        #pascalForMe="routerLinkActive"
                        >Is Pascal IP for me?
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        routerLinkActive="active"
                        routerLink="/ip-kinds-covered"
                        #ipKindsCovered="routerLinkActive"
                        >IP kinds covered
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        routerLinkActive="active"
                        routerLink="/why-pascal-ip"
                        #whyPascal="routerLinkActive"
                        >Why Pascal?
                      </a>
                    </li>
                    <li>
                      <a
                        *ngIf="validSubscription()"
                        class="dropdown-item"
                        routerLinkActive="active"
                        routerLink="/country-guide"
                        #countryGuide="routerLinkActive"
                        >Country Guide
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLinkActive="active"
                    routerLink="/price"
                    >Pricing</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLinkActive="active"
                    routerLink="/faq"
                    >FAQs</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLinkActive="active"
                    routerLink="/ip-firms-directory"
                    >IP Firms Directory</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLinkActive="active"
                    routerLink="/contact"
                    >Contact</a
                  >
                </li>
              </ul>
              <p *ngIf="loggedIn" style="margin-bottom: 0px">
                <a
                  class="nav-link"
                  routerLinkActive="active"
                  routerLink="/account"
                  >{{ userName }}</a
                >
                
              </p>
              <button
                *ngIf="!loggedIn"
                class="btn btn-theme mx-2 mb-4 mb-lg-0"
                href="#"
              >
                <a routerLink="/login">Sign In</a> /
                <a routerLink="/register">Register</a>
              </button>
              <button
                *ngIf="loggedIn"
                class="btn btn-theme mx-2 mb-4 mb-lg-0"
                href="#"
                (click)="this.svcLogin.logout()"
              >
                <a href="#">Logout</a>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</div>
