<!-- Login -->
<div class="sec-pad">

    <div class="container">
      <div class="row d-flex justify-content-center">
        <div
          class="col-md-10 col-lg-7 wow fadeInUp"
          style="visibility: visible; animation-name: fadeInUp"
        >
        <p
        *ngIf="successMessage!=null"
        class="alert alert-success"
        [innerText]="successMessage"
      >
      </p>
          <div class="login-box text-start">
            
            <div class="sec-title ft-25 text-start mb-2">
              Forgot password
            </div>
            <p class="mb-4">Enter your email to recover your password</p>
            <form class="pt-4" [formGroup]="formLogin" (ngSubmit)="onSubmit()">
              <div class="mb-4 form-group position-relative">
                <label>Email</label>
                <input
                  formControlName="email"
                  type="email"
                  name="email"
                  placeholder="example@mail.com"
                  id="email"
                  class="form-control"
                />
                <img
                  src="./assets/img/icon/at.svg"
                  class="img-fluid"
                  alt="icon"
                />
              </div>
  
              <div class="mb-3">
                <button
                  class="btn btn-theme w-100 br-8"
                  type="submit"
                  [disabled]="!formLogin.valid"
                >
                  Send Email
                </button>
                <p
                  *ngIf="
                    formLogin.controls['email'].hasError('required') &&
                    formLogin.controls['email'].touched
                  "
                  class="alert alert-danger error-margin"
                >
                  please enter email
                </p>
                <p
                  *ngIf="
                    formLogin.controls['email'].hasError('pattern') &&
                    formLogin.controls['email'].touched
                  "
                  class="alert alert-danger error-margin"
                >
                  please enter valid email
                </p>
  
                <p *ngIf="errorLogin" class="alert alert-danger error-margin">
                  {{ errorLogin }}
                </p>
              </div>
              <p class="text-center mb-0">
                Or <a [routerLink]="['/login']" class="text-theme"> Sign In</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  