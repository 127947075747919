import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  @Input() type: string = "info";
  @Input() title: string;
  @Input() body: string;
  @Input() timeOut: number;
  show: boolean = true;
  constructor() { }

  ngOnInit(): void {
    if(this.timeOut){
      setTimeout(() => {
        this.show = false;
      }, this.timeOut);
    }
  }

  get iconByType(){
    switch(this.type){
      case "info": return "fa fa-check";
      case "warning": return "fa fa-warning";
      case "danger": return "fa fa-warning";
      case "success": return "fa fa-check";
    }
  }

  get textColor(){
    switch(this.type){
      case "info": return "alert-custom-color";
      case "warning": return "text-warning";
      case "danger": return "text-danger";
      case "success": return "text-success";
    }
  }
}
