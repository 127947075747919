<!-- Form Step -->
<div class="sec-pad form-step-box">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="px-0 mb-3">
          <!-- steps -->
          <form id="form" class="mt-0">
            <ul id="progressbar" class="arrow-steps">
              <li class="active step" id="step1">
                <div class="fw-500 ft-21 step-num">1</div>
                <div class="ft-21 step-right"><i class="fa fa-check"></i></div>
                <span> Pick the IP Type</span>
              </li>
              <li id="step2" class="step">
                <div class="fw-500 ft-21 step-num">2</div>
                <div class="ft-21 step-right"><i class="fa fa-check"></i></div>
                <span>Pick the countries</span>
              </li>
              <li id="step3" class="step">
                <div class="fw-500 ft-21 step-num">3</div>
                <div class="ft-21 step-right"><i class="fa fa-check"></i></div>
                <span>Pick your Currency and Language</span>
              </li>
              <li id="step4" class="step">
                <div class="fw-500 ft-21 step-num">4</div>
                <div class="ft-21 step-right"><i class="fa fa-check"></i></div>
                <span
                  >Insert the particulars <br />
                  of your case</span
                >
              </li>
              <li id="step5" class="step">
                <div class="fw-500 ft-21 step-num">5</div>
                <div class="ft-21 step-right"><i class="fa fa-check"></i></div>
                <span>Get your estimate</span>
              </li>
            </ul>
            <fieldset class="fieldset1">
              <div>
                <div class="sec-title text-center">Pick the IP Type</div>
                <div class="sec-line ms-auto me-auto mb-4"></div>
              </div>
              <!-- step 1.1 -->
              <div id="step_1_1">
                <p class="mb-4">
                  Choose the IP Type you need an estimate for. Select from the
                  available options to begin the process.
                </p>
                <div class="pt-4">
                  <label
                    class="container2"
                    [ngClass]="{ 'op-5': isDisabledMatterGroup(matterGroup) }"
                    *ngFor="let matterGroup of listMatterGroup"
                    >{{ matterGroup.Name }}
                    <input
                      tabindex="0"
                      type="radio"
                      [value]="matterGroup.Id"
                      name="matterGroup"
                      (change)="onChangeMatterGroup($any($event.target).value)"
                      [disabled]="isDisabledMatterGroup(matterGroup)"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div id="matterGroupError" class="d-none">
                    <p class="text-danger pt-4">Select option</p>
                  </div>
                </div>
              </div>
              <!-- step 1.2 -->
              <div id="step_1_2">
                <p class="mb-4">
                  Choose the type of Patent you need an estimate for. Select
                  from the available options to continue the process
                </p>
                <div class="pt-4">
                  <div class="row d-flex justify-content-center">
                    <div class="col-md-8 col-lg-5">
                      <label
                        class="container2 d-flex"
                        *ngFor="let matter of listMatter"
                        [ngClass]="{ 'op-5': isDisabledMatter(matter) }"
                        >{{ matter.Name }}
                        <input
                          tabindex="0"
                          type="radio"
                          [value]="matter.Id"
                          [disabled]="isDisabledMatter(matter)"
                          name="matters"
                          (change)="
                            onChangeMatter(
                              $any($event.target).value,
                              matter.InputPatentNumber
                            )
                          "
                        />
                        <span class="checkmark"></span>
                      </label>
                      <div id="matterError" class="d-none">
                        <p class="text-danger pt-4">Select option</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- step 1.3 -->
              <div id="step_1_3" style="display: none" class="mb-5">
                <p class="mb-4">
                  If your PCT International case has already been published,
                  please insert here the PCT International Application Number:
                </p>
                <p class="mb-0">
                  PCT/ &nbsp;<input
                    type="text"
                    placeholder="Ex: US2022"
                    class="input-fc w-150 me-2"
                    name=""
                    id="pct"
                    pattern="[A-Za-z]{2}[0-9]{4}"
                    maxlength="6"
                  />
                  <input
                    type="text"
                    placeholder="012345"
                    class="input-fc w-95px me-2"
                    name=""
                    id="pctAdditional"
                    pattern="[0-9]{6}"
                    maxlength="6"
                    onkeypress="if ( isNaN( String.fromCharCode(event.keyCode) )) return false;"
                  />
                </p>
                <div id="pctError">
                  <p class="text-danger pt-4">
                    Each PCT serial number has three parts. The first one is the
                    word PCT, the second one is the country code and the 4-digit
                    filing year, the last part is a six-digit number. Do not
                    forget to include zeros (if any).
                  </p>
                  <!-- If a digit is missed, an error message will be received -->
                </div>
                <!-- <p class="text-end mb-0">
                  If you do not know the PCT application serial number,
                </p> -->
              </div>
              <div id="step_1_4" class="mt-2">
                <div class="row">
                  <div class="col-4"></div>
                  <div class="col col-4 form-group position-relative">
                    <div tabindex="1" class="form-floating mt-3">
                      <select
                        class="form-select"
                        id="selectCountryIP"
                        aria-label="Floating label select example"
                        (change)="onChangeTypeOfApplicant($any($event.target).value)"
                      >
                        <option selected="" value="0">Indistinct</option>
                        <option value="1">Individual</option>
                        <option value="2">Company</option>
                      </select>
                      <label for="selectCountryIP">Select type of applicant</label>
                    </div>
                  </div>
                  <div class="col-4"></div>
                </div>
                <div class="row">
                  <div class="col col-6 form-group position-relative">
                    <label>Client Name</label>
                    <input
                      type="text"
                      name=""
                      id="clientName"
                      class="form-control"
                      placeholder="Joe Doe"
                    />
                  </div>
                  <div class="col col-6 form-group position-relative">
                    <label>Reference</label>
                    <input
                      type="text"
                      name=""
                      id="reference"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="pt-4"></div>
              <div class="d-flex flex-row-reverse">
                <input
                  type="button"
                  id="first-step-next-btn"
                  name="next-step"
                  class="next-step form-btn"
                  value="Next"
                  id="next-step1"
                  (click)="clickNextStep1(' paso 1')"
                />
                <span id="pctLabelNextButton" class="d-none"
                  >If you do not know the PCT application serial number, press
                  next button.</span
                >
              </div>

              <input
                class="d-none"
                id="cancelNext"
                name="next-step"
                [attr.data-cancelNext]="cancelNext"
              />
            </fieldset>
            <fieldset>
              <div>
                <div class="sec-title text-center">Pick the Countries</div>
                <div class="sec-line ms-auto me-auto mb-4"></div>
              </div>
              <!-- step 2.1 -->
              <div class="text-start">
                <p class="mb-4">Select Countries you are interested in:</p>
                <label class="mb-3">Select Mode</label>
                <div class="">
                  <label class="container2"
                    >By Name
                    <input
                      type="radio"
                      name="mode"
                      (change)="onChangeByNameOption($any($event.target).value)"
                      checked="true"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="container2"
                    >By Continent
                    <input
                      type="radio"
                      name="mode"
                      (change)="
                        onChangeByContinentOption($any($event.target).value)
                      "
                    />
                    <span class="checkmark"></span>
                  </label>
                  <!--  <label class="container2">BY GDP
                          <input type="radio"  name="radio">
                          <span class="checkmark"></span>
                          </label> -->
                  <label class="container2"
                    >Favourite Countries
                    <input
                      type="radio"
                      name="mode"
                      (change)="
                        onChangeByFavouriteOption($any($event.target).value)
                      "
                    />

                    <span class="checkmark"></span>
                  </label>
                  <div id="modeError" class="d-none">
                    <p class="text-danger pt-4">Select option</p>
                  </div>
                </div>
                <hr />

                <label class="container1 d-block"
                  >Check All
                  <!-- <input type="checkbox" checked="checked" /> -->
                  <input id="checkAll" type="checkbox" />
                  <span class="checkmark"></span>
                </label>
                <div id="jurisdictionsChecks">
                  <label
                    *ngIf="this.listRegionalAgreements.length != 0"
                    class="mb-4 mt-3"
                    style="display: block"
                    >Regional Agreements</label
                  >

                  <div
                    class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 row-cols-xxl-5 mt-0"
                  >
                    <div
                      *ngFor="let item of this.listRegionalAgreements"
                      class="col"
                    >
                      <label class="container1"
                        >{{ item.NameEn }}
                        <input
                          type="checkbox"
                          [value]="item.Id"
                          name="jurisdictions"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>

                  <label *ngIf="showByName || showFavorite" class="mb-4 mt-3"
                    >Countries</label
                  >
                  <div
                    *ngIf="showByName"
                    class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 row-cols-xxl-5 mt-0"
                  >
                    <div *ngFor="let item of this.listJurisdiction" class="col">
                      <label class="container1"
                        >{{ item.NameEn }}
                        <input
                          id="{{ item.NameEn }}"
                          [attr.data-id]="item.Id"
                          [attr.data-nameen]="item.NameEn"
                          [attr.data-continent]="item.Continent"
                          [attr.data-isfavority]="item.IsFavority"
                          type="checkbox"
                          [value]="item.Id"
                          [checked]="showFavorite && item.IsFavority"
                          name="jurisdictions"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div
                    *ngIf="showFavorite"
                    class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 row-cols-xxl-5 mt-0"
                  >
                    <div *ngFor="let item of this.listJurisdiction" class="col">
                      <label class="container1"
                        >{{ item.NameEn }}
                        <input
                          id="{{ item.NameEn }}"
                          [attr.data-id]="item.Id"
                          [attr.data-nameen]="item.NameEn"
                          [attr.data-continent]="item.Continent"
                          [attr.data-isfavority]="item.IsFavority"
                          type="checkbox"
                          [value]="item.Id"
                          [checked]="item.IsFavority"
                          name="jurisdictions"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="showByContinents">
                    <label
                      *ngIf="this.listEurope?.length != 0"
                      class="mb-4 mt-3"
                      style="display: block"
                      >Europe</label
                    >

                    <div
                      class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 row-cols-xxl-5 mt-0"
                    >
                      <div *ngFor="let item of this.listEurope" class="col">
                        <label class="container1"
                          >{{ item.NameEn }}
                          <input
                            type="checkbox"
                            [value]="item.Id"
                            [checked]="showFavorite && item.IsFavority"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <label
                      *ngIf="this.listAsia.length != 0"
                      class="mb-4 mt-3"
                      style="display: block"
                      >Asia</label
                    >

                    <div
                      class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 row-cols-xxl-5 mt-0"
                    >
                      <div *ngFor="let item of this.listAsia" class="col">
                        <label class="container1"
                          >{{ item.NameEn }}
                          <input
                            type="checkbox"
                            [value]="item.Id"
                            [checked]="showFavorite && item.IsFavority"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <label
                      *ngIf="this.listAmerica.length != 0"
                      class="mb-4 mt-3"
                      style="display: block"
                      >America</label
                    >

                    <div
                      class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 row-cols-xxl-5 mt-0"
                    >
                      <div *ngFor="let item of this.listAmerica" class="col">
                        <label class="container1"
                          >{{ item.NameEn }}
                          <input
                            type="checkbox"
                            [value]="item.Id"
                            [checked]="showFavorite && item.IsFavority"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <label
                      *ngIf="this.listAfrica.length != 0"
                      class="mb-4 mt-3"
                      style="display: block"
                      >Africa</label
                    >

                    <div
                      class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 row-cols-xxl-5 mt-0"
                    >
                      <div *ngFor="let item of this.listAfrica" class="col">
                        <label class="container1"
                          >{{ item.NameEn }}
                          <input
                            type="checkbox"
                            [value]="item.Id"
                            [checked]="showFavorite && item.IsFavority"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <label
                      *ngIf="this.listAustralia.length != 0"
                      class="mb-4 mt-3"
                      style="display: block"
                      >Australia</label
                    >

                    <div
                      class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 row-cols-xxl-5 mt-0"
                    >
                      <div *ngFor="let item of this.listAustralia" class="col">
                        <label class="container1"
                          >{{ item.NameEn }}
                          <input
                            type="checkbox"
                            [value]="item.Id"
                            [checked]="showFavorite && item.IsFavority"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div id="jurisdictionsError" class="d-none">
                    <p class="text-danger pt-4">Select option</p>
                  </div>
                </div>
              </div>
              <div class="pt-4"></div>
              <input
                type="button"
                name="next-step"
                class="next-step form-btn"
                value="Next"
                (click)="clickNextStep2(' paso 2')"
              />
              <input
                type="button"
                name="previous-step"
                class="previous-step form-btn1"
                value="Previous"
              />
            </fieldset>
            <fieldset>
              <div>
                <div class="sec-title text-center">Pick your Currency</div>
                <div class="sec-line ms-auto me-auto mb-4"></div>
              </div>
              <!-- step 3.1 -->
              <div class="text-start">
                <p class="mb-4">Select your Currency</p>
                <label class="mb-4 mt-2">Currency</label>
                <div class="form-floating">
                  <select
                    class="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    (change)="onChangeSelectCurrency($any($event.target).value)"
                  >
                    <!-- <option selected>U.S. Dollar</option> -->
                    <option
                      *ngFor="let currency of listCurrency"
                      [value]="currency.Id"
                    >
                      {{ currency.Name }}
                    </option>
                  </select>
                  <label for="floatingSelect">Select Currency</label>
                </div>
              </div>
              <div>
                <div class="sec-title text-center">Pick your Language</div>
                <div class="sec-line ms-auto me-auto mb-4"></div>
              </div>
              <!-- step 3.2 -->
              <div class="text-start">
                <p class="mb-4">Select your Language</p>
                <label class="mb-4 mt-2">Language</label>
                <div class="form-floating">
                  <select
                  [(ngModel)]="PCTLanguageId"
                  [ngModelOptions]="{standalone: true}"
                    class="form-select"
                    id="floatingSelect2"
                    aria-label="Floating label select example"
                    (change)="onChangeSelectLanguage($any($event.target).value)"
                  >
                    <option
                      *ngFor="let language of listLanguage"
                      [value]="language.Id"
                      [ngValue]="language.Id"
                    >
                      {{ language.Name }}
                    </option>
                  </select>
                  <label for="floatingSelect2">Select Language</label>
                  <small *ngIf="PCTLanguage != null" class="mb-4">Language from WIPO: {{ PCTLanguage }}</small>
                </div>
              </div>
              <div class="pt-4"></div>
              <input
                type="button"
                name="next-step"
                class="next-step form-btn"
                value="Next"
                (click)="clickNextStep3(' paso 3')"
              />
              <input
                type="button"
                name="previous-step"
                class="previous-step form-btn1"
                value="Previous "
              />
            </fieldset>
            <!-- step 4.1 -->
            <fieldset id="fourthStep">
              <div>
                <div class="sec-title text-center">
                  Insert the particular information of your case
                </div>
                <div class="sec-line ms-auto me-auto mb-4"></div>
              </div>
              <p class="mb-4 text-start">
                Please insert the information required to make the estimate you
                need
              </p>
              <div class="text-start">
                <div class="row">
                  <div
                    *ngFor="let item of listInputs"
                    class="{{
                      item.SiteUseRowComplete ? 'col-md-12' : 'col-md-6'
                    }}"
                  >
                    <form
                      [formGroup]="paramUserForm"
                      *ngIf="paramUserForm.controls[item.Id] != null"
                    >
                      <div class="form-floating1 mb-2 mt-4">
                        <input
                          (keypress)="
                            onKeyPressTiers(
                              $event.keyCode,
                              item.SiteIsFormatNumber
                            )
                          "
                          [value]="item.Value"
                          id="{{ item.Id }}"
                          formControlName="{{ item.Id }}"
                          [attr.data-message-id]="item.Id"
                          [attr.data-required]="item.SiteIsRequired"
                          type="{{ item.SiteIsFormatDate ? 'date' : 'text' }}"
                          name=""
                          class="form-control"
                          [ngClass]="
                            paramUserForm.controls[item.Id].touched
                              ? !paramUserForm.controls[item.Id].valid
                                ? 'is-invalid'
                                : 'is-valid'
                              : 'is-valid'
                          "
                          placeholder="{{ item.SitePlaceHolder }}"
                          [attr.data-code]="item.Code"
                          [attr.data-name]="item.Name"
                          [attr.data-SiteLabel]="item.SiteLabel"
                          [attr.data-SitePlaceHolder]="item.SitePlaceHolder"
                          [attr.data-SiteIsFormatDate]="item.SiteIsFormatDate"
                          [attr.data-SiteIsFormatNumber]="
                            item.SiteIsFormatNumber
                          "
                          [attr.data-SiteUseInFormule]="item.SiteUseInFormule"
                          [attr.data-SiteUseRowComplete]="
                            item.SiteUseRowComplete
                          "
                          [attr.data-SiteOrder]="item.SiteOrder"
                          [attr.data-SiteIsRequired]="item.SiteIsRequired"
                        />
                        <label for="floatingSelect">{{ item.SiteLabel }}</label>
                        <div *ngIf="item.SiteIsRequired">
                          <!-- <div *ngIf="checkForError(item)"> -->
                          <!-- should be at least 8 characters. -->
                          (*) Is required
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="pt-4"></div>
              <input
                id="revise-parameter-button"
                type="button"
                name="next-step"
                class="next-step form-btn"
                value="Next"
                (click)="clickNextStep4(' paso 4')"
                [disabled]="!paramUserForm.valid"
                [style.background]="
                  !paramUserForm.valid ? '#858484' : '#0ab4ff'
                "
                style="display: none"
              />
              <input
                id="next-step-4"
                type="button"
                name="next-step"
                class="next-step form-btn"
                value="Next"
                (click)="clickNextStep4(' paso 4')"
                [disabled]="!paramUserForm.valid"
                [style.background]="
                  !paramUserForm.valid ? '#858484' : '#0ab4ff'
                "
              />

              <input
                id="previous-step-4"
                type="button"
                name="previous-step"
                class="previous-step form-btn1"
                value="Previous "
              />
            </fieldset>
            <fieldset id="lastStep">
              <div>
                <div class="sec-title text-center">
                  Insert the particulars of this case
                </div>
                <div class="sec-line ms-auto me-auto mb-4"></div>
              </div>
              <!-- step 5.1 -->
              <div>
                <p class="mb-4">
                  Our estimates cover all the steps in the life of your
                  registration, from filing to issuance, maintenance, and
                  assignment. Please pick the step you need or just click on
                  ALL.
                </p>
                <div class="pt-4">
                  <div class="row d-flex justify-content-center">
                    <div class="col-md-6 col-lg-4 text-start">
                      <!-- <label class="container2 ">All
                                <input type="radio" checked="checked" name="radio">
                                <span class="checkmark"></span>
                                </label> -->

                      <label
                        *ngFor="let item of listTariffGroupByMatter"
                        class="container1 d-block"
                        [ngClass]="{ 'op-5': isDisabledTariffGroup(item) }"
                        >{{ item.TariffGroup.Name }}
                        <input
                          name="tariffName"
                          type="checkbox"
                          [value]="item.TariffGroupId"
                          [checked]="
                            !item.Disabled && !isDisabledTariffGroup(item)
                          "
                          [disabled]="
                            item.Disabled || isDisabledTariffGroup(item)
                          "
                        />
                        <span class="checkmark"></span>
                      </label>
                      <div id="tarrifError" class="d-none">
                        <p class="text-danger pt-4">
                          Select at least one tariff.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="mb-2 mt-4">
                  Also pick the type of IP Law Firm you would like to work with
                </p>
                <div class="pt-4">
                  <div class="row d-flex justify-content-center">
                    <div class="col-md-12 col-lg-11">
                      <label
                        class="container2"
                        *ngFor="let tier of listTierOptions"
                        >{{ tier.Name }}
                        <input
                          tabindex="0"
                          type="radio"
                          [value]="tier.Id"
                          [disabled]="
                            !session.user.subscription.AllowTearSelect
                          "
                          [checked]="tier.Selected"
                          name="radio2"
                          (change)="
                            onChangeTierOption(
                              $any($event.target).value,
                              tier.Name
                            )
                          "
                        />
                        <span class="checkmark"></span>
                      </label>
                      <div id="tierError" class="d-none">
                        <p class="text-danger pt-4">Select option</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- step 5.2 -->
              <input
                type="button"
                name="next-step"
                class="next-step form-btn"
                value="Finish"
                id="finish"
                (click)="clickFinishStep5('finish')"
              />
              <input
                type="button"
                name="previous-step"
                class="previous-step form-btn1"
                value="Previous "
              />
            </fieldset>
          </form>
          <!-- result 1 -->
          <div class="" id="result">
            <alert type="success" [dismissible]="true" *ngIf="isEmailSend"
              >Email sent successfully!</alert
            >
            <div>
              <div class="sec-title text-center">{{ matterName }}</div>
              <div class="sec-line ms-auto me-auto mb-4"></div>
            </div>
            <div class="d-flex justify-content-between">
              <button class="form-btn" (click)="reloadCurrentRoute()">
                Get a New Quote
              </button>
              <div>
                <button class="form-btn" (click)="sendMail()">Send Mail</button>
                <button class="form-btn" (click)="downloadPDF()">
                  Download PDF
                </button>
              </div>
            </div>
            <div class="result-box mt-4">
              <div class="rb-header fw-500">
                Cost Estimate for {{ this.tierTitle }}
              </div>

              <div class="result-body">
                <div
                  *ngFor="let itemEstimate of listOtherParams"
                  class="result-li"
                >
                  <div class="row">
                    <div class="col-lg-5">
                      {{ itemEstimate.SiteLabel }}
                    </div>
                    <div class="col-lg-2 text-start text-lg-center">
                      <i class="fa fa-long-arrow-right"></i>
                    </div>
                    <div class="col-lg-5 text-theme">
                      {{ itemEstimate.Value }}
                    </div>
                  </div>
                </div>
                <div
                  *ngFor="let itemEstimate of listOrders?.Data?.OrderParameters"
                  class="result-li"
                >
                  <div class="row">
                    <div class="col-lg-5">
                      {{ itemEstimate.ParameterUser.SiteLabel }}
                    </div>
                    <div class="col-lg-2 text-start text-lg-center">
                      <i class="fa fa-long-arrow-right"></i>
                    </div>
                    <div
                      class="col-lg-5 text-theme"
                      *ngIf="
                        itemEstimate.ParameterUser.SiteIsFormatDate &&
                        !itemEstimate.ParameterUser.SiteIsCalculated
                      "
                    >
                      {{ itemEstimate.Value | date : "MM/dd/yyyy" }}
                    </div>
                    <div
                      class="col-lg-5 text-theme"
                      *ngIf="
                        !itemEstimate.ParameterUser.SiteIsFormatDate ||
                        itemEstimate.ParameterUser.SiteIsCalculated
                      "
                    >
                      {{ itemEstimate.Value }}
                    </div>
                  </div>
                </div>
                <!-- <div
                  *ngFor="let itemEstimate of listEstimate"
                  class="result-li"
                >
                  <div class="row">
                    <div class="col-lg-5">
                      {{ itemEstimate.ParameterUser.SiteLabel }}
                    </div>
                    <div class="col-lg-2 text-start text-lg-center">
                      <i class="fa fa-long-arrow-right"></i>
                    </div>
                    <div class="col-lg-5 text-theme">
                      {{ itemEstimate.Value }}
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="text-center mt-4">
                <button class="form-btn float-none" (click)="backToStepFour()">
                  Revise Perameters
                </button>
                <button
                  *ngIf="tierOption != 1"
                  class="form-btn float-none"
                  (click)="getLawFirmInformation()"
                >
                  Law Firms Information
                </button>
              </div>

              <div class="result-table-box">
                <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-compact-view-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-compact-view"
                      type="button"
                      role="tab"
                      aria-controls="pills-compact-view"
                      aria-selected="true"
                    >
                      Compact View
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-detailed-view-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-detailed-view"
                      type="button"
                      role="tab"
                      aria-controls="pills-detailed-view"
                      aria-selected="false"
                    >
                      Detailed View
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-compact-view"
                    role="tabpanel"
                    aria-labelledby="pills-compact-view-tab"
                  >
                    <p class="text-theme">
                      <!-- PCT National Phase -->
                      {{ listOrders?.Data.MatterName }} Application Costs
                    </p>
                    <div class="table-responsive">
                      <ng-container
                        *ngIf="listOrders != null; else elseTemplate"
                      >
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Country</th>
                              <th
                                *ngFor="
                                  let item of listOrders?.Data.ResumePrice
                                    .Headers
                                "
                                scope="col"
                              >
                                {{ item }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let jurisdictionItem of listOrders?.Data
                                  .ResumePrice.Jurisdictions;
                                let i = index
                              "
                            >
                              <td data-label="Country">
                                {{ jurisdictionItem }}
                              </td>
                              <td
                                *ngFor="
                                  let rowItem of listOrders?.Data.ResumePrice
                                    .Rows[i]
                                "
                                data-label="Examination"
                              >
                                <span>
                                  $ {{ rowItem | number : "1.2-2" }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="text-end fw-500 p-3">
                          Total Cost({{ listOrders?.Data.CurrencyName }})
                          <span class="ms-3"
                            >${{
                              listOrders?.Data.TotalPrice | number : "1.2-2"
                            }}</span
                          >
                        </div>
                      </ng-container>
                      <ng-template #elseTemplate>
                        <table class="table simple-table text-start">
                          <tbody>
                            <tr
                              *ngFor="
                                let i of this.helperService.generateFake(2)
                              "
                            >
                              <td>
                                <ngx-skeleton-loader
                                  [theme]="{ width: '100%' }"
                                ></ngx-skeleton-loader>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-template>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-detailed-view"
                    role="tabpanel"
                    aria-labelledby="pills-detailed-view-tab"
                  >
                    <div
                      *ngFor="
                        let detail of listOrders?.Data.DetailPrices;
                        let i = index
                      "
                    >
                      <p class="text-theme">{{ detail.TariffName }}</p>
                      <div class="table-responsive">
                        <div *ngIf="detail.ResumePrice.Headers.length >= 10">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Country</th>
                                <th
                                  *ngFor="
                                    let resumeHeaders of detail.ResumePrice
                                      .Headers;
                                    let i = index
                                  "
                                  scope="col"
                                  [ngClass]="{ 'd-none': i >= 10 }"
                                >
                                  {{ resumeHeaders }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let jurisdictionItem of detail.ResumePrice
                                    .Jurisdictions;
                                  let i = index
                                "
                              >
                                <td data-label="Country">
                                  {{ jurisdictionItem }}
                                </td>
                                <td
                                  *ngFor="
                                    let rowItem of detail.ResumePrice.Rows[i];
                                    let i = index
                                  "
                                  data-label="Professional Fees"
                                  [ngClass]="{ 'd-none': i >= 10 }"
                                >
                                  $ {{ rowItem | number : "1.2-2" }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="table mt-3">
                            <thead>
                              <tr>
                                <th scope="col">Country</th>
                                <th
                                  *ngFor="
                                    let resumeHeaders of detail.ResumePrice
                                      .Headers;
                                    let i = index
                                  "
                                  scope="col"
                                  [ngClass]="{ 'd-none': i < 10 }"
                                >
                                  {{ resumeHeaders }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let jurisdictionItem of detail.ResumePrice
                                    .Jurisdictions;
                                  let i = index
                                "
                              >
                                <td data-label="Country">
                                  {{ jurisdictionItem }}
                                </td>
                                <td
                                  *ngFor="
                                    let rowItem of detail.ResumePrice.Rows[i];
                                    let i = index
                                  "
                                  [ngClass]="{ 'd-none': i < 10 }"
                                  data-label="Professional Fees"
                                >
                                  $ {{ rowItem | number : "1.2-2" }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div *ngIf="detail.ResumePrice.Headers.length < 10">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Country</th>
                                <th
                                  *ngFor="
                                    let resumeHeaders of detail.ResumePrice
                                      .Headers
                                  "
                                  scope="col"
                                >
                                  {{ resumeHeaders }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let jurisdictionItem of detail.ResumePrice
                                    .Jurisdictions;
                                  let i = index
                                "
                              >
                                <td data-label="Country">
                                  {{ jurisdictionItem }}
                                </td>
                                <td
                                  *ngFor="
                                    let rowItem of detail.ResumePrice.Rows[i]
                                  "
                                  data-label="Professional Fees"
                                >
                                  $ {{ rowItem | number : "1.2-2" }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="text-end fw-500 p-3">
                        Total Cost({{ listOrders?.Data.CurrencyName }})
                        <span class="ms-3"
                          >$ {{ detail.TotalPrice | number : "1.2-2" }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="position: absolute; top: -2000px; left: -2000px" *ngIf="orderId">
  <app-generate-order-pdf
    [orderId]="orderId"
    (finishDownload)="orderId = null"
  ></app-generate-order-pdf>
</div>
