import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { HelperService } from 'src/app/core/services/shared/helper.service';
import { LoadingService } from 'src/app/core/services/shared/loading.service';
import { ApiResultListData } from '../../core/models/base/ApiResult';
import { PriceService } from '../../core/services/apis/price.service';
import { PaymentSubscriptionComponent } from './modal/payment-subscription/payment-subscription.component';
import { StorageService } from '../../core/services/base/storage.service';
import { PaypalCaptureRequestDTO } from 'src/app/core/models/customerSubscriptionDTO';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css'],
})
export class PriceComponent implements OnInit {
  public listSubscription: any;
  public listMatters: any;
  public listTariffs: any;
  public listSubTypePay = [
    { subType: 1, text: 'Per quote' },
    { subType: 2, text: 'month/user' },
    { subType: 3, text: '' },
  ];
  listSubTypeButton = [
    { subType: 1, text: 'Get Plus' },
    { subType: 2, text: 'Get Pro' },
    { subType: 3, text: 'Get Started for Free' },
  ];
  public messageSuccess: string;

  bsModalRef: BsModalRef;
  loadingStatus$: Observable<boolean>;
  constructor(
    private srvPrice: PriceService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private svcLoading: LoadingService,
    private helperService: HelperService,
    private svcStorage: StorageService,
    private router: Router,
    private scroller: ViewportScroller,
  ) {}

  openModalWithComponent(item: any) {
    const initialState = {
      item: item,
    };
    this.bsModalRef = this.modalService.show(PaymentSubscriptionComponent, {
      initialState: initialState,
      class: 'modal-dialog-centered',
    });

    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.event.subscribe(res => {
      this.messageSuccess = res;
      window.scroll(0,0);
    })
  }
  ngOnInit(): void {
    this.initProgress();
    this.svcLoading.show();
    // this.getSubscription();
    this.srvPrice.getSubscription().then((result: ApiResultListData) => {
      this.listSubscription = result.ListData;
      this.helperService.sortByOrder(this.listSubscription);
      this.scroller.scrollToAnchor("choose-your-plan");
      this.svcLoading.hide();
    });

    this.srvPrice.getMatters().then((result: ApiResultListData) => {
      this.listMatters = result.ListData;
    });
    this.srvPrice.getTariffs().then((result: ApiResultListData) => {
      this.listTariffs = result.ListData;
    });
  }

  // getSubscription(): void {
  //   this.listSubscription = this.route.snapshot.data.profile.ListData;
  // }
  initProgress(): void {
    this.loadingStatus$ = this.svcLoading.listenLoading();
  }
  getTextPay(item) {
    return this.listSubTypePay.find((i) => i.subType === item.SubType).text;
  }
  getTextButton(item) {
    return this.listSubTypeButton.find((i) => i.subType === item.SubType).text;
  }
  getCheck(item, matter) {
    return item.SubscriptionMatter.find((element) => {
      return element.MatterId === matter.Id;
    }) != null
      ? true
      : false;
  }
  getCheckForTariffGroup(item, tariffGroup) {
    return item.SubscriptionTariffGroup.find((element) => {
      return element.TariffGroupId === tariffGroup.Id;
    }) != null
      ? true
      : false;
  }
  // Generate Fake Object Array with skeleton
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
}
