import { IToken } from './base/IToken';
export class CustomerSubscriptionDTO implements IToken{
  public Token: string;
  public SubscriptionsId: string;
  public CountQuote: string;
  public CountUser: string;
  public CardNumber: string;
  public CardName: string;
  public CardExpiration: string;
  public CardSecretCode: string;
  public PaymentMethod: number;
  public DiscountCode: string;
}
export class CustomerSubscriptionResponseDTO{
  public CustomerSubscription : CustomerSubscriptionDTO;
  public PaypalOrderResponse: PaypalOrderResponseDTO;
}
export class PaypalOrderResponseDTO{
  public Status: string;
  public OrderId: string;
  public Links: Array<PaypalOrderLinkDTO>;
  static isCreated(paypalOrder){
    return paypalOrder.Status == "CREATED";
      }
  static isCompleted(paypalOrder){
    return paypalOrder.Status == "COMPLETED";
      }
  static getCheckoutNowLink(paypalOrder){
    return paypalOrder.Links.find(x => x.rel == "approve").href;
  }
  static getLink(paypalOrder){
    let array = paypalOrder.Links.find(x => x.rel == "self").href.split('/');
    return array[array.length - 1];
  }
}
export class PaypalOrderLinkDTO{
  public href: string;
  public rel: string;
}
export interface PaypalCaptureRequestDTO{
  OrderId: string;
  Token: string;
}
